import axios from "axios";

const apiState = {
    api_base_url: '',  // process.env.VUE_APP_AB_API <- Important to set this in child class
    api_resource: '',
    list: [],
    single: {},
    tmp: {}
}

export const apiMutations = {
    updateField(state, { path, value }) {
        path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
          if (array.length === index + 1) {
            // eslint-disable-next-line no-param-reassign
            prev[key] = value;
          }
      
          return prev[key];
        }, state);
      }
}

export const apiGetters = {
    read(state){
        return path => path.split(/[.[\]]+/).reduce((prev, key) => prev[key], state);
    },

    apiURL(state){
        return state.api_base_url + state.api_resource
    },

    url(state){
        return id => id ? 
            process.env.VUE_APP_NCAPI + state.api_resource + id + '/' : 
            process.env.VUE_APP_NCAPI + state.api_resource;
    },

}

export const apiActions = {
    async create(store, params = {url, data}){
        var url = store.getters.read( 'api_base_url' )        
        return store.dispatch('ncapi/post', params )
    },
    async read(store, id) {
        var url = store.getters.url(id)
        console.log("Got Id: ", id)
        console.log("URL with id: ", url)
        
        return store.dispatch('ncapi/get', {url: url}, {root:true} )
        .then(r=>{
            store.state[store.state.data_attr_name] = r.data
            store.state['tmp_'+store.state.data_attr_name] = r.data
        })
        .catch(e=>{
            console.log("Got Error: ", e)
        })
    },
    async readAll(store) {
        var url = store.getters.url()
        return await store.dispatch('ncapi/get', {url: url}, {root:true} )
        .then(r=>{
            store.state[store.state.data_attr_name+'s'] = r.data
        })
        .catch(e=>{
            console.log("Got Error: ", e)
        })
    },
    async update(store, params = {url, data}){
        return store.dispatch('ncapi/patch', params)
    },
    async delete(store, params = {url}){
        return store.dispatch('ncapi/delete', params )
    }
}


export default {
    state: apiState, 
    mutations: apiMutations,
    getters: apiGetters,
    actions: apiActions,
}

