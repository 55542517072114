<template>
  <div class="" id="bgImage">
  
    <!-- <NavbarComponents /> -->

        <NavBar>
        <template v-slot:navHeader></template>
        <template v-slot:navLinks>
        <!-- <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Info</a>
        </li> -->
       
        <!-- <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Support</a>
        </li> -->
        </template>
        <template v-slot:navForm>
          <button class="btn  mx-2" type="submit" @click="Login()">Login</button>
          <router-link to="/sign-up"><button class="btn" type="submit">Sign up</button></router-link>
        </template>
      </NavBar>

    <!-- <div class="mt-5">
      <div class="col" align="center"> -->
        <!-- <button class="btn btn-primary" @click="Login()">Login</button> -->
        <!-- <router-link to="/registration" class="btn btn-primary"><i class="bi bi-files text-white-50 me-2"></i>Administration</router-link> -->
        <!-- <button class="btn btn-danger text-white" @click="KClogout()"><i class="bi bi-box-arrow-right text-white me-2"></i>Log out</button> -->
      <!-- </div>
    </div> -->
    
<!-- for image to left, login, maybe sign up component -->
    <div class="container">{{getData}}
    
      <div class="align-items-center justify-content-center py-5" >
          <div class="bg-ab-grey opacity-75 mt-5 mb-5 p-5"><img src="../assets/actablue_newBlue.png" alt="newBlue" style="width:50%;"></div>
           </div>  
      </div>
          
           <section id="bgColor" class="d-flex justify-content-center justify-content-between p-4 border-bottom">
                <div class="row d-flex p-5">
                    <div class="col-md-4 col-lg-2 col-xl-3 ms-5 py-1 background-color rounded card-header" id="cardBorderA">
                        <h3 class="mb-4 my-4 mx-4">Wat is narrowcasting?</h3>
                      <div class="d-md-block card-body">
                        <p class="card-text">Narrowcasting staat voor een gerichte verspreiding van informatie en inhoud. Bij narrowcasting, ook wel digital signage genoemd, worden beeldschermen ingezet om dynamische content weer te geven</p>
                     </div>
                    </div>

                    <div class="col-md-3 col-lg-3 col-xl-4 ms-5 py-4 background-color rounded card-header" id="cardBorderB">
                        <h3>Waarvoor moet u Narrowcasting gebruiken?</h3>
                        <div class="d-md-block card-body">
                          <p class="card-text">
                            Narrowcasting biedt een wereld van mogelijkheden voor elk bedrijf dat op zoek is naar een manier om effectief te communiceren met zijn doelgroepen en heeft verschillende voordelen ten opzichte van de traditionele omroepmedia.</p>
                        </div>
                    </div>

                    <div class="col-md-4 col-lg-3 col-xl-3 ms-5 py-3 background-color rounded card-header" id="cardBorderA">
                        <h4>Voordelen van Narrowcasting!</h4>
                        <div class="d-md-block card-body">
                          <p class="card-text">Narrowcasting heeft vele voordelen. Narrowcasting helpt u om uw publiek te bereiken met op maat gemaakte berichten. U kunt de inhoud die u met uw publiek wilt delen diversifiëren. Narrowcasting bespaart u ook geld in vergelijking met andere vormen van reclame. </p>
                         
                        </div>
                    </div>
                </div>
          </section>

          <section id="bgGradient" class="d-flex p-4 border-bottom card-header">
            <div class="ms-5 p-3">
              <h3 class="mb-4 text-center">Hoe werkt het portaal?</h3>
                <div class="mt-4 ms-1 card-body rounded"> 
                      <iframe width="740" height="415" src="https://www.youtube.com/embed/JaK6zBcaBjE" frameborder="0" id="playerBg"></iframe>
                </div>
            </div>
          </section>
          <!-- <div class="col-md-4 me-4 background-color rounded mb-5" style="background-color: #07324a;">              
              <div class="text-white px-1 py-3 p-md-5 mx-md-5">
                  <h4 class="mb-4">What is Narrowcasting?</h4> 
                  <p class="textFormat mb-0">Narrowcasting staat voor een gerichte verspreiding van informatie en inhoud. Bij narrowcasting, ook wel digital signage genoemd, worden beeldschermen ingezet om dynamische content weer te geven.</p>
              </div>
          </div> -->
                    
      <!-- <div class="col-6 px-1 py-3 p-md-5 mx-md-5 ">
            <form>
                <div class="">
                    <img src="../assets/logoTransparant.png" alt="logo" style="width:70%; ">
                </div>
                    <div class="d-flex">
                        <div class="col-5 mt-5 ms-5">
                            <button type="sumbit" class="btn btn-success btn-block me-3" @click="Login()" >Login</button>
                            <router-link to="/" class="btn btn-primary btn-block " @click="register()"> Sign Up</router-link>
                        </div>
                   </div>
            </form>
        </div> -->
      
     
     
     <!-- footer section -->
   
     <footer class="text-center text-lg-start text-white" id="footerHead">
            <section class="d-flex justify-content-center justify-content-between p-4 border-bottom">
                <div class="me-4 d-none d-lg-block">
                  <span>&nbsp;</span>
                </div>
                
            </section>
            
            <section class="d-flex" id="footerBottom">
                <div class="container text-center text-md-start mt-5">
                    <div class="row mt-3">
                      <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                          <h6 class="text-uppercase fw-bold mb-4">
                                <a href="https://www.actablue.com" target="_blank" class="textFormat"><i class="bi bi-building"></i> Actablue</a>
                          </h6>
                          <p id="aboutActa" class="textFormat">
                             Wij bieden u de oplossingen die u helpen uw bedrijf te laten groeien, contact te leggen met uw klanten en het proces te vereenvoudigen. Uw one-stop-shop voor al uw data tranfser, bestel- en betaaloplossingen
                          </p>
                      </div>
                      <div class="col-md-2 col-lg-2 col-xl-3 mx-auto mb-4">
                          <h6 class="text-uppercase fw-bold mb-4">
                             <i class="bi bi-people"></i> Social Platforms
                          </h6>
                          <a href="https://www.facebook.com/Actablue/" class="me-4 text-reset">
                              <i class="bi bi-facebook" id="iconFormat">&nbsp;</i>
                          </a>
                          <a href="https://www.linkedin.com/company/actablue/?originalSubdomain=nl" class="me-4 text-reset">
                            <i class="bi bi-linkedin">&nbsp;</i>
                          </a>
                          <a href="https://actablue.com/blog/" class="me-4 text-reset">
                              <i class="bi bi-newspaper">&nbsp;</i>
                          </a>
                          <a href="https://twitter.com/ActaBlue" class="me-4 text-reset">
                              <i class="bi bi-twitter">&nbsp;</i>
                          </a>
                      </div>
                      <!-- <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                          <h6 class="text-uppercase fw-bold mb-4">
                             <i class="bi bi-info-square"></i> Information
                          </h6>
                          <p>
                            <a href="#!" class="textFormat">Disccusions</a>
                          </p>
                          <p>
                            <a href="#!" class="textFormat">Terms & Conditions</a>
                          </p>
                          <p>
                            <a href="#!" class="textFormat">Documentation</a>
                          </p>
                          <p>
                            <a href="#!" class="textFormat">Help</a>
                          </p>
                      </div> -->
                      <div class="col-md-4 col-lg-2 col-xl-2 mx-auto mb-4">
                          <h6 class="text-uppercase fw-bold mb-4">
                              <a href="https://www.actablue.com/contact/" target="_blank" class="textFormat"><i class="bi bi-file-person"></i> Contact</a>
                          </h6>
                          <p>
                           <a href="#" class="textFormat"><span></span> <i class="bi bi-house-fill"></i><span> Ceintuurbaan Noord 126, 9301NZ, Roden</span></a>
                          </p>
                          <p>
                            <a href="#" class="textFormat"><i class="bi bi-telephone-fill"></i> (+31) 088-110-1000</a>
                          </p>
                          <p>
                            <a href="#" class="textFormat"><i class="bi bi-at"></i> info@actablue.com</a>
                          </p>
                      </div>
                    </div>
                </div>
            </section>
            <div class="text-center p-4" id="footerHead">
              © 2022 Copyright:
              <a href="#" class="text-reset fw-bold">Actablue BV</a>
            </div>
        </footer> 
      </div>  
</template>

<script setup>
import navbar from '@/store/composables/navbar'

var nav_item = {icon: '', tag: 'Actablue', href: 'https://actablue.com/', position: 1}
var nav_item2 = {icon: '', tag: 'Contact', href: 'https://actablue.com/contact/', position: 1}


navbar.addNavLink(nav_item)
navbar.addNavLink(nav_item2)

console.log(navbar, nav_item)

// var nav_routeTo = window.location.href='https://getbootstrap.com/docs/5.0/components/navbar/';
// navbar.addRouterToLink(nav_routeTo)
</script>

<script> 
// import headNavbar from '../components/NavbarSlot.vue';
// import NavbarComponents from '../components/NavbarComponents.vue';
import { ref } from 'vue'
import About from '../views/About.vue'
  export default {
    name: 'home',
    components: {
        // NavbarComponents,
      // headNavbar,
      About
    },

    setup(){
      const firstName = ref('')
      const lastName = ref ('')

      return{
        firstName,
        lastName,
      }
    },

    data() {
      return {
        administrations: [],
        authentication: false,
       
      }
    },
    mounted(){
      this.onPageLoad()
      
    },
    computed: {
        getData(){
          // this.administrations = this.$store.state.octoblueStore.administrations
          // console.log(this.administrations)
          if (!this.$store.state.user.isAuthenticated) {
            this.authentication = false
          }
          else {
            this.authentication = true
          }
          console.log(this.authentication)
          console.log(this.$store.state.user.name)
        }
    },
    methods: {
      onPageLoad(){
        // this.$store.dispatch("getAdministrations")
        console.log(this.$store.state.user.isAuthenticated)
      },
      getDetails(){
        console.log(this.$store.state.user.isAuthenticated)
        console.log(this.$store.state.user.name)
        console.log(this.$store.state.user.idToken)
        console.log(this.$store.state.user.accessToken)
      },
      async KClogout(){
        // this.$store.dispatch('key/logout')
      },
      Login(){
        this.$router.push("/login")
      }
    },

  }

</script>
<style scoped>
  .gradient-custom {
  /* fallback for old browsers */
  /* background: #6a11cb; */

  /* Chrome 10-25, Safari 5.1-6 */
  /* background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1)); */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
}

#footerBottom{
  background-color: #23313B
}
#footerHead{
  background-color: #31424e;
}

.textFormat{
  color: white;
}

a:link{
  text-decoration: none;
  
}
a:hover{
  text-decoration: none;
  color: #FFC248;
}

#bgImage{
  background-image:url("../assets/femaletyping.jpeg");
  background-size: 80rem;
  height: 100%;
  /* filter: blur(2px);
  -webkit-filter:blur(2px); */
}
#bgGradient{
  background-image:url("../assets/emptyroomgradient.jpg");
  background-size: 60%;
  height: 50%;
  /* filter: blur(2px);
  -webkit-filter:blur(2px); */
}

#bgColor{
  background-color: rgb(255, 255, 255);

}

#cardBorderA{
/* border-color: #FFC248;
border-style: solid;
border: 30rem; */
border: 1px solid #FFC248;
}
#cardBorderB{
  border: 1px solid #51BADD;
}

#playerBg{
  border: 1px solid rgba(81, 186, 221,0.6);
}
.nav-item a{
  color:white;
  text-decoration: none;  
}
.btn:hover{
  background-color: #51BADD;
}


</style>