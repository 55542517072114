<template>
    <div>
        <div v-if="!errorMessage">
            <h3 class="d-flex justify-content-center mt-5"><strong>Loading...</strong></h3>
            <div class="d-flex justify-content-center my-5">
                <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status"></div>
            </div>
        </div>
        <div v-else>
            <p class="text-danger text-center mt-5"><strong>Something went wrong during login. Please return to home and try again later.</strong></p>
            <div class="d-flex justify-content-center">
                <router-link :to="{ name: 'Home'}" class="btn btn-outline-primary">Back to home</router-link>
            </div>
        </div>
    </div>
</template>

<script>
// import * as Keycloak from 'keycloak-js';
export default {
    name: 'home',
    data() {
        return{
            errorMessage: false,
        }
    },
    mounted(){
        this.login()
        
    },
    methods: {
        async login(){
            await this.$store.dispatch("key/login")
            .then(() => {
                console.log('success')
                setTimeout(() => { 
                        this.$router.push("/registration")
                    }, 1000
                );
                
            })
            .catch((error) => {
                console.log('AAAH: ', error)
                this.errorMessage = true
            })
            
        }
    }
}
</script>

<style>

</style>
