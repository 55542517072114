<template>
 <NavBar>
        <template v-slot:navHeader></template>
                <template v-slot:navForm>
                        <div >
                            <ul class="m-0 me-5 nav-item">
                                <li class=" my-auto " style="list-style-type: none;">
                                    <div v-if="authChecking()" class="nav-item dropdown ">
                                        <a class="nav-link dropdown-toggle fs-5 text-white" href="#" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="bi bi-person-fill me-1" aria-label="Account options"></i>
                                        </a>
                                        <ul class="dropdown-menu me-2 mt-3" aria-labelledby="dropdownMenuButton1">
                                            <li><a href="#" class="dropdown-item dropColor fs-5"><i class="bi bi-person-circle me-2 mx-3" aria-label="Account"></i>Account</a></li>
                                            <li><a href="/registration" class="dropdown-item dropColor fs-5"><i class="bi bi-files  me-2 mx-3" aria-label="Administration"></i>Administration</a></li>
                                            <li><a href="/faq" class="dropdown-item dropColor fs-5"><i class="bi bi-question-circle me-2 mx-3" aria-label="FAQ"></i>FAQ</a></li>
                                            <li><hr class="dropdown-divider"></li>
                                            <li><a href="#backDrop" class="dropdown-item dropColor fs-5" data-bs-toggle="modal"><i class="bi bi-box-arrow-right  me-2 mx-3" aria-label="Log out"></i>Log out</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </template>
        </NavBar>
     <div class="container mt-5 w-50">
        <div v-if="errorMessage" class="mb-3">
            <p class="text-danger text-center mt-5" v-if="!errorOccurred"><strong>Something went wrong during admin registration. Please return to home and try again later.</strong></p>
            <p class="text-danger text-center mt-5" v-else><strong>{{errorOccurred}}. Please try again later.</strong></p>
            <div class="d-flex justify-content-center">
                <router-link :to="{ name: 'Home'}" class="btn btn-outline-primary">Back to home</router-link>
            </div>
        </div>
        <div v-if="loading" class="mb-3">
            <h3 class="d-flex justify-content-center mt-5"><strong>Loading...</strong></h3>
            <div class="d-flex justify-content-center my-5">
                <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status"></div>
            </div>
        </div>
        <div class="" v-if="show">
            <RegistrationForm cls="-xl-12" @submit.prevent="submitRegistration(index)">
                <template v-slot:header>Registreer</template>
                <template v-slot:body>
                    <Input type="text" id="text"  v-model:modelValue="adminName" cls="-md-12 mb-2" label="Bedrijfs naam" required/>

                    <div class="row">
                        <Input type="text" id="text"  v-model:modelValue="coc" cls="-md-6 mb-2" label="KvK" required/>
                        <Input type="text" id="text"  v-model:modelValue="vatNumber" cls="-md-6 mb-2" label="BTW nummer" required/>
                    </div>
                    <div class="row">
                        <Input type="text" id="validationCustom" v-model:modelValue="street" cls="-md-4 mb-2" label="Straat" required/>
                        <Input type="text" id="validationCustom" v-model:modelValue="streetNumber" cls="-md-4 mb-2" label="Nr." required/>
                        <Input type="text" id="validationCustom" v-model:modelValue="streetNumberAddition" cls="-md-4 mb-2" label="Toevoeging" />
                    </div>
                    <div class="row">
                        <Input type="text" id="text" v-model:modelValue="zipcode" cls="-md-6 mb-2" label="Postcode" required/>
                        <Input type="text" id="text" v-model:modelValue="place" cls="-md-6 mb-2" label="Plaats" required/>
                    </div>

                    <Input type="text" id="text" v-model:modelValue="phoneNumber" cls="-md-12 mb-2" label="Telefoon nummer" required/>
                    <Input type="text" id="text" v-model:modelValue="iban" cls="-md-12 mb-2" label="Iban" required/>
                </template>
            </RegistrationForm>
            <!-- <h5 class="card-header-actaorange text-white"></h5>
            <div class="card-body">
                <h2 class="text-actablue mb-2 text-center">Registreer</h2>
                <form @submit.prevent="submitRegistration(index)">
                    <div class="field mb-3">
                        <label>Bedrijfs naam</label>
                        <div class="control">
                            <input type="text" class="input form-control" v-model="adminName" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="field mb-3 col-6">
                            <label>kvk</label>
                            <div class="control">
                                <input type="text" class="input form-control" v-model="coc" required name="un">
                            </div>
                        </div>
                        <div class="field mb-3 col-6">
                            <label>BTW nummer</label>
                            <div class="control">
                                <input type="text" class="input form-control" v-model="vatNumber" required name="un">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="field mb-3 col-8">
                            <label>Straat</label>
                            <div class="control">
                                <input type="text" class="input form-control" v-model="street" required>
                            </div>
                        </div>
                        <div class="field mb-3 col-2">
                            <label>Nr.</label>
                            <div class="control">
                                <input type="number" class="input form-control" v-model="streetNumber" required>
                            </div>
                        </div>
                        <div class="field mb-3 col-2">
                            <label>Toevoeging</label>
                            <div class="control">
                                <input type="text" class="input form-control" v-model="streetNumberAddition">
                            </div>
                        </div>
                        <div class="field mb-3 col">
                            <label>Postcode</label>
                            <div class="control">
                                <input type="text" class="input form-control" v-model="zipcode" required>
                            </div>
                        </div>
                        <div class="field mb-3 col">
                            <label>Plaats</label>
                            <div class="control">
                                <input type="text" class="input form-control" v-model="place" required>
                            </div>
                        </div>
                    </div>

                    <div class="field mb-3">
                        <label>Telefoon nummer</label>
                        <div class="control">
                            <input type="tel" class="input form-control" v-model="phoneNumber">
                        </div>
                    </div>
                   
                    <div class="field mb-3">
                        <label>iban</label>
                        <div class="control">
                            <input type="text" class="input form-control" v-model="iban" required name="un">
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="btn btn-primary mt-2">Registreer</button>
                        </div>
                    </div>
                </form>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminRegistration',
    data() {
        return {
            loading: true,
            errorMessage: false,
            show: false,
            adminName: '',
            street: '',
            streetNumber: null,
            streetNumberAddition: '',
            zipcode: '',
            place: '',
            phoneNumber: '',
            coc: '',
            vatNumber: '',
            iban: '',
            merchID: '',
            errorOccurred: '',
        }
    },
    mounted(){
        this.checkAuth()
    },
    methods: {
            authChecking(){
                if(!this.$store.state.user.isAuthenticated){
                    console.log('is authenticated')
                }
            },
        async checkAuth(){
            // this.$store.dispatch("key/login")
            // .then(() => {
            //     console.log('success')
            //     setTimeout(() => { 
            //         this.loading = false
            //         this.show = true 
            //     }, 5000
            // );
            // })
            // .catch((error) => {
            //     console.log('something went wrong: ', error)
            //     this.errorMessage = true
            // })

            setTimeout(() => { 
                    this.loading = false
                    this.show = true 
                }, 1000
            );
            // console.log("IS THIS PERSON AUTHENTICATED??: ", this.$store.state.user.isAuthenticated)
            // console.log(this.$store.state.user.idtoken)
            // console.log(this.merchID)
        
        },
        async submitRegistration(){
            this.merchID = this.$store.state.octoblueStore.merchID  
            this.loading = true
            // get subscription id first
            let subscriptionID = null
            await this.$store.dispatch("getSubscription")
            subscriptionID = this.$store.state.octoblueStore.subscriptionID
            const userData = await this.$store.state.registration
            console.log(userData)
            console.log(subscriptionID)

            const createAdministration = {
                // id: this.merchID,
                user_id: this.merchID,
                name: this.adminName,
                person: {
                    first_name: userData.firstName,
                    surname: userData.lastName,
                },
                addresses: [
                    {
                        street: this.street,
                        street_number: this.streetNumber,
                        street_number_additional: this.streetNumberAddition,
                        zip_code: this.zipcode,
                        place: this.place,
                        country: "the Netherlands"
                    }
                ],
                emails: [
                    {
                        type: "algemeen",
                        email: userData.mail
                    }
                ],
                phone_numbers:[
                    {
                        type: "algemeen",
                        number: this.phoneNumber
                    }
                ],
                coc: this.coc,
                iban: this.iban,
                vat_number: this.vatNumber,
                country_code: "NL",
                // subscriptions: [
                //     subscriptionID
                // ]
            }
            console.log(createAdministration)
            await this.$store.dispatch("postRegistration", createAdministration)
            .then(() => {
                console.log('success')
                this.loading = false
                // this.loading = true
                this.show = false
                this.$router.push("/registration?registration=true")
            })
            .catch((error) => {
                console.log('something went wrong: ', error)
                this.loading = false
                this.errorMessage = true
                this.errorOccurred = error
                // this.show = false
            })
        },
    }
}
</script>

<style>

</style>