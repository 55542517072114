<template>
  <div :class="'col' + props.cls">
            <div class='card-header abstyle text-white' id="borderTop">
                <h4><i v-if="props.icon" :class="'bi bi-'+props.icon"></i>&nbsp;<slot name="header"></slot></h4>
            </div> 
        <div class="card-body" id="borderBot">
            <slot name="body"></slot>      
        </div>
      </div>
</template>


<script setup>
const props = defineProps({
    cls: {
        type: String,
        default: '-xl-4'
    },
    icon: {
        type: String,
        default: ''
    },
    id: {
        type: String,
        default: ''
    }
    
})
console.log('this is prop class', props.cls)
</script>

<script>
export default {
    name: 'Card',
     props: {
        objCampagnes: {
            type: Object
        },
        PKPlaylist: {
            type: Number 
        },
        objSortedViews: {
            type: Object
        },
    },

}
</script>

<style scoped>
  .card-header{
    color: #017ab5;
  }
  .bg-color{
    color: #337ab7;
  }
  .navbg-color{
    color: #f7960b;
  }
  
 .backdrop-color{
   color: #343434;
 }

 .sidebar-color{
   color: #017ab5;
 }
 .abstyle {
    background-color: #017ab5;
}

.cardbgColor1{
    background-color: #d8d8d8;
    border-top: 2px solid #bdbdbd;
}
.cardbgColor2{
    background-color: #ffffff;
}

.btn-default{
    background-color: #017ab5;
}
.btn-default:hover{
    background-color: #f7960b;
}
i :hover{
    background-color: #f7960b;
}

#right{
  float: right;
}
#borderTop{
  border-style: solid;
  border-color: #017ab5;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  

}
#borderBot{
  border-style: solid;
  border-color: #017ab5;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>