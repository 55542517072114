<template>
    <div class="container">{{getData}}
        <div class="col-6">
            <form @submit.prevent="submitForm">
                <div class="field">
                    <label>Name</label>
                    <div class="control">
                        <input type="text" class="input form-control" v-model="campagne.name">
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button data-bs-toggle="collapse" data-bs-target="#collapseExample" class="btn mt-3">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="flippingtoast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-success border-0">
                    <strong class="me-auto">Success!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" data-bs-toggle="collapse" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The campagne has been updated!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const {Toast} = bootstrap
export default {

    name: 'EditCampagne',
    data() {
        return {
            campagne:[],
        }
    },
    computed: {
        getData(){
            //store campagne data into local variable
            this.campagne = this.$store.state.ncapiStore.campagne
        }
    },
    methods: {
        async submitForm() {
            //call toast
            var toast = new Toast(this.$refs.flippingtoast)
            toast.show()
            
            

            //patch updated data to API and load campagne to update page
            const campagne = {
                name: this.campagne.name,
            }
            await this.$store.dispatch('patch', {type:"campagne", pk:this.$route.params.pk, data:campagne})
            this.$store.dispatch("get", {type:"campagne", pk: this.$route.params.pk})
        },
    },
}
</script>