<template>
  <div :class="'col' +props.cls">
    <div v-if="props.styl" :class="'alert alert-' + props.styl">
        <slot name="body"></slot>This is the body
    </div>
  </div>
</template>

<script setup>
    const props = defineProps({
        cls:{
            type: String,
            default: '-lg-8'
        },
        styl:{
            type: String,
            default: 'alert'
        },
        icon:{
            type: String,
            default: ''
        }
    })
</script>

<script>
export default {

}
</script>

<style>

</style>