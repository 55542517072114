<template>
<a :href="''+props.link">
<div :class="'col' + props.cls">
   <div class="card mx-2 my-5 borderFaq">
      <div class="row g-0">
        <div class="col-md-12">
          
            <div class="card-body">
                <h4 class="bg-color-text"><div class="card-title">&nbsp;<i v-if="props.icon" :class="'bi bi-'+props.icon"></i><slot name="title">Card Title</slot></div></h4>
                <slot name="body"></slot>
                <p class="card-text"><slot name="text"></slot></p>
                <p class="card-text"><small class="text-muted"><slot name="update"></slot></small></p>
               </div>
          </div>
      </div>
  </div>
</div>
</a>
</template>

<!-- <h4 class="bg-color"><i v-if="props.icon" :class="'bi bi-'+props.icon"></i>&nbsp;<slot name="title">This is the title</slot></h4> -->

<script setup>

const props = defineProps({
    cls: {
        type: String,
        default: '-md-8'
    },
    icon: {
        type: String,
        default: ''
    },
    link:{
      type: String,
      default: ''
    }
    
})
</script>

<script>
export default {
    name: 'FaqCard',
     props: {
        objCampagnes: {
            type: Object
        },
        PKPlaylist: {
            type: Number 
        },
        objSortedViews: {
            type: Object
        },
    },

}
</script>

<style scoped>
 
  .bg-color{
    color: #337ab7;
  }
  .bg-color-text{
    color: #f7960b;
    text-decoration: none;
  }
  .navbg-color{
    color: #f7960b;
  }
  
i :hover{
    background-color: #f7960b;
}

#right{
  float: right;
}
#borderTop{
  border-style: solid;
  border-color: #017ab5;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  

}
#borderBot{
  border-style: solid;
  border-color: #017ab5;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.borderFaq{
  border-left: 5px solid var(--ab-blue);
  /* border-top: 5px solid var(--ab-blue);
  border-bottom: 5px solid var(--ab-blue);
  border-right: 5px solid var(--ab-blue); */
}

a{
  text-decoration: none;
}
</style>