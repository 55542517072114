<template>
    <div>
        <!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-5">
            <div class="container-fluid">
                <router-link to="/" class="navbar-brand">ActaBlue</router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mb-2 mb-lg-0">
                        <li class="nav-item">
                            <button class="btn btn-danger me-2" @click="KClogout()">Logout</button>
                        </li>
                        <li class="nav-item">
                            <router-link to="/" class="nav-link me-2">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard" class="nav-link me-2">Dashboard</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/devices-overview" class="nav-link me-2">Devices</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/campagnes-overview" class="nav-link me-2">Campagnes</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/select-show" class="nav-link me-2">Preview</router-link>
                        </li>
                        
                        <li class="nav-item">
                            <router-link to="/about" class="nav-link me-2"><i class="bi bi-grid"></i> Test</router-link>
                        </li>
                        <li class="nav-item">
                            <i class="bi bi-list nav-link me-2" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-expanded="false" aria-controls="sidebarCollapse"></i>
                        </li>
                    </ul>
                </div>
            </div>
        </nav> -->
        
        <nav class="navbar navbar-dark bg-navTopSideOrange border-bottom">
            <div>
                <span v-if="notAdministrations" class=" ms-3"></span>
                <picture class="ps-2 nav-item">
                    <!-- <source media="(min-width: 900px)" srcset="../../assets/logoTransparant.png"> -->
                    <!-- <source media="(min-width: 480px)" srcset="../../assets/ActablueIcon.png"> -->
                    <img id="imageLogo" src="../../assets/Actablue_logo.png" alt="Actablue logo" style="width:23%; height:auto; padding:0px; margin:0px;" class="navbar-brand">
                </picture>
            </div>
            <div >
                <ul class="m-0 nav-item">
                    <li class=" my-auto " style="list-style-type: none;">
                        <div class="dropdown ">
                            <a class="nav-link fs-3 p-0" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                <i class="bi bi-person-circle text-white-50 me-3 " aria-label="Account options"></i>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-dark bg-navTopSide dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item text-white-50" href="#"><i class="bi bi-person-circle text-white-50 me-2" aria-label="Account"></i>Account</a></li>
                                <li><router-link to="/registration" class="dropdown-item text-white-50"><i class="bi bi-files text-white-50 me-2"></i>Administration</router-link></li>
                                <li><router-link to="/faq" class="dropdown-item text-white-50"><i class="bi bi-question-circle text-white-50 me-2"></i>FAQ</router-link></li>
                                <li><hr class="dropdown-divider text-white-50"></li>
                                <li><a class="dropdown-item text-white-50" data-bs-toggle="modal" href="#backDrop" @click="logoutPrompt()"><i class="bi bi-box-arrow-right text-white-50 me-2"></i>Log out</a></li>
                                <div class="text-center"> 
                                </div>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            
            <div class="modal fade" id="backDrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Log Out</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                            <div class="modal-body">
                            <strong>Are you sure you want to log out?</strong>
                            </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-danger" @click="KClogout()" data-bs-dismiss="modal">Log Out</button>
                        </div>
                    </div>
                </div>
            </div>

        </nav>
    </div>
</template>



<script> 
    export default {

        data(){
            return{
                hover: false,
                trueValue: true
                
            };
        },

        name: 'Navbar',
        methods: {


            KClogout(){
                this.$store.dispatch('key/logout')
                console.log("in out of here")
                // this.$router.push("/")
            },
            toggleSidebar(){
                var element = document.getElementById("wrapper");
                element.classList.toggle("toggled");

                var newLogo = document.getElementById("imageLogo")
                if(newLogo.style.display ==="inline"){
                    newLogo.style.display ="none";
                }
                else{
                    newLogo.style.display = "inline"
                }
            },
            logoutPrompt(){
                console.log("My friends dont drink Dr.Pepper")
               //var myModal = new bootstrap.Modal(document.getElementById('backDrop'), options)                
            }
            //showModal(){
            //    this.$modal.show("backDrop")
            //}
        },
        computed: {
            notAdministrations() {
                return this.$route.name !== 'registration'
            }
        },
        mounted(){
            //toggle sidebar
            // $("#menu-toggle").click(function(e) {
            // e.preventDefault();
            // $("#wrapper").toggleClass("toggled");
            // });
            //this.showModal()
        },
    
    }

</script>

<style>
:root {
  --sidebar-bg-color: #2f855a;
  --sidebar-item-hover: #38a169;
  --sidebar-item-active: #276749;
 
}
</style>

<style scoped>
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);
  float: left;
  position: fixed;
  z-index: 1;
  top: 56px;
  left: 0;
  bottom: 0;
  padding: 0.5em;
  width: 180px;
  height: 100%;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
}
.sidebar h1 {
  height: 2.5em;
}
.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.2s linear;
} 
 .rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
.navbar {
    padding-left: calc(var(--sidebar-width) + 1rem);
}

#imageLogo{
    display: none;
}
</style>