import axios from 'axios'

export default {
    state:{
        nc_api_base_url: process.env.VUE_APP_NCAPI,
        details: [],
        devices:[],
        device: [],
        schedules:[],
        schedule: [],
        schedulesCampagnes:[],
        scheduleCampagne: [],
        campagnes:[],
        campagne: [],
        playlists:[],
        playlist: [],
        views:[],
        view: [],
        
    },    
    mutations:{
        


        setDetails(state, context){
            state.details = context
        },

        setDevices(state, context){
            state.devices = context
        },
        setDevice(state, context){
            state.device = context
        },
        pushDevice(state, context) {
            state.devices.push(context) 
        },

        setSchedules(state, context){
            state.schedules = context
        },
        setSchedule(state, context){
            state.schedule = context
        },
        pushSchedule(state, context) {
            state.schedules.push(context) 
        },

        setSchedulesCampagnes(state, context){
            state.schedulesCampagnes = context
        },
        setScheduleCampagne(state, context){
            state.scheduleCampagne = context
        },
        pushScheduleCampagnes(state, context) {
            state.schedulesCampagnes.push(context) 
        },

        setCampagnes(state, context){
            state.campagnes = context
        },
        setCampagne(state, context){
            state.campagne = context
        },
        pushCampagne(state, context) {
            state.campagnes.push(context) 
        },

        setPlaylists(state, context){
            state.playlists = context
        },
        setPlaylist(state, context){
            state.playlist = context
        },
        pushPlaylist(state, context) {
            state.playlists.push(context) 
        },

        setViews(state, context){
            state.views = context
        },
        setView(state, context){
            state.view = context
        },
        pushView(state, context) {
            state.views.push(context) 
        },
    },
    getters: {
        ncAPIBaseURL(state) {
            return (resource) => process.env.VUE_APP_NCAPI + resource
        },
        getDevices(state){
            return state.devices
        },
        getAxConfig(state, getters){
            //const csrftoken = Cookies.get('csrftoken');
            const bearerToken = 'Bearer ' + getters.token;
            const adminId = localStorage.administrationID
            return {
                headers: {
                    Authorization: bearerToken,
                    'administration': adminId,
                    //'X-CSRFToken': csrftoken,
                    'X-Requested-With': 'XMLHttpRequest'
                },
                timeout: 3000,
            }
        }
    },
    actions: {
        getDetails(store, postdata){
            var details = {
                URL: "",
                commitName: "",
                pk: null,
                data: []
            }
            if (postdata.type === 'device'){
                details.URL = store.getters.ncAPIBaseURL("/api/v1/devices/device/")
                details.commitName = "setDevices"
                if(postdata.data){
                    details.data = postdata.data
                    details.commitName = 'pushDevice'
                }
                if(postdata.pk){
                    details.URL = store.getters.ncAPIBaseURL(`/api/v1/devices/device/${postdata.pk}/`)
                    details.pk = postdata.pk
                    details.commitName = 'setDevice'
                }
            }
            if (postdata.type === 'schedule'){
                details.URL = store.getters.ncAPIBaseURL('/api/v1/schedules/dailyroutine/')
                details.commitName = "setSchedules"
                if(postdata.data){
                    details.data = postdata.data
                    details.commitName = 'pushSchedule'
                }
                if(postdata.pk){
                    details.URL = store.getters.ncAPIBaseURL(`/api/v1/schedules/dailyroutine/${postdata.pk}/`)
                    details.pk = postdata.pk
                    details.commitName = 'setSchedule'
                }
            }
            if (postdata.type === 'scheduleCampagne'){
                details.URL = store.getters.ncAPIBaseURL('/api/v1/schedules/dailyroutinecampagnes/')
                details.commitName = "setSchedulesCampagnes"
                if(postdata.data){
                    details.data = postdata.data
                    details.commitName = 'pushScheduleCampagnes'
                }
                if(postdata.pk){
                    details.URL = store.getters.ncAPIBaseURL(`/api/v1/schedules/dailyroutinecampagnes/${postdata.pk}/`)
                    details.pk = postdata.pk
                    details.commitName = 'setScheduleCampagne'
                }
            }
            if (postdata.type === 'campagne'){
                details.URL = store.getters.ncAPIBaseURL('/api/v1/campagnes/campagne/')
                details.commitName = "setCampagnes"
                if(postdata.data){
                    details.data = postdata.data
                    details.commitName = 'pushCampagne'
                }
                if(postdata.pk){
                    details.URL = store.getters.ncAPIBaseURL(`/api/v1/campagnes/campagne/${postdata.pk}/`)
                    details.pk = postdata.pk
                    details.commitName = 'setCampagne'
                }
            }
            if (postdata.type === 'playlist'){
                details.URL = store.getters.ncAPIBaseURL('/api/v1/campagnes/playlist/')
                details.commitName = "setPlaylists"
                if(postdata.data){
                    details.data = postdata.data
                    details.commitName = 'pushPlaylist'
                }
                if(postdata.pk){
                    details.URL = store.getters.ncAPIBaseURL(`/api/v1/campagnes/playlist/${postdata.pk}/`)
                    details.pk = postdata.pk
                    details.commitName = 'setPlaylist'
                }
            }
            if (postdata.type === 'view'){
                details.URL = store.getters.ncAPIBaseURL('/api/v1/campagnes/view/')
                details.commitName = "setViews"
                if(postdata.data){
                    details.data = postdata.data
                    details.commitName = 'pushView'
                }
                if(postdata.pk){
                    details.URL = store.getters.ncAPIBaseURL(`/api/v1/campagnes/view/${postdata.pk}/`)
                    details.pk = postdata.pk
                    details.commitName = 'setView'
                }
            }
            store.commit("setDetails", details)
        },
        async getAll({dispatch, commit, getters, state}, postdata){
            await dispatch("getDetails", postdata)
            
            await axios
                .get(state.details.URL, getters.getAxConfig)
                .then(response => {
                    // console.log("Data: ", response.data)
                    
                    commit(state.details.commitName, response.data)
                })
                .catch(error => {
                 console.log(error, {...error})
                 
                })
        },
        async get({dispatch, commit, getters, state}, postdata){
            await dispatch("getDetails", postdata)
            
            await axios
                .get(state.details.URL, getters.getAxConfig)
                .then(response => {
                    // console.log("Data one: ", response.data)
                    
                    commit(state.details.commitName, response.data)
                })
                .catch(error => {
                 console.log(error)
                 
                })
        },
        async post({dispatch, commit, getters, state}, postdata){
            await dispatch("getDetails", postdata)
            
            await axios
                .post(state.details.URL, state.details.data, getters.getAxConfig)
                .then(response => {
                    // console.log("Data posted: ", response.data)
                    
                    commit(state.details.commitName, response.data)
                })
                .catch(error => {
                 console.log(error)
                 
                })
        },
        async delete({dispatch, commit, getters, state}, postdata){
            await dispatch("getDetails", postdata)
            
            await axios
                .delete(state.details.URL, getters.getAxConfig)
                .then(response => {
                    // console.log("Data deleted: ", response.data)
                    
                })
                .catch(error => {
                 console.log(error)
                 
                })
        },
        async patch({dispatch, commit, getters, state}, postdata){
            await dispatch("getDetails", postdata)
            
            await axios
                .patch(state.details.URL, state.details.data, getters.getAxConfig)
                .then(response => {
                    // console.log("Data patched: ", response.data)
                    
                })
                .catch(error => {
                 console.log(error)
                 
                })
        },
    },
}