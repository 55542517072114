  <template>
<div :class="'col'+props.cls">
  <label v-if="label" :for="id" class="font-bold block">
    {{label}}
  </label>
    <input
    :id="id" 
    :type="type"
    :value="modelValue" 
    @input="updateInput"
    :placeholder="blankInput"
    :password="password"
    :email="email"
    :text="text"
    :class="attr"
    :required="required">
</div>
</template>


<script setup>
import {ref, computed} from 'vue'

const props = defineProps({
    attr:{
        type: String,
        default:"form-control"
    },
    cls:{
        type: String,
        default: "-lg-6"
    },
     id:{
        type: String,
        default: "",
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    required:{
      type: Boolean,
      defualt:""
    },

    password:{type:Boolean},
    text:{type:String},
    number:{type:Boolean}, 
    email:{type:Boolean},
    modelvalue:{}
    
})

// const col = ref('')
// if( props.col != 'col') {
//     console.log(props.col)
//     col.value = 'col-' + props.col;
// }

const type= ref('text')
switch(true){
    case props.password:
        console.log('we have a password');
        type.value = 'password';
        break;
    case props.text:
        console.log('we have a text');
        type.value = 'text';
        break;
    case props.number:
        console.log('we have a number');
        type.value = 'number';
        break;
}
const modelvalue = computed(()=>props.modelvalue)

</script>

<script>
export default {
name:"Label",

data(){
    return{
        blankInput: ''
    }
},

methods:{
    updateInput(event){
        this.$emit("update:modelValue", event.target.value);
         }
    },


}
</script>

<style>

</style>

  <!-- <Input id="email"  v-model:modelvalue="email" cls="-md-8" label="Email"/> -->