<template>
   {{getData}}
        <div class="col-12 mb-2">
            <h2 class=" my-4">Campagnes</h2>
            <button class="btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-plus-circle me-2"></i>Add campagne</button>

            <div class="collapse" id="collapseExample">
                <div class="card card-body border">
                    <AddCampagne />
                </div>
            </div>
        </div>

        <Card cls="" icon="card-list" v-bind:objCampagnes="campagnes">
            <template v-slot:header>
                Campagnes
            </template>
            <template v-slot:body>
                 <table class="table table-borderless table-hover"> 
                <thead>
                    <tr>
                        <th>Campagne Name:</th>
                        <th>Amount of playlists:</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="(campagne, index) in campagnes"
                        v-bind:key="campagne.pk"
                        @click="goDetails(index)"
                        class=""
                    >   
                            <td>{{campagne.name}}</td>
                            <td v-if="campagne.playlists">{{campagne.playlists.length}}</td>
                            <td>
                                <router-link :to="{ name: 'Campagne', params: { pk: campagne.pk}}" class="btn">Details</router-link>
                            </td>  
                    </tr>
                </tbody>
            </table>
            </template>
        </Card>
<!-- 
        <div class="card border-actaDarkblue mb-3 rounded">
            <h5 class="card-header-actaDarkblue text-white bg-actaDarkblue">Campagnes</h5>
            <table class="table table-borderless table-hover"> 
                <thead>
                    <tr>
                        <th>Campagne Name:</th>
                        <th>Amount of playlists:</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="(campagne, index) in campagnes"
                        v-bind:key="campagne.pk"
                        @click="goDetails(index)"
                        class=""
                    >   
                            <td>{{campagne.name}}</td>
                            <td>{{campagne.playlists.length}}</td>
                            <td>
                                <router-link :to="{ name: 'Campagne', params: { pk: campagne.pk}}" class="btn btn-outline-primary">Details</router-link>
                            </td>  
                    </tr>
                </tbody>
            </table>
        </div> -->
         <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
         
         <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="noCampagnes" class="toast hide text-black bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-black bg-danger border-0">
                    <strong class="me-auto"><i class="bi bi-info-circle-fill"></i> Campagne overview</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>There are currently no campagnes in the overview</p>
                </div>
            </div>
        </div> 
         </div>
   
</template>

<script>
    import AddCampagne from '../views/AddCampagne.vue'

    const {Toast} = bootstrap

    export default {
        name: 'CampagnesOverview',
        components: {
            AddCampagne,
        },
        data(){
            return{
                campagnes:[],
                currentCamps:[],
            }
        },
        computed: {
            getData(){
                //store campagnes data in local variable
                this.campagnes = this.$store.state.ncapiStore.campagnes
            }
        },
        mounted (){
            this.onPageLoad()
            this.checkCampagnes()
        },
        
        methods: {

            async checkCampagnes(){
               for(var index = 0; index < this.campagnes.length; index++){
                   if(this.campagnes[index]){
                       console.log('this is the campagnes', this.campagnes[index])
                    //    var toast = new Toast(this.$refs.currentCampagnes)
                    //    toast.show()
                   }
                   else {
                       console.log('there is no camps in here')
                       var toast = new Toast(this.$refs.noCampagnes)
                       toast.show()
                   }
               }
                // this.currentCamps = this.campagnes.length
                // console.log('current camps number', this.currentCamps )
            },

            async onPageLoad(){
                //load campagnes data in store from API
                await this.$store.dispatch("getAll", {type:"campagne"})
            },
            goDetails(index){
                //go to campagnes details page of selected campagne
                this.$router.push(`/campagnes-overview/${this.campagnes[index].pk}`)
            }
        }
    }
</script>
<style scoped>
td{
    cursor: pointer;
}

</style>