<template>
  <div class="card mb-3 mx-2 borderAdmin" :style="'max-width: ' + props.size">
      <div class="row g-0">
        <div :class="'col' + props.cls">
            <div class="card-body ">
                <h5><div class="card-title"><slot name="title">Card Title</slot></div></h5>
                <slot name="body"></slot>
                <p class="card-text"><slot name="text">This is the wider card with the supporting text</slot></p>
                <p class="card-text"><small class="text-muted"><slot name="update"></slot></small></p>
               </div>
          </div>
      </div>
  </div>
</template>

<script setup>
const props = defineProps({
    cls:{
        type: String,
        default: '-md-8'
    },
    size:{
        type: String,
        default: '540px'
    }
})

</script>


<script>
export default {
name: 'Admin',

data(){
    return{
        adminName:'',
    }
},

computed:{

},

mounted(){

},

methods: {

},
}
</script>


<style>
.borderAdmin{
    border-left: 5px solid var(--ab-orange);
    /* border-bottom-left-radius: 15px; */

}
</style>