<template>

          <router-view/>
</template>

<script>
  import Navbar from '@/components/layout/Navbar'
  import Sidebar from '@/components/layout/Sidebar'
  // import NavbarComponents from '@/components/NavbarComponents'
  export default {
    name: 'App', 
    components: {
      Navbar,
      Sidebar,
      // NavbarComponents,
    },
    data(){
      return{
        adminName: '',
        routes: ['/dashboard', '/device-overview']
      }
    },

    mounted(){
      this.getAdminInfo()
      this.checkRoute()
    },

    methods:{
          KClogout(){
                this.$store.dispatch('key/logout')
                console.log("in out of here")
                this.$router.push("/")
            },
           async getAdminInfo(){
            this.adminName = localStorage.adminName
                await this.$store.dispatch("getAllLocations")
                await this.$store.dispatch("getAll", {type:"device"})
                await this.$store.dispatch("getAll", {type:"schedule"})
            },

            checkRoute(){
              for(var i =0; i<this.routes.length; i++){
                console.log('here', this.routes[i])
               }
               if(this.$router = this.routes[i]){
                 this.$route.fullPath = this.routes[i]
              }
            }
    }
  }
</script>


<style lang="scss">
:root{
  --ab-blue: #017ab5;
  --ab-orange: #f7960b;
  --ab-grey: #343434;
}

.bg-abblue {background-color: var(--ab-blue); } .txt-abblue{color: var(--ab-blue);}
.bg-aborange {background-color: var(--ab-orange); } .txt-aborange{color: var(--ab-orange);}
.bg-ab-grey {background-color: var(--ab-grey); } .txt-ab-grey{color: var(--ab-grey);}

body {
    overflow-x: hidden;
  }
  
  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
  }
  
  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
  
  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  
  // #page-content-wrapper {
  //   // min-width: 100vw;
  // }
  
  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }
  
  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }
  
    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }
  
    #wrapper.toggled #sidebar-wrapper {
      margin-left: -15rem;
    }
  }
  // .bg-light {
  //   background-color: #23313B!important;
  // }
  .bg-navTopSide {
    background-color: #23313B!important;
  }
  .bg-navTopSideBlue {
    background-color: #046c9c!important;
  }
  .bg-navTopSideOrange {
    background-color: #f7960b!important;
  }
  .bg-sideItem {
    background-color: #23313B;
  }
  .bg-sideItemBlue {
    background-color: #067db6;
  }
  .bg-sideItem:hover {
    background-color: #1AA5D3!important;
  }

  .border-actablue{
    border-color: #51BADD!important;
  }
  .border-actaDarkblue{
    border-color: #046c9c!important;
  }
  .bg-actablue{
    background-color: #51BADD!important;
  }
  .bg-actaDarkblue{
    background-color: #046c9c!important;
  }
  .border-end-actablue{
    border-right: 1px solid #51BADD;
  }
  .border-end-actaDarkblue{
    border-right: 1px solid #046c9c;
  }
  .text-actablue{
    color: #51BADD;
  }
  .card-header-actablue{
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    padding: 0.5rem 1rem;
    margin-top: -1px;
    margin-bottom: 0;
    margin-right: -1px;
    background-color: #51BADD;
    border-bottom: 1px solid #51BADD;
  }
  .card-header-actaDarkblue{
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    padding: 0.5rem 1rem;
    margin-top: -1px;
    margin-bottom: 0;
    margin-right: -1px;
    background-color: #046c9c;
    border-bottom: 1px solid #046c9c;
  }

  .border-actaorange{
    border-color: #FFC248!important;
  }
  .card-header-actaorange{
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    padding: 0.5rem 1rem;
    margin-top: -1px;
    margin-left: -1px;
    margin-bottom: 0;
    margin-right: -1px;
    background-color: #FFC248;
    border-bottom: 1px solid #FFC248;
  }

// .btn{
//   background-color:#017ab5;
//   color: white;
//   margin: auto;
//   text-align: center;
// }
// .btn:hover{
//   background-color:#f7960b
// }

body{
  // overflow: hidden;
  height: 100%;
}
 .btn{
   background-color:#017ab5;
   color: white;
   margin: auto;
   text-align: center;
 }
.btn:hover{
   background-color:#f7960b
 }
</style>
