<template>
    {{getData}}
        <div v-if="schedule.device" class="my-4">
            <router-link :to="{ name: 'Device', params: { pk: schedule.device}}" class="btn "><i class="bi bi-arrow-left me-2"></i>Back</router-link>
        </div>

        <Card cls="">
            <template v-slot:header>
                Schedule day: {{schedule.day}}
            </template>
            <template v-slot:body>
                <p><b>Day:</b> {{schedule.day}}</p>
                <p><b>Device:</b> {{device.name}}</p>
                <button class="btn" type="button" @click="previewSchedule()"><i class="bi bi-card-image me-2"></i>Preview Schedule</button>  
            </template>
        </Card>

        <Card cls="-xl-12 my-4" icon="">
            <template v-slot:header>
                Campagnes
            </template>
            <template v-slot:body>
                 <button class="btn mt-3 ms-3" type="button" data-bs-toggle="collapse" data-bs-target="#AddCampagneCollapse" aria-expanded="false" aria-controls="AddCampagneCollapse" @click="campagneSearch()"><i class="bi bi-plus-circle me-2"></i>Select campagne</button>
                <div class="collapse" id="AddCampagneCollapse">
                    <div class="card card-body border-success">
                        <SelectCampagne />
                    </div>
                    
                </div>
                    <ToolTip cls="-lg-6 my-4" styl="primary">
                        <template v-slot:body>
                            <i class="bi bi-info-circle">{{Message}}</i>    
                        </template>
                    </ToolTip>
                    <div :v-bind="addEventListeners()">
                    <div class="draggable-list" id="draggable-list">
                        <section id="dragging" class="card-body draggable" draggable="true"
                        v-for="(campagne, index) in scheduleCampagnes" 
                        v-bind:key="campagne.daily"
                        @dragstart="dragStart(index)"
                        :data-index="index"
                        >
                            <div class="card border-actaorangeBg">
                                <div class="card-header-actaorangeBg">
                                        <i class="bi bi-list ms-1 me-1"></i>
                                    #{{index +1}} {{campagne.name}}
                                </div>
                                <div class="card-body table-responsive">
                                <p v-if="!campagne.total_time" class="text-danger mb-0 ms-2"><strong>This campagne has no active playlists!</strong></p>
                                    <table class="table table-borderless mb-0" >
                                        <tbody>
                                            <tr>
                                                <td>Name: {{campagne.name}}</td>
                                                <td>Seconds: {{campagne.total_time}}</td>
                                                
                                                <td class="p-0 text-end">
                                                    <router-link :to="{ name: 'Campagne', params: { pk: campagne.pk}}" class="btn me-2">
                                                        Details
                                                    </router-link>
                                                    <button class="btn" type="button" data-bs-toggle="modal" :data-bs-target="'#campagneModal' + many2many[index].pk">
                                                        <i class="bi bi-trash-fill me-2"></i>Remove Campagne
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="modal fade" :id="'campagneModal' + many2many[index].pk" tabindex="-1" aria-labelledby="campagneModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="campagneModalLabel">Are you sure?</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Are you sure you want to remove {{campagne.name}} from {{schedule.day}}?</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <button type="button" class="btn btn-danger" @click="deleteCampagne(many2many[index].pk)" data-bs-dismiss="modal"><i class="bi bi-trash-fill me-2"></i>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                        
                        </section>
                    </div>  
                </div> 
            </template>
        </Card>
       
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="deleteCampagneToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-danger border-0">
                    <strong class="me-auto">Campagne removed!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The campagne has been removed from this schedule!</p>
                </div>
            </div>
        </div>
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="noCampagnes" class="toast hide text-black bg-warning border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-black bg-warning border-0">
                    <strong class="me-auto">There are no campagnes</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>This schedule has no campagnes, click select campagnes to choose one!</p>
                </div>
            </div>
        </div>
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="createCampagnes" class="toast hide text-black bg-warning border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-black bg-warning border-0">
                    <strong class="me-auto"> <i class="bi bi-info-circle-fill"></i> Campagnes need to be created!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>There are no campanges created, head on over to the campagnes page to create one! <router-link style="text-decoration: none; color: black;" to="/campagnes-overview"><b> Click Here</b></router-link></p>
                </div>
            </div>
        </div>
</template>

<script> 
import SelectCampagne from '../views/SelectCampagne.vue'
import Card from '../components/Card.vue'
import ToolTip from '../components/ToolTip.vue'

const {Toast} = bootstrap

    export default {
        name: 'Schedule',
        components: {
    SelectCampagne,
    Card,
    ToolTip
},
        data() {
            return{
                schedule: [],
                device: [],
                campagnes: [],
                scheduleCampagnes: [],
                many2many: [],
                currentPosition: null,
                dragStartIndex: null,
                dragEndIndex: null,
                Message: ' In this section is where you select your campagnes, To get started click the Add campagne button.'
            }
        },

        computed: {
            getData(){
                //store schedule, devices and campagnes data in local variables
                this.schedule = this.$store.state.ncapiStore.schedule
                // console.log("Schedule Data here", this.schedule)
                const devices = this.$store.state.ncapiStore.devices
                this.campagnes = this.$store.state.ncapiStore.campagnes 
                
                //store device data belonging with this schedule in local variable
                for (var index = 0; index < devices.length; index ++) {
                    if (devices[index].pk === this.schedule.device) {
                        this.device = devices[index]
                    }
                }

                //empty this.scheduleCampagnes and this.many2many
                //store dailyroutineCampagnes data in many2manySorter
                //sort many2manySorter items by position
                this.scheduleCampagnes = []
                this.many2many= []
                const many2manySorter = this.$store.state.ncapiStore.schedulesCampagnes
                many2manySorter.sort((a, b)=>{
                    if(a.position < b.position) return -1
                    else if(a.position > b.position) return 1
                    else return 0
                })

                //store dailyroutineCampagnes connected to this.schedule in this.many2many
                //store campagne data belonging to the dailyroutineCampagne in this.scheduleCampagnes
                for (var index =0; index<many2manySorter.length; index++){
                    if (many2manySorter[index].dailyroutine === this.schedule.pk){
                        this.many2many.push(many2manySorter[index])

                        for(var i=0; i<this.campagnes.length; i++){
                            if(this.campagnes[i].pk === many2manySorter[index].campagne){
                                this.scheduleCampagnes.push(this.campagnes[i])
                            }
                        }
                    }
                }
                // if(this.schedule){
                //     console.log('this is current schedule', this.schedule)
                // }
            },
        },
        mounted() {
            this.getScheduleDetails()
            this.checkSchedule()
        },
        methods: {

                campagneSearch(){
                    if(this.campagnes.length){
                        console.log('log of current created camps', this.campagnes.length)
                    }
                    else {
                        console.log('there is nothing here....this should work as inteded')
                        var toast = new Toast(this.$refs.createCampagnes)
                        toast.show()
                    }
                },


            async checkSchedule(){
                if(this.schedule.campagnes){
                    
                    console.log('this is current schedule number', this.schedule.campagnes.length)
                }
                else{
                    
                    console.log('there is nothing to show here')
                    var toast = new Toast(this.$refs.noCampagnes)
                    toast.show()
                }
            },

            async getScheduleDetails(){
                //load devices, schedule, campagne and scheduleCampagnes data into store
                await this.$store.dispatch("getAll", {type:"device"})
                await this.$store.dispatch("get", {type:"schedule", pk: this.$route.params.pk})
                await this.$store.dispatch("getAll", {type:"campagne"}) 
                await this.$store.dispatch('getAll', {type:"scheduleCampagne"})
            },
            async previewSchedule(){
                //store device.name and schedule.name data in store
                //and go to preview page
                await this.$store.commit("setData", {device: this.device.name , schedule: this.schedule.day})
                this.$router.push("/select-show")
            },

            async deleteCampagne(many2manyPK){
                //store position of tobe deleted many2many campagne relation in currentPosition 
                //call toast and delete many2many relation
                for (var index=0; index<this.many2many.length; index++){
                    if(this.many2many[index].pk === many2manyPK){
                        this.currentPosition = this.many2many[index].position
                    }
                }
                var toast = new Toast(this.$refs.deleteCampagneToast)
                toast.show()
                await this.$store.dispatch("delete", {type:"scheduleCampagne", pk: many2manyPK})

                //update the position order of other many2many relationships
                for(var index=0; index<this.many2many.length; index++){
                    if (this.many2many[index].position > this.currentPosition){
                        let newPosition={
                            position: this.many2many[index].position -1
                        }
                        await this.$store.dispatch("patch", {type:"scheduleCampagne", pk: this.many2many[index].pk, data: newPosition})
                    }
                }

                //load scheduleCampagnes, campagnes and schedule to reload page
                await this.$store.dispatch("getAll", {type:"scheduleCampagne"})
                await this.$store.dispatch("getAll", {type:"campagne"})
                await this.$store.dispatch("get", {type:"schedule", pk: this.$route.params.pk})
            },

            addEventListeners() {
                //get all items with class 'draggable' and all <section> elements with class 'draggable-list'
                const draggables = document.querySelectorAll('.draggable');
                const dragListItems = document.querySelectorAll('.draggable-list section');


                //for each campagne in dragListItems add eventlisteners for dragging
                dragListItems.forEach(view => {
                    view.addEventListener('dragover', this.dragOver);
                    view.addEventListener('drop', this.dragDrop);
                    view.addEventListener('dragenter', this.dragEnter);
                    view.addEventListener('dragleave', this.dragLeave);
                });
                      
            },

            //getDragged(){}
                
           

            dragStart(index, viewPosition) {
                //save the index of currently dragged campagne
                this.dragStartIndex = +index
            },

            dragEnter() {
            // this.classList.add('over');
            //console.log("is moving in")
              document.getElementById("dragging").setAttribute('style', 'opacity:0.7;')
               
            },

            dragLeave() {
            // this.classList.remove('over');
            //console.log("is moving out")
             //document.getElementById("dragging").setAttribute('style', 'opacity:0.2;')
            },

            dragOver(e) {
            e.preventDefault();
            },

            dragDrop(event) {
            //save the index of the campagne that the dragged item was dropped on
            //and call reorderItems
            this.dragEndIndex = +event.currentTarget.getAttribute('data-index');
            this.reorderItems(this.dragStartIndex, this.dragEndIndex);
            // this.classList.remove('over');
            },

            async reorderItems(oldIndex, newIndex){
                //for every many2many relation in many2many check if their position 
                //should be changed because of the dragged campagne
                for (var index = 0; index < this.many2many.length; index ++) {
                    if (index <= newIndex && index > oldIndex){
                        const newPositionOrder = {
                            position: this.many2many[index].position-1
                        }
                        await this.$store.dispatch("patch", {type:"scheduleCampagne", pk: this.many2many[index].pk, data: newPositionOrder})
                    }
                    else if (index >= newIndex && index < oldIndex){
                        const newPositionOrder = {
                            position: this.many2many[index].position+1
                        }
                        await this.$store.dispatch("patch", {type:"scheduleCampagne", pk: this.many2many[index].pk, data: newPositionOrder})
                    }
                }
                
                //save the position where the dragged campagne needs to go and patch this
                let newSpot = this.many2many[newIndex]
                const newPositionOrder = {
                    position: newSpot.position
                }
                await this.$store.dispatch("patch", {type:"scheduleCampagne", pk: this.many2many[oldIndex].pk, data: newPositionOrder})
                this.$store.dispatch("getAll", {type:"scheduleCampagne"})
            },    
            
        }
    }
</script>

<style scoped>
    
.draggable{
    cursor: move;
}

.dragging{
    opacity: 100%;
}
.card-header-actaorangeBg{
background-color: #f7960b !important;
}

.border-actaorangeBg{
    border-color:#f7960b;
}
</style>