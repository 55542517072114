<template>
    <NavBar>
        <template v-slot:navHeader></template>
                <template v-slot:navForm>
                        <div >
                            <ul class="m-0 me-5 nav-item">
                                <li class=" my-auto " style="list-style-type: none;">
                                    <div v-if="authChecking()" class="nav-item dropdown ">
                                        <a class="nav-link dropdown-toggle fs-5 text-white" href="#" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="bi bi-person-fill me-1" aria-label="Account options"></i>
                                        </a>
                                        <ul class="dropdown-menu me-2 mt-3" aria-labelledby="dropdownMenuButton1">
                                            <li><a href="#" class="dropdown-item dropColor fs-5"><i class="bi bi-person-circle me-2 mx-3" aria-label="Account"></i>Account</a></li>
                                            <li><a href="/registration" class="dropdown-item dropColor fs-5"><i class="bi bi-files  me-2 mx-3" aria-label="Administration"></i>Administration</a></li>
                                            <li><a href="/faq" class="dropdown-item dropColor fs-5"><i class="bi bi-question-circle me-2 mx-3" aria-label="FAQ"></i>FAQ</a></li>
                                            <li><hr class="dropdown-divider"></li>
                                            <li><a href="#backDrop" class="dropdown-item dropColor fs-5" data-bs-toggle="modal"><i class="bi bi-box-arrow-right  me-2 mx-3" aria-label="Log out"></i>Log out</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </template>
        </NavBar>
    <div class="container mt-5 w-50">
        <!-- <h3 class="d-flex justify-content-center mt-5"><strong>Loading...</strong></h3>
        <div class="d-flex justify-content-center my-5">
            <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status"></div>
        </div> -->
        <div v-if="errorMessage">
            <p class="text-danger text-center mt-5"><strong>Something went wrong during sign up. Please return to home and try again later.</strong></p>
            <div class="d-flex justify-content-center">
                <router-link :to="{ name: 'Home'}" class="btn">Back to home</router-link>
            </div>
        </div>
        
        <div v-else>

            <RegistrationForm cls="-xl-10" @submit.prevent="submitMerchant(index)">
            <template v-slot:header>Registreer</template>
            <template v-slot:body>
                <Input v-model:modelValue="firstName" cls="-md-12 mb-2" label="First Name"/>
                <Input v-model:modelValue="lastName" cls="-md-12 mb-2" label="Last Name"/>
                <Input id="email"  v-model:modelValue="email" cls="-md-12 mb-2" label="Email" email/>
                <Input id="password"  v-model:modelValue="password" cls="-md-12 mb-2" label="Password" name="up" password />
                
                <div>
                    <Input  id="confirm_password"  v-model="passwordCheck" cls="-md-12 mb-2" label="Confirm Password" required name="up2" password/>
                </div>
                <p class="text-danger" v-if="!passwordMatch"><i class="bi bi-exclamation-triangle-fill me-2"></i>The written passwords don't match, please retype password.</p>
            </template>
        </RegistrationForm>
        
            <!-- <h5 class="card-header-actaorange text-white"></h5>
            <div class="card-body">
                <h2 class="text-actablue mb-2 text-center">Registreer</h2>
                <form @submit.prevent="submitMerchant(index)">
                    <div class="field mb-3">
                    <label>First Name</label>
                        <div class="control">
                            <input type="text" class="input form-control" v-model="firstName" required>
                        </div>
                    </div>

                    <div class="field mb-3">
                        <label>Last Name</label>
                        <div class="control">
                            <input type="text" class="input form-control" v-model="lastName" required>
                        </div>
                    </div>

                    <div class="field mb-3">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" class="input form-control" v-model="email" required name="un">
                        </div>
                    </div>

                    <div class="field mb-3">
                        <label>Password</label>
                        <div class="control">
                            <input type="password" class="input form-control" id="password" v-model="password" required name="up">
                        </div>
                    </div>

                    <div class="field mb-3">
                        <label>Confirm password</label>
                        <div class="control">
                            <input type="password" class="input form-control" id="confirm_password" v-model="passwordCheck" required name="up2">
                        </div>
                        <p class="text-danger" v-if="!passwordMatch"><i class="bi bi-exclamation-triangle-fill me-2"></i>The passwords don't match</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="btn btn-primary mt-2">Volgende</button>
                        </div>
                    </div>
                </form>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "signUp",
    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordCheck: "",
            passwordMatch: true,
            merchID: "",
            errorMessage: false,
        };
    },
    mounted() {
    },
    methods: {

            authChecking(){
                if(this.$store.state.user.isAuthenticated){
                    console.log('is authenticated')
                }
            },  

        async submitMerchant() {
            console.log("submitting");
            if (this.password !== this.passwordCheck) {
                this.passwordMatch = false;
            }
            else {
                console.log("nah is fine");
                const reUseData = {
                    mail: this.email,
                    firstName: this.firstName,
                    lastName: this.lastName,
                };
                this.$store.commit("setRegistrationData", reUseData);
                const createUser = {
                    username: this.email,
                    password: this.password,
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email: this.email,
                };
                await this.$store.dispatch("postMerchant", createUser)
                    .then(() => {
                    console.log("success");
                    // this.loading = false
                    this.merchID = this.$store.state.octoblueStore.merchID;
                    this.$router.push("/admin-registration");
                })
                    .catch((error) => {
                    console.log("something went wrong: ", error);
                    this.errorMessage = true;
                });
                // this.$router.push("/admin-registration")
            }
        },
    },
}
</script>
