<template>
    <div class="container" style="min-height:100vh"> {{getData}}
        <h2 class="my-4">Preview</h2>

        <form v-if="previewDevice" @submit.prevent="submitForm(index)">
            <div class="field">
                <label>Device</label>
                <div class="control">
                    <div class="select">
                        <select v-model="selectedDevice" class="form-select">
                            <option disabled value="">Please select one</option>
                            <option 
                            v-for="device in devices" 
                            v-bind:key="device.select"
                            >
                                {{device.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="field">
                <label>Schedule</label>
                <div class="control">
                    <div class="select">
                        <select v-model="selectedSchedule" class="form-select">
                            <option disabled value="">Please select one</option>
                            <option 
                            v-for="deviceSchedule in deviceSchedules" 
                            v-bind:key="deviceSchedule.select"
                            >
                                {{deviceSchedule.day}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="field">
                    <div class="control my-3">
                        <button class="btn  me-2"><i class="bi bi-play-fill"></i>Play</button>
                        <!-- button to switch between device and campagne previews -->
                        <button v-if="!previewDevice" class="btn " @click="switchPreview()" type="button">Select device</button>
                        <button v-if="previewDevice" class="btn " @click="switchPreview()" type="button">Select campagne</button>
                    </div>
                </div>
        </form>
        
        <form v-if="!previewDevice" @submit.prevent="submitForm(index)">
            <div class="field">
                <label>Campagne</label>
                <div class="control">
                    <div class="select">
                        <select v-model="selectedCampagne" class="form-select">
                            <option disabled value="">Please select one</option>
                            <option 
                            v-for="campagne in campagnes" 
                            v-bind:key="campagne.select"
                            >
                                {{campagne.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="field">
                <div class="control my-3">
                    <button class="btn btn-success me-2"><i class="bi bi-play-fill"></i>Play</button>
                    <!-- button to switch between device and campagne previews -->
                    <button v-if="!previewDevice" class="btn btn-primary" @click="switchPreview()" type="button">Preview device</button>
                    <button v-if="previewDevice" class="btn btn-primary" @click="switchPreview()" type="button">Preview campagne</button>
                </div>
            </div>
        </form>

        <Preview v-if="submitPreview && previewDevice"  v-bind:deviceName="selectedDevice" v-bind:objDeviceSchedules="deviceSchedules" v-bind:scheduleName="selectedSchedule" ref="devicePreviewRef" />
        <Preview v-if="submitPreview && !previewDevice"  v-bind:campagneName="selectedCampagne" ref="previewComponentRef" />

        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="noSelectToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-danger border-0">
                    <strong class="me-auto">Nothing selected!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>Please select a device or campagne from the dropdown menu!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Preview from './Preview.vue'

const {Toast} = bootstrap

export default {
    name:'SelectShow',
    components: {
        Preview,
    },
    data() {
        return{
            devices: [],
            selectedDevice: "",
            deviceSchedules: [],
            schedules: [],
            selectedSchedule: "",
            campagnes: [],
            selectedCampagne: "",
            previewDevice: true,
            submitPreview: false,
        }
    },
    computed: {
        getData(){
            //store data of devices, schedules and campagnes in local variable
            this.devices = this.$store.state.ncapiStore.devices
            this.schedules = this.$store.state.ncapiStore.schedules
            this.campagnes = this.$store.state.ncapiStore.campagnes

            //store the schedule data of schedules in a selected device in deviceSchedules local variable
            this.deviceSchedules=[]
            for (var index=0; index<this.schedules.length; index++){
                for (var i=0; i<this.devices.length; i++){
                    if(this.devices[i].name === this.selectedDevice){
                        const chosenDevice = this.devices[i]

                        if(this.schedules[index].device === chosenDevice.pk){
                            this.deviceSchedules.push(this.schedules[index])
                        }
                    }
                }   
            }
        }
    },
    mounted(){
        this.onPageLoad()
    },
    methods: {
        async onPageLoad() {
            //load data of devices, schedules and campagnes from API into store
            await this.$store.dispatch("getAll", {type:"device"})
            await this.$store.dispatch("getAll", {type:"schedule"})
            await this.$store.dispatch("getAll", {type:"campagne"})

            //check if data is available in passdata in store, which happens when using a shortcut
            //from campagne/schedule/device page to preview said item
            if(this.$store.state.passdata.passCampagne){
                this.selectedCampagne = this.$store.state.passdata.passCampagne
                this.previewDevice = false
            }
            if(this.$store.state.passdata.passDevice){
                this.selectedDevice = this.$store.state.passdata.passDevice
                this.previewDevice = true

                if(this.$store.state.passdata.passSchedule){
                    this.selectedSchedule = this.$store.state.passdata.passSchedule
                }
            }
            
        },
        async switchPreview(){
            //switch between previewing campagne selection and previewing device/schedule selection
            //and remove passdata from store
            if(this.previewDevice){
                this.previewDevice = false
                this.submitPreview = false
                await this.$store.commit("stopData")
            }
            else {
                this.previewDevice = true
                this.submitPreview = false
                await this.$store.commit("stopData")
            }
        },
        async submitForm(){
            //show toast if nothing is selected
            //else remove passdata from store and turn submitPreview true to show preview component
            //first false to correctly switch when previewing again in same session
            if(!this.selectedCampagne && !this.selectedDevice){
                var toast = new Toast(this.$refs.noSelectToast)
                toast.show()
            }
            else{
                this.submitPreview = false
                await this.$store.commit("stopData")

                setTimeout(() => {
                        this.submitPreview = true
                    }, 
                    1
                );    
            } 
        },
    },
}
</script>

<style>

</style>