<template>
    <div class="container my-4">{{getData}}
    <Card cls="-xl-10 my-4">
        <template v-slot:header>
            Device: {{device.name}}
        </template>
        <template v-slot:body>
            <p>Name: {{device.name}}</p>
                    <p>Show page URL: <router-link :to="{ name: 'ShowPage', params: { pk: device.pk}}" target="_blank">https://narrowcasting.actablue.com/show/{{device.pk}}</router-link></p>
                    <p>Location: {{location.name}}</p>
                    <p>Screen rotation: {{device.screen_rotation}}</p>
                    <button type="button" class="btn ms-2 mb-1" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-pencil-square me-2"></i>Edit Device</button>
                    <button class="btn ms-2 mb-1" type="button" data-bs-toggle="modal" data-bs-target="#deviceModal"><i class="bi bi-trash-fill me-2"></i>Delete Device</button>
                    <button class="btn ms-2 mb-1" type="button" @click="previewDevice()"><i class="bi bi-card-image me-2"></i>Preview Device</button>                            
                    <button @click="goShow();" class="btn ms-2 mb-1" type="button" ><i class="bi bi-card-image me-2"></i>Show Page</button> 
                    
                     <div class="modal fade" id="deviceModal" tabindex="-1" aria-labelledby="deviceModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="deviceModalLabel">Are you sure?</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <p>Are you sure you want to delete {{device.name}}?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" class="btn" @click="deleteDevice()" data-bs-dismiss="modal"><i class="bi bi-trash-fill me-2"></i>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div> 

                     <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
                        <div ref="deleteDeviceToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="toast-header text-white bg-danger border-0">
                                <strong class="me-auto">Just a second</strong>
                                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                            </div>
                                <div class="toast-body">
                                    <p>Your device is being deleted!</p>
                                </div>
                        </div>
                     </div> 
                      <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
                        <div ref="numDeviceToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="toast-header text-white bg-danger border-0">
                                <strong class="me-auto">Currently</strong>
                                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                            </div>
                                <div class="toast-body">
                                    <p>There are these many devices</p>
                                </div>
                        </div>
                     </div> 

                       <div class="collapse" id="collapseExample">
                        <div class="card card-body">
                            <EditDevice />
                        </div>
                    </div>
        </template>
    </Card>

    <Card cls="">
        <template v-slot:header>Schedules</template>
        <template v-slot:body>
              <table class="table table-borderless table-hover mt-2">
                    <thead>
                        <tr>
                            <th>Schedule day</th>
                            <th>Amount of campagnes</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody v-for="(schedule, index) in schedules"
                            v-bind:key="schedule.pk"
                            @click="goDetails(index)">
                        <tr v-if="schedule.campagnes.length > 0">  
                            <td class="m-3">{{schedule.day}}</td>
                            <td>{{schedule.campagnes.length}}</td>
                            <td>
                                <router-link :to="{ name: 'Schedule', params: { pk: schedule.pk}}" class="btn">Details</router-link>
                            </td>  
                        </tr>
                        <tr v-else class="bg-light">
                            <td class="m-3">{{schedule.day}}</td>
                            <td class="text-muted">{{schedule.campagnes.length}}</td>
                            <td>
                                <router-link :to="{ name: 'Schedule', params: { pk: schedule.pk}}" class="btn">Details</router-link>
                            </td>  
                        </tr>
                    </tbody>
                </table>
                <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
                        <div ref="emptySchedule" class="toast hide text-black bg-warning border-0" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="toast-header text-black bg-warning border-0">
                                <strong class="me-auto">There are empty schedules</strong>
                                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                            </div>
                                <div class="toast-body">
                                    <p>Click 'details' to add campagnes to the schedule</p>
                                </div>
                        </div>
                </div> 
        </template>
    </Card>
</div>    
</template>

<script> 
import EditDevice from '../views/EditDevice.vue'
import AddDevice from '../views/AddDevice.vue'
const {Toast} = bootstrap
    export default {
        name: 'Device',
        components: {
            EditDevice,
            AddDevice,
        },

        data() {
            return{
                device: [],
                schedules: [],
                location: [],
                deviceCampagnes: [],
            }
        },
        computed: {
            getData(){
                //store data of device and locations in local variables
                this.device = this.$store.state.ncapiStore.device
                const locations = this.$store.state.octoblueStore.locations
                

                //store data of location belonging to this.device in local variable
                for (var index = 0; index < locations.length; index ++) {
                    if (locations[index].id === this.device.location_id) {
                        this.location = locations[index]
                    }
                }

                //store data of schedules in local variable and sort through them
                //to find the schedules that belong to this device
                //sort them by day
                const allSchedules = this.$store.state.ncapiStore.schedules
                this.schedules = []
                for (var index = 0; index < allSchedules.length; index++){
                    if (allSchedules[index].device === this.device.pk){
                        this.schedules.push(allSchedules[index])
                    }
                }
                const map = {
                    'monday': 1,'tuesday': 2,'wednesday': 3,'thursday': 4,
                    'friday': 5,'saturday': 6, 'sunday': 7
                };
                this.schedules.sort((a, b)=>{
                    return map[a.day] - map[b.day]
                })
            }
        },
        mounted() {
            this.getDeviceDetails()
            this.checkDevicesSchedule()
        },
        methods: {
            
            //check for device schedules for each day
            async checkDevicesSchedule(){
                if(this.schedules){
                    console.log('this is the device and its info',this.schedules)
                }
                for(var index = 0; index < this.schedules.length; index++){
                    if(this.schedules[index].campagnes){
                        this.deviceCampagnes = this.schedules[index].campagnes
                        console.log('this is the camps', this.deviceCampagnes)
                    }
                }
              if(this.deviceCampagnes.length === 0){
                  console.log('some of the schedules are empty')
                  var toast = new Toast(this.$refs.emptySchedule)
                  toast.show()
              }
            },
            

            async getDeviceDetails(){
                //load locations, device and schedules into store from API
                this.$store.dispatch("getAllLocations")
                await this.$store.dispatch("get", {type:"device", pk: this.$route.params.pk})
                await this.$store.dispatch("getAll", {type:"schedule"})
                
                // console.log(this.device)
            },
            async deleteDevice(){
                //delete device from API, load correct list of devices into store from API and 
                //go to device overview page
                //call tasty toast to delete the device and show msg in the corner screen
                
                var toast = new Toast(this.$refs.deleteDeviceToast)
                toast.show()
                await this.$store.dispatch("delete", {type:"device", pk: this.$route.params.pk})
                await this.$store.dispatch("getAll", {type:"device"})

                   setTimeout(() => {
                    this.$router.push('/devices-overview') }, 
                    5000
                );
            },

            async showToasty(){
                var toast = new Toast(this.$refs.testToast)
                toast.show()
            },
            created(){
                this.$root.$refs.Device.component = this;
            },

            async previewDevice(){
                //save device.name into store and go to preview page
                await this.$store.commit("setData", {device: this.device.name})
                this.$router.push("/select-show")
            },
            goDetails(index){
                //go to schedule details page of selected schedule
                this.$router.push(`/schedules-overview/${this.schedules[index].pk}`)
            },
            goShow(){
                let route = this.$router.resolve({name: "ShowPage", params: {pk: this.device.pk}})
                window.open(route.href, "show")
            },
        }
    }
</script>
<style scoped>
td{
    cursor: pointer;
}
</style>
