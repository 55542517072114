<template>
        <div v-if="campagneName">
            <div class="card border-actaDarkblue col-12">
                <div class="row g-0">
                    <div class="col-lg-6 border-end-actaDarkblue">
                        <h5 class="card-header-actaDarkblue text-white bg-actaDarkblue">Previewing: {{campagne.name}}</h5>
                        <div class="card-body">
                            <p>Campagne: {{campagne.name}} </p>
                            <p>Total time: {{campagne.total_time}} </p>
                            <div v-if="campagne.playlists">
                                <div v-if="!activePlaylists.length">
                                    <p class="text-danger"><strong>this campagne has no active playlists</strong></p>
                                </div>
                                <div v-else>
                                    <p>Current Playlist: {{showingPlaylist.name}}</p>
                                    <div v-for="viewInfo in showingView" v-bind:key="viewInfo.show">
                                        <p v-if="viewInfo.view">{{viewInfo.name}}: {{viewInfo.view.name}}</p>
                                    </div>
                                    <p>Time until next Playlist: {{playlistTimer}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card-body">
                            <div class="container text-light  p-0 my-5" >
                                <div class="position-relative m-auto align-center bg-dark showpreview">
                                    <div v-for="viewSort in showingView" v-bind:key="viewSort.sorter">
                                        <div v-if="viewSort.name ==='Fullscreen'" class="position-absolute full-view top-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <video v-if="viewSort.view.content_type === 'video'" :src="viewSort.view.video" controls autoplay style="width:100%; height:100%" class="rounded mx-auto d-block"></video>
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>
                                        </div>
                                        <div v-if="viewSort.name ==='Top Left'" class="position-absolute border-end border-bottom border-light border-2 quarter-view top-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>
                                        </div>
                                        <div v-if="viewSort.name ==='Left'" class="position-absolute border-end border-light border-2 vertical-view top-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Bottom Left'" class="position-absolute border-end border-top border-light border-2 quarter-view bottom-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Top Right'" class="position-absolute border-start border-bottom border-light border-2 quarter-view top-0 end-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Right'" class="position-absolute border-start border-light border-2 vertical-view top-0 end-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Bottom Right'" class="position-absolute border-start border-top border-light border-2 quarter-view bottom-0 end-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Top'" class="position-absolute border-bottom border-light border-2 horizontal-view top-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Bottom'" class="position-absolute border-top border-light border-2 horizontal-view bottom-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>

        <div v-if="deviceName">
            <div class="card border-actaDarkblue col-12">
                <div class="row g-0">
                    <div class="col-lg-6 border-end-actaDarkblue">
                        <h5 class="card-header-actaDarkblue text-white bg-actaDarkblue">Previewing: {{device.name}}</h5>
                        <div class="card-body">
                            <p>Device: {{device.name}}</p>
                            <p>Schedule: {{schedule.day}}</p>
                            <div v-if="schedule.campagnes">
                                <div v-if="!schedule.campagnes.length">
                                    <p class="text-danger"><strong>this schedule has no campagnes</strong></p>
                                </div>
                                <div v-else>
                                    <p>Current Campagne: {{campagne.name}}</p>
                                    <p>Total time: {{campagne.total_time}}</p>
                                    <div v-if="campagne.playlists">
                                        <div v-if="!activePlaylists.length">
                                            <p class="text-danger"><strong>this campagne has no active playlists</strong></p>
                                        </div>
                                        <div v-else>
                                            <p>Current Playlist: {{showingPlaylist.name}}</p>
                                            <div v-for="viewInfo in showingView" v-bind:key="viewInfo.show">
                                                <p v-if="viewInfo.view">{{viewInfo.name}}: {{viewInfo.view.name}}</p>
                                            </div>
                                            <p>Time until next Playlist: {{playlistTimer}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card-body">
                            <div class="container text-light  p-0 my-5" >
                                <div class="position-relative m-auto align-center bg-dark showpreview">
                                    <div v-for="viewSort in showingView" v-bind:key="viewSort.sorter">
                                        <div v-if="viewSort.name ==='Fullscreen'" class="position-absolute full-view top-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <video v-if="viewSort.view.content_type === 'video'" :src="viewSort.view.video" controls autoplay style="width:100%; height:100%" class="rounded mx-auto d-block"></video>
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>
                                        </div>
                                        <div v-if="viewSort.name ==='Top Left'" class="position-absolute border-end border-bottom border-light border-2 quarter-view top-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>
                                        </div>
                                        <div v-if="viewSort.name ==='Left'" class="position-absolute border-end border-light border-2 vertical-view top-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Bottom Left'" class="position-absolute border-end border-top border-light border-2 quarter-view bottom-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Top Right'" class="position-absolute border-start border-bottom border-light border-2 quarter-view top-0 end-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Right'" class="position-absolute border-start border-light border-2 vertical-view top-0 end-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Bottom Right'" class="position-absolute border-start border-top border-light border-2 quarter-view bottom-0 end-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Top'" class="position-absolute border-bottom border-light border-2 horizontal-view top-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                        <div v-if="viewSort.name ==='Bottom'" class="position-absolute border-top border-light border-2 horizontal-view bottom-0 start-0">
                                            <!-- <p class="m-auto">{{viewSort.view.name}}</p> -->
                                            <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="mx-auto d-block">
                                            <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                                            <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                                            <!-- <p v-if="viewSort.view.content_type === 'url'" class="m-auto">{{viewSort.view.url}}</p> -->
                                            <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                        
        </div>
</template>

<script>
export default {
    name:"Preview",
    props: {
        deviceName: {
            type: String
        },
        scheduleName: {
            type: String
        },
        campagneName: {
            type: String
        },
        objDeviceSchedules: {
            type: Object 
        },
    },
    data() {
        return{
            campagne: [],
            device: [],
            schedule: [],
            activePlaylists: [],
            showingPlaylist: [],
            scheduleCampagnes: [],
            stopTimer: false,
            playlistTimer: 0,
            timerVar: "",
            showingView: {
                1: [],
                2: [],
                3: [],
                4: [],
            },
            usedViews: [],
            views: {
                fullscreen: [],
                left: [],
                right: [],
                top: [],
                bottom: [],
                topRight: [],
                topLeft: [],
                bottomRight: [],
                bottomLeft: [],
            },
            
            
        }
    },
    mounted(){
        this.onLoad()
        
    },
    beforeUnmount(){
        //clear timer and set stopTimer to true to stop all previewing processes
        clearInterval(this.timerVar)
        this.stopTimer = true
        // console.warn("BEFORE UNMOUNT", this.stopTimer)
    },
    unmounted(){
        // console.warn("UNMOUNTED")

    },
    methods: {
        async onLoad(){
            //load in campagnes data in store
            await this.$store.dispatch("getAll", {type:"campagne"})

            // sending the data to the right method
            if(this.campagneName){
                this.previewCampagne()
            }
            if(this.scheduleName){
                this.previewSchedule()
            }
            if(this.deviceName && !this.scheduleName){
                this.previewDevice()
            }
        },
        async previewDevice(){
            // getting the data from the selected device
            const deviceSorter = await this.$store.state.ncapiStore.devices

            for(var index = 0; index <deviceSorter.length; index++){
                if(deviceSorter[index].name === this.deviceName){
                    this.device = deviceSorter[index]
                }
            }

            // getting today's day and getting the data from the right schedule
            const weekday = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];

            const d = new Date();
            let day = weekday[d.getDay()];

            for(var index = 0; index<this.objDeviceSchedules.length; index++){
                if(this.objDeviceSchedules[index].day === day){
                    this.schedule = this.objDeviceSchedules[index]
                }
            }

            this.scheduleSorter()
        },
        async previewSchedule(){
            // getting the data from the selected device
            const deviceSorter = await this.$store.state.ncapiStore.devices

            for(var index = 0; index <deviceSorter.length; index++){
                if(deviceSorter[index].name === this.deviceName){
                    this.device = deviceSorter[index]
                }
            }

            // getting the data from the selected schedule
            for(var index = 0; index<this.objDeviceSchedules.length; index++){
                if(this.objDeviceSchedules[index].day === this.scheduleName){
                    this.schedule = this.objDeviceSchedules[index]
                }
            }
            this.scheduleSorter()
        },
        async scheduleSorter(){
            // getting the campagnes in the schedule
            const campagneSorter = await this.$store.state.ncapiStore.campagnes
            this.scheduleCampagnes = []
            

            //sort campagnes by position
            this.schedule.campagnes.sort((a, b)=>{
                if(a.position < b.position) return -1
                else if(a.position > b.position) return 1
                else return 0
            })
            
            for(var i=0; i<this.schedule.campagnes.length; i++){
                for(var index = 0; index <campagneSorter.length; index++){
                    if(this.schedule.campagnes[i].campagne === campagneSorter[index].pk){
                        this.scheduleCampagnes.push(campagneSorter[index])
                    }
                }
            }
            
            // loop through campagnes, get activeplaylists for campagne 
            // and play it before going to next campagne
            for (var index = 0; index<this.scheduleCampagnes.length; index++){
                this.campagne = await this.scheduleCampagnes[index]

                //sort playlists by position order
                this.campagne.playlists.sort((a, b)=>{
                    if(a.position_order < b.position_order) return -1
                    else if(a.position_order > b.position_order) return 1
                    else return 0
                })

                this.activePlaylists = []
                for(var i = 0; i<this.campagne.playlists.length; i++){
                    if (this.campagne.playlists[i].status === 1){
                        this.activePlaylists.push(this.campagne.playlists[i])
                    } 
                }

                await this.play()
                // console.log("next campagne")
            }
        },
        async previewCampagne(){
            // get data of selected campagne
            const campagneSorter = await this.$store.state.ncapiStore.campagnes

            for(var index = 0; index <campagneSorter.length; index++){
                if(campagneSorter[index].name === this.campagneName){
                    this.campagne = campagneSorter[index]
                }
            }

            //sort playlists by position order
                this.campagne.playlists.sort((a, b)=>{
                    if(a.position_order < b.position_order) return -1
                    else if(a.position_order > b.position_order) return 1
                    else return 0
                })

            // get all active playlists of campagne and play them
            this.activePlaylists = []
            for(var index = 0; index<this.campagne.playlists.length; index++){
                if (this.campagne.playlists[index].status === 1){
                    this.activePlaylists.push(this.campagne.playlists[index])
                }
            }

            this.play()
        },
        async play(){
            // loop playlists and save current playlist as showingPlaylist
            for(var index=0; index<this.activePlaylists.length; index++){
                this.showingPlaylist = this.activePlaylists[index]
                // this.playlistTimer = this.activePlaylists[index].seconds

                var playlistTotalSeconds = 0
                //check if the playlist is fullscreen. if yes, check if it has video views and nonvideoviews
                //calculate the playlistTotalSeconds 
                if(this.activePlaylists[index].layout === 'fullscreen'){
                    console.log(this.activePlaylists[index])
                    //calculate how many nonVideoViews there are
                    var nonVideoViews =0
                    playlistTotalSeconds = 0
                    for (var i = 0; i < this.activePlaylists[index].views.length; i ++){
                        if(this.activePlaylists[index].views[i].content_type !== 'video'){
                            nonVideoViews = nonVideoViews+1
                        }
                    }
                    if(nonVideoViews !== 0){
                        playlistTotalSeconds = playlistTotalSeconds + this.activePlaylists[index].seconds
                    }
                    playlistTotalSeconds = playlistTotalSeconds + this.activePlaylists[index].video_seconds
                    
                }
                else{
                    playlistTotalSeconds = 0
                    playlistTotalSeconds = this.activePlaylists[index].seconds
                }
                this.playlistTimer = playlistTotalSeconds
                console.log(playlistTotalSeconds)

                if (this.playlistTimer > 0 && this.stopTimer !== true) {
                    this.timerVar = setInterval(() => {
                        
                        this.playlistTimer= this.playlistTimer-1
                        if(this.playlistTimer <=0){
                            clearInterval(this.timerVar)
                        }
                        }, 
                        1000
                    );    
                }

                // sort views of playlist by position order
                this.activePlaylists[index].views.sort((a, b)=>{
                    if(a.position_order < b.position_order) return -1
                    else if(a.position_order > b.position_order) return 1
                    else return 0
                })

                // clear to make sure no double views:
                this.views.fullscreen = []
                this.views.left = []
                this.views.right = []
                this.views.top = []
                this.views.bottom = []
                this.views.topRight = []
                this.views.topLeft = []
                this.views.bottomRight = []
                this.views.bottomLeft = []
                // sort views in positions:
                for (var i = 0; i < this.activePlaylists[index].views.length; i ++) {
                    if (this.activePlaylists[index].views[i].position === 'fullscreen') {
                        this.views.fullscreen.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'left') {
                        this.views.left.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'right') {
                        this.views.right.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'top') {
                        this.views.top.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'bottom') {
                        this.views.bottom.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'topright') {
                        this.views.topRight.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'topleft') {
                        this.views.topLeft.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'bottomright') {
                        this.views.bottomRight.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'bottomleft') {
                        this.views.bottomLeft.push(this.activePlaylists[index].views[i])
                    }
                }

                // save sorted views + position name in usedViews array
                this.usedViews = []
                if(this.activePlaylists[index].layout === 'fullscreen'){
                    const fullscreen = {
                        name: "Fullscreen",
                        views: this.views.fullscreen
                    }
                    this.usedViews.push(fullscreen)
                }
                if (this.activePlaylists[index].layout === 'verticalsplit' || this.activePlaylists[index].layout === 'tsplitleft'){
                    const left = {
                        name: "Left",
                        views: this.views.left
                    }
                    this.usedViews.push(left)
                }
                if (this.activePlaylists[index].layout === 'verticalsplit' || this.activePlaylists[index].layout === 'tsplitright'){
                    const right = {
                        name: "Right",
                        views: this.views.right
                    }
                    this.usedViews.push(right)
                }
                if (this.activePlaylists[index].layout === 'horizontalsplit' || this.activePlaylists[index].layout === 'tsplittop'){
                    const top = {
                        name: "Top",
                        views: this.views.top
                    }
                    this.usedViews.push(top)
                }
                if (this.activePlaylists[index].layout === 'horizontalsplit' || this.activePlaylists[index].layout === 'tsplitbottom'){
                    const bottom = {
                        name: "Bottom",
                        views: this.views.bottom
                    }
                    this.usedViews.push(bottom)
                }
                if (this.activePlaylists[index].layout === 'crosssplit' || this.activePlaylists[index].layout === 'tsplitright' || this.activePlaylists[index].layout === 'tsplitbottom'){
                    const topLeft = {
                        name: "Top Left",
                        views: this.views.topLeft
                    }
                    this.usedViews.push(topLeft)
                }
                if (this.activePlaylists[index].layout === 'crosssplit' || this.activePlaylists[index].layout === 'tsplitleft' || this.activePlaylists[index].layout === 'tsplitbottom'){
                    const topRight = {
                        name: "Top Right",
                        views: this.views.topRight
                    }
                    this.usedViews.push(topRight)
                }
                if (this.activePlaylists[index].layout === 'crosssplit' || this.activePlaylists[index].layout === 'tsplitright' || this.activePlaylists[index].layout === 'tsplittop'){
                    const bottomLeft = {
                        name: "Bottom Left",
                        views: this.views.bottomLeft
                    }
                    this.usedViews.push(bottomLeft)
                }
                if (this.activePlaylists[index].layout === 'crosssplit' || this.activePlaylists[index].layout === 'tsplitleft' || this.activePlaylists[index].layout === 'tsplittop'){
                    const bottomRight = {
                        name: "Bottom Right",
                        views: this.views.bottomRight
                    }
                    this.usedViews.push(bottomRight)
                }
                
                // initial index counter for showingView
                var showIndex = 1
                // make sure no double showViews
                this.showingView[1]=[]
                this.showingView[2]=[]
                this.showingView[3]=[]
                this.showingView[4]=[]

                // loop through usedViews (which has position and sorted views)
                // play them in showViewSorter, add 1 to showIndex for next position
                for(var i=0; i<this.usedViews.length; i++){
                    this.showViewSorter(this.usedViews[i], showIndex)
                    showIndex++
                }

                // Wait till this playlist is finished playing, then go to next loop
                const waitTime = this.playlistTimer*1000
                await this.sleep(waitTime)
                if(this.stopTimer === true){
                    break
                }
                // console.log("next playlist")
                clearInterval(this.timerVar)
                this.playlistTimer = 0
            }
        },
        async showViewSorter(positionViewList, showIndex){
            // loop through positionViewList(specific usedViews(has position and sorted views))
            // save position and specific view in showingView and wait till it finished playing
            // then go to next view loop
            for(var index=0; index<positionViewList.views.length; index++){
                const show = {
                    name: positionViewList.name,
                    view: positionViewList.views[index]
                }
                this.showingView[showIndex] = show
                const waitTime = this.showingView[showIndex].view.seconds*1000
                await this.sleep(waitTime)
            
                if(this.stopTimer === true){
                    break
                }
                // console.log("next view")
            }
        },
        
        sleep(ms) {     
            if(this.stopTimer === false){
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        },    
    }
}
</script>

<style scoped>
.showpreview{
    border: 15px black;
}
@media only screen and (max-width: 576px) {
  .showpreview {width: 225px;
    height: 150px;}
}
@media only screen and (min-width: 576px) {
  .showpreview {width: 450px;
    height: 300px;}
}
@media only screen and (min-width: 768px) {
  .showpreview {width: 450px;
    height: 300px;}
}
@media only screen and (min-width: 992px) {
  .showpreview {width: 450px;
    height: 300px;}
}

.quarter-view{
    width: 50%;
    height: 50%;
    display: flex;
}
.horizontal-view{
    width: 100%;
    height: 50%;
    display: flex;
}
.vertical-view{
    width: 50%;
    height: 100%;
    display: flex;
}
.full-view{
    width: 100%;
    height: 100%;
    display: flex;
}
</style>