import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import Home from '../views/Home.vue'
import Administrations from '../views/Administrations.vue'
import Dashboard from '../views/Dashboard.vue'
import DevicesOverview from '../views/DevicesOverview.vue'
import Device from '../views/Device.vue'
import CampagnesOverview from '../views/CampagnesOverview.vue'
import Campagne from '../views/Campagne.vue'
import Schedule from '../views/Schedule.vue'
import Playlist from '../views/Playlist.vue'
import SelectShow from '../views/SelectShow.vue'
import Show from '../views/Show.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import AdminRegistration from '../views/AdminRegistration.vue'
import Faq from '../views/Faq.vue'
import FaqPortal from '../views/FaqPortal.vue'
// import TestingCompnents from '../components/TestingCompnents.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/admin-registration',
    name: 'AdminRegistration',
    component: AdminRegistration,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },  
  {
    path: '/show/:pk?',
    name: 'ShowPage',
    component: Show,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/dev',
    name: 'Dev',
    component: () => import( '../views/dev/Dev.vue'),
    meta: {
      requireLogin: true
    },
    children: [

    ]
  },
  {
    path: '/registration',
    name: 'registration',
    component: Administrations,
    meta: {
      requireLogin: true
    },



    children: [
      
      {
        path: '/logout',
        name: 'logout',
      },
    ]
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      requireLogin: true
    },
  },
  {
    path: '/faqportal',
    name: 'FaqPortal',
    component: FaqPortal,
    meta: {
      requireLogin: true
    },
  },
  {
    /**
     *  All child routes of Narrowcasting should go in the children object
     */
    path: '/narrowcasting',
    name: 'Narrowcasting',
    component: () => import('../views/narrowcasting/Narrowcasting.vue'),
    meta: {
      requireLogin: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          requireLogin: true
        }
      },
      {
        path: '/devices-overview',
        name: 'DevicesOverview',
        component: DevicesOverview,
        meta: {
          requireLogin: true
        }
      },
      {
        path: '/devices-overview/:pk',
        name: 'Device',
        component: Device,
        meta: {
          requireLogin: true
        }
      },
      {
        path: '/campagnes-overview',
        name: 'CampagnesOverview',
        component: CampagnesOverview,
        meta: {
          requireLogin: true
        }
      },
      {
        path: '/campagnes-overview/:pk',
        name: 'Campagne',
        component: Campagne,
        meta: {
          requireLogin: true
        }
      },
      {
        path: '/schedules-overview/:pk',
        name: 'Schedule',
        component: Schedule,
        meta: {
          requireLogin: true
        }
      },
      {
        path: '/campagnes-overview/playlist/:pk',
        name: 'Playlist',
        component: Playlist,
        meta: {
          requireLogin: true
        }
      },
      {
        path: '/select-show',
        name: 'SelectShow',
        component: SelectShow,
        meta: {
          requireLogin: true
        },
      },
    ]
  },

  {
    /**
     *  All child routes of Schapkaartjes should go in the children object
     */
    path: '/schapkaartjes',
    name: 'Schapkaartjes',
    component: () => import('../views/barcode/Barcode.vue'),
    meta: {
      requireLogin: true
    },
    children: [
      /** child routes go here */
      /*{
        path: '',
        name: '',
        component: () => import('')
      },*/
    ]
  },

  {
    /**
     *  All child routes of FolderApp should go in the children object.
     */
    path: '/folder', 
    name: 'Folder',
    component: () => import('../views/folder/Folder.vue'),
    meta: {
      requireLogin: true
    },
    children: [
      /** child routes go here */
      /*{
        path: '',
        name: '',
        component: () => import('')
      },*/
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async(to, from, next)=> {
  window.scrollTo({top:0, left:0, behavior:'instant'})
  
  // store.dispatch('key/refresh')
  // store.dispatch('key/check')
  // if (!store.state.user.isAuthenticated) return '/'
  // else {next()}
  // if (to.name !== 'Home' && !store.state.user.isAuthenticated) next({ name: 'Home' })
  // else next()
  
  if (to.matched.some(record => record.meta.requireLogin)) {
  
    if(!store.state.user.isAuthenticated){
      store.dispatch('key/login')
      next()
      // next('/login')
    }
    else{
      var checkTime = new Date().getTime()
      var timePast = (checkTime - store.state.user.loginTime)/1000
      console.log("timepast: ", checkTime, " - ", store.state.user.loginTime, "/1000 = ", timePast)

      if(timePast <= 1499){
        // console.log("NOTHING UP GOOD ACCESSTOKEN")
      }
      else if (timePast>= 1499 & timePast<= 1799){
        // console.log("GET NEW ACCESSTOKEN")
        store.dispatch('key/refresh')
      }
      else {
        // console.log("LOGIN NOW")
        store.dispatch('key/login')
      }
      document.title =`${to.name}`
      next();
    }
    
  }
  else {
    next()
  }
})

export default router
