<template>
    <div class="container text-start">{{getData}}
        <div class="col-12">
            <form @submit.prevent="submitForm(index)">
                <div class="field mb-2">
                    <label>Name</label>
                    <div class="control">
                        <input type="text" class="input form-control" v-model="view.name">
                    </div>
                </div>

                <div class="field mb-2" v-if="view.content_type === 'url'">
                    <label>URL</label>
                    <div class="control">
                        <input type="url" class="input form-control" v-model="view.url">
                    </div>
                </div>

                <div class="field" v-if="view.content_type === 'youtube'">
                    <label>Youtube</label>
                    <div class="control">
                        <input type="" class="input form-control" v-model="ytInput" placeholder="https://www.youtube.com/embed/VideoID">
                    </div>
                    <b>Youtube Link Format:</b> {{ytFormat}}
                    
                    <p class="mt-3 border-bottom">Current youtube video below</p>
                    
                    <iframe id="ytplayer" width="500" height="350" :src="ytplayerPreview" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <!-- <iframe width="560" height="315" :src="ytplayerPreview" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                </div>

                <div class="field" v-if="view.content_type === 'html'">
                    <label>HTML</label>
                    <div class="control">
                        <textarea type="url" class="form-control" v-model="view.html"></textarea>
                    </div>
                </div>

                <div class="field mb-3" v-if="view.content_type === 'image'">
                    <label>Image</label>
                    <div class="control">
                        <input type="file" class="form-control" @change="onFileChange">
                    </div>
                    <p>Current image: </p>
                    <img :src="imagePreview" alt="test image" style="width:25%; height:25%" class="rounded mx-auto d-block">
                </div>

                <div class="field mb-3" v-if="view.content_type === 'video'">
                    <label>Video</label>
                    <div class="control">
                        <input type="file" class="form-control" @change="onFileChange">
                    </div>
                    <p>Current video: </p>
                    <video :src="videoPreview" controls style="width:50%; height:50%" class="rounded mx-auto d-block"></video>
                </div>
                
                <div class="field">
                    <div class="control">
                        <button class="btn  mt-1" @click=" changeYtData()">Submit</button>
                    </div>
                </div>
        
            </form>
        </div>
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="flippingtoast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-success border-0">
                    <strong class="me-auto">Success!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The view has been updated!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

const {Toast} = bootstrap
export default {


    name: 'EditView',
    props: {
        objView: {
            type: Object
        },
        PKView: {
            type: Number 
        },
    },
    data() {
        return {
            view:[],
            playlist:[],
            oldContentType: '',
            mediaData: '',
            vidSeconds: null,
            oldVidSeconds: null,
            imagePreview: null,
            videoPreview: null,
            ytplayerPreview: null,
            oldytVideo: null, 
            ytFormat: "https://www.youtube.com/embed/VideoID",
            ytInput: ''
            
        }
    },
    computed: {
        getData(){
            //save the view data in local variable
            this.view = this.objView
            console.log( 'this is the view data', this.view)

            //save playlist data in local variable
            this.playlist = this.$store.state.ncapiStore.playlist
            
            //if the view has an image or video save them in a variable so they can be previewed easily
            if (this.view.image){
                this.imagePreview = this.view.image
            }
            if (this.view.video){
                this.videoPreview = this.view.video
            }
            
        },
    },
    mounted() {
        this.onLoad()
        this.getYtData()
        
    },
    methods: {

              getYtData(){
                  if (this.view.content_type === 'youtube'){
                    this.ytplayerPreview = this.view.youtube
                    this.ytInput = this.view.youtube
                    }
            },

        async onLoad(){

           
            //load the information of the view and playlist
            // await this.$store.dispatch("get", {type:"playlist", pk:this.$route.params.pk})
            // await this.$store.dispatch("get", {type:"view", pk:this.view.pk})

            //save original viewdata in local variables 
            if(this.view.content_type === 'video'){
                this.oldVidSeconds = this.objView.seconds
            }

            // if(this.view.content_type === 'youtube'){
            //     this.oldVidSeconds = this.objView.seconds
            // }
        },
        async submitForm() {
            //call toast 
            var toast = new Toast(this.$refs.flippingtoast)
            toast.show()

            //initialize formData
            const formData = new FormData();
            
            //patch information to view (sorted by content_type)
            if(this.view.content_type === 'image'){
                this.view.html = ''
                this.view.url = ''

                formData.append("name", this.view.name)
                formData.append("url", '')
                formData.append("html", '')
                //check if a new image is uploaded
                if(this.mediaData){
                    formData.append("image", this.mediaData, this.mediaData.name)
                }
                await this.$store.dispatch("patch", {type:"view", pk: this.PKView, data: formData})
            }
            else if(this.view.content_type === 'html'){
                this.mediaData = ''
                this.view.url = ''

                const view = {
                    name: this.view.name,
                    url: '',
                    html: this.view.html,
                }
                await this.$store.dispatch("patch", {type:"view", pk: this.PKView, data: view})
            }
            else if(this.view.content_type === 'url'){
                this.view.html = ''
                this.mediaData = ''

                const view = {
                    name: this.view.name,
                    url: this.view.url,
                    html: '',
                }
                await this.$store.dispatch("patch", {type:"view", pk: this.PKView, data: view})
            }
            else if(this.view.content_type === 'youtube'){
                this.view.html=''
                this.view.url=''
                
               const ytData = {
                   name: this.view.name,
                   youtube: this.ytplayerPreview,
               }
               console.log('this is yt data', ytData)
                await this.$store.dispatch("patch", {type: "view", pk: this.PKView, data: ytData})
            }
      
            else if(this.view.content_type === 'video'){
                this.view.html = ''
                this.view.url = ''

                formData.append("name", this.view.name)
                formData.append("url", '')
                formData.append("html", '')
                //check if a new video is uploaded
                if(this.mediaData){
                    formData.append("video", this.mediaData, this.mediaData.name)
                    formData.append("seconds", this.vidSeconds)
                }
                await this.$store.dispatch("patch", {type:"view", pk: this.PKView, data: formData})

                //if the view is still a video: check if a new video was uploaded. if it is, recalculate the seconds of playlist
                //and total_time of campagne
                if(this.view.content_type === 'video'){
                    if(this.mediaData){
                        const newPlaylistSeconds = {
                            seconds: this.playlist.video_seconds - this.oldVidSeconds + this.vidSeconds
                        }
                        
                        await this.$store.dispatch("patch", {type:"playlist", pk: this.$route.params.pk, data: newPlaylistSeconds})
                        if(this.playlist.status === 1){
                            await this.$store.dispatch("get", {type:"campagne", pk: this.playlist.campagne})
                            const newCampagneSeconds = {
                                total_time: this.$store.state.ncapiStore.campagne.total_time - this.oldVidSeconds + this.vidSeconds
                            }
                            await this.$store.dispatch("patch", {type:"campagne", pk: this.playlist.campagne, data: newCampagneSeconds}) 
                        }
                    }
                }
            }

            //get playlist to update the page with new data
            await this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})

            //save original viewdata in local variables and empty mediaData
            // this.oldContentType = this.objView.content_type
            if(this.view.content_type === 'video'){
                this.oldVidSeconds = this.objView.seconds
            }
        },
        onFileChange(e) {
            //on uploading a file, save it to mediaData
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;

            this.mediaData = files[0]

            //if the media is a video, calculate the seconds and store this in a local variable
            if(this.mediaData.type === "video/mp4"){
                var vid = document.createElement('video');
                var fileURL = URL.createObjectURL(files[0]);
                vid.src = fileURL;
                
                setTimeout(() => { 
                    this.vidSeconds= Math.round(vid.duration) 
                    // console.log("DONE WITH WAITING", this.vidSeconds)
                    }, 100
                );
            }
        }, 
        //for changing the link using sumbit perhaps
        changeYtData(){
            if(this.view.content_type === 'youtube'){
                console.log('this is a youtube link')
                this.ytplayerPreview = this.ytInput
            }
        }
    },
}
</script>
