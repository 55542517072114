import { createStore } from 'vuex'
import * as Keycloak from 'keycloak-js';
import ncapiStore from './modules/ncapiStore';
import apiBase from './apiBase';
import octoblueStore from './modules/octoblueStore';
import createPersistedState from "vuex-persistedstate";
import _NarrowCastingAPI from '@/store/api/_NarrowCastingAPI';
import KeycloakStore from './KeycloakStore'

export default createStore({
  state: {
    endpoints: {
      api: process.env.VUE_APP_NCAPI,
      login: process.env.VUE_APP_KC_URL 
    },
    
    administration: {
      adminID: "",
      name: "",
    },

    test: {
      working: false,
    },

    user: {
      isAuthenticated: false,
      name: "",
      idToken: "",
      accessToken: "",
      loginTime: null,
    },

    registration: {
      mail: '',
      firstName: '',
      lastName: '',
    },

    passdata: {
      passCampagne: "",
      passDevice: "",
      passSchedule: "",
      passingData: false,
    },
  },
  getters: {
    token (state) {
        return state.user.accessToken
    },
  },
  mutations: {
    testWorking(state){
      state.test.working =  true;
    },
    testWorkingOff(state){
      state.test.working = false;
    },
    setAPI(state, payload) {
      state.endpoints.api = payload.api;      
    },
    setAPIAndLogin(state, payload) {
      state.endpoints.api = payload.api;
      state.endpoints.login = payload.login;
    },
    login(state, payload) {
      state.user.isAuthenticated = true;
      state.user.idToken =payload.idToken;
      state.user.accessToken =payload.accessToken;
      state.user.loginTime = payload.loginTime;
    },

    setName(state, payload) {
        state.user.name = payload.name;
    },

    setRegistrationData(state, payload) {
      state.registration.mail = payload.mail;
      state.registration.firstName = payload.firstName;
      state.registration.lastName = payload.lastName;
    },

    kcLogout(state) {
      console.log("EMPTY THIS")
        console.log(state.user.name);
        state.user.isAuthenticated = false;
        state.user.name = "";
        state.user.idToken = "";
        state.user.accessToken = "";
        state.administration.adminID = "";
        state.administration.name = "";
    },
    // logout(state) {
    //   let initOptions = {
    //     url: process.env.VUE_APP_KC_URL, realm: process.env.VUE_APP_KC_REALM, clientId: process.env.VUE_APP_KC_CLIENT_ID, onLoad: 'login-required'
    //   }
    //   let keycloak = Keycloak(initOptions);
      
    //   keycloak.init()
    //   console.log(state.user.name);
    //   state.user.isAuthenticated = false;
    //   state.user.name = "";
    //   state.user.idToken = "";
    //   state.user.accessToken = "";
    //   state.administration.adminID = "";
    //   state.administration.name = "";
    //   keycloak.logout()
    // },
    
    setAdminID(state, dataSaver){
      state.administration.adminID = dataSaver.id;
      state.administration.name = dataSaver.name;
    },

    setData(state, data){
      state.passdata.passCampagne = data.campagne
      state.passdata.passDevice = data.device
      state.passdata.passSchedule = data.schedule
      state.passdata.passingData = true
    },
    stopData(state){
      state.passdata.passingData = false
      state.passdata.passCampagne = ""
      state.passdata.passDevice = ""
      state.passdata.passSchedule = ""
    },
    
  },
  actions: {
  },

  plugins: [createPersistedState()],

  modules: {
    ncapiStore,
    octoblueStore,

    key: KeycloakStore,
    
    //api: apiBase,

    ncapi: _NarrowCastingAPI
  }
})
