import {apiState, apiGetters} from '@/store/apiIntegrations'
import _mdlDevice from '@/store/api/ncapi/_mdlDevice'
import axios from 'axios'

const state = {
    api_base_url: process.env.VUE_APP_NCAPI,
    api_resource: '',
}

const getters = {
    read(state){
        return path => path.split(/[.[\]]+/).reduce((prev, key) => prev[key], state);
    },

    ax(state, getters, rootState, rootGetters){
        //const csrftoken = Cookies.get('csrftoken');
        const bearerToken = 'Bearer ' + rootGetters.token;
        const adminId = localStorage.administrationID
        return {
            headers: {
                Authorization: bearerToken,
                'administration': adminId,
                //'X-CSRFToken': csrftoken,
                'X-Requested-With': 'XMLHttpRequest'
            },
            //timeout: 1000,
        }
    }
}

// Parent Narrowcasting API
// Child/Resource classes should use these actions
// integration.js holds the integration, just do export default { actions: integrationActions }
export default {
    namespaced: true,
    state: state, 
    mutations: {
        // 
    },
    getters: getters,
    actions: {
        async post( store, { url, data } ) {
            return await store.getters.ax.post( url, data )
        },
        async get( store, {url} ) {
            return await axios.get( url, store.getters.ax )
            
        },
        async patch( store, { url, data } ){
            return await axios.patch( url, data )
        },
        async put( store, { url, data } ){
            return await axios.put( url, data )
        },
        async delete( store, url ){
            return await axios.delete( url )
        },
    },
    modules: {
        device: _mdlDevice
    }
}