<template>
    <div class="container" >
        <div class="col-6">
            <form @submit.prevent="submitForm" required>

                <div class="field">{{getData}}
                    <label>Campagne</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="selectedCampagne" class="form-select" required>
                                <option disabled value="">Please select one</option>
                                <option 
                                v-for="campagne in campagnes" 
                                v-bind:key="campagne.select"
                                >
                                    {{campagne.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <button class="btn mt-3">Add</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="addCampagneToast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-success border-0">
                    <strong class="me-auto">Success!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The campagne was added to the schedule! </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const {Toast}=bootstrap

    export default {
        name: 'SelectCampagne',
        data() {
            return {
                campagnes: [],
                selectedCampagne: [],
                selectedCampagnePK: null,
                times: 0,
            }
        },
        computed: {
            getData(){
                //store campagnes and schedule data in local variables
                this.campagnes = this.$store.state.ncapiStore.campagnes
                this.schedule = this.$store.state.ncapiStore.schedule
            }
        },
        methods: {
            async submitForm() {

                this.campagne = this.$store.state.ncapiStore.campagne
                var toast = new Toast(this.$refs.addCampagneToast)
                toast.show()

                //get the PK of the selected campagne
                for(var index = 0; index< this.campagnes.length; index++){
                    if (this.campagnes[index].name === this.selectedCampagne){
                        this.selectedCampagnePK = this.campagnes[index].pk
                    }
                }

                //post data of dailyroutineCampagne many2many relationship to API
                //get campagnes and schedule to update page
                const dailyroutineCampagne = {
                    campagne: this.selectedCampagnePK,
                    dailyroutine: parseInt(this.$route.params.pk),
                    administration_id: localStorage.administrationID,
                    position: this.schedule.campagnes.length + 1,
                    times: this.times

                }
                await this.$store.dispatch('post', {type:"scheduleCampagne", data: dailyroutineCampagne})
                await this.$store.dispatch('getAll', {type:"campagne"})
                await this.$store.dispatch("get", {type:"schedule", pk: this.$route.params.pk})

                //empty values
                this.selectedCampagne= []
                this.times= 0
            }
        },
    }
</script>