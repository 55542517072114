<template>
  <div>{{getData}}
 <NavBar>
    <template v-slot:navHeader>Welcome to the FAQ Portal!</template>
    <template v-slot:navForm>
    <div >
        <ul class="m-0 nav-item">
            <li class=" " style="list-style-type: none;">
                <div class="nav-item dropdown ">
                    <a class="nav-link dropdown-toggle fs-5 text-white" href="#" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="bi bi-person-fill me-3" aria-label="Account options">&nbsp;{{adminName}}</i>
                    </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a href="#" class="dropdown-item dropColor fs-5"><i class="bi bi-person-circle me-2 mx-3" aria-label="Account"></i>Account</a></li>
                            <li><a href="/registration" class="dropdown-item dropColor fs-5"><i class="bi bi-files  me-2 mx-3" aria-label="Administration"></i>Administration</a></li>
                            <li><a href="/faq" class="dropdown-item dropColor fs-5"><i class="bi bi-question-circle me-2 mx-3" aria-label="FAQ"></i>FAQ</a></li>
                            <li><hr class="dropdown-divider dropColor fs-5"></li>
                            <li><a href="#backDrop" class="dropdown-item dropColor fs-5" data-bs-toggle="modal"><i class="bi bi-box-arrow-right  me-2 mx-3" aria-label="Log out"></i>Log out</a></li>
                        </ul>
                  </div>
              </li>
          </ul>
      </div>
    </template>
  </NavBar>
  
    <div class="container">
        <FaqCard cls="-md-8 mt-4" icon="" link="#">
                    <template v-slot:body>
                      <div class="mt-3" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"></div>
                      <img src="../assets/NCtree4.png" alt="NC_Boom" id="ncboom">
                    </template>
                    <template v-slot:title>De narrowcasting portal boom</template>
        </FaqCard>
            
            <FaqCard cls="-md-12 mt-4" icon="" link="#">
                    <template v-slot:body>
                    </template>
                    <template v-slot:title>De portal in 10 stappen</template>
                    <template v-slot:text>
                      <div class="">
                        <p>1. Na het inloggen kunt u een administratie kiezen waarvoor u de narrowcasting wilt opzetten. </p>
                        <p>2. Hierna komt u in het dashboard. Hier kunt u al uw devices zien (als u nog geen devices heeft toegevoegd is dit uiteraard leeg).</p>
                        <p>3. In het navigatie menu aan de linker kant kunt u klikken op “Devices”. Hier kunt u een device toevoegen met een naam, locatie en 
                              scherm rotatie. 
                        </p>
                        <p>4. Nadat u het device heeft toegevoegd kunt u op de nieuwe device 
                              (details) klikken om meer informatie in te kunnen zien. Hier kunt 
                              u de link zien naar de Show page, waar straks uw campagnes te 
                              zien zijn. Verder kun u hier de schedules zien. Omdat u nog geen 
                              campagnes heeft gemaakt zijn deze nog leeg.  
                        </p>
                        <p>5. In het navigatie menu aan de linker kant kunt u klikken op “Campagnes”. Hier kunt u een campagne toevoegen met een naam. </p>
                        <p>6. Nadat u de campagne heeft toegevoegd kunt u op de nieuwe 
                              campagne (details) klikken om meer informatie in te kunnen zien. 
                              Hier kunt u bijvoorbeeld zien hoe lang de campagne in totaal duurt 
                              in seconden. Omdat u nog geen playlists heeft is de totale tijd 
                              nog nul. U kunt hier ook de playlists in deze campagne zien en ze 
                              toevoegen met een naam, layout en aantal seconden.
                          </p>
                        <p>7. Nadat u de playlist heeft toegevoegd wordt u doorgestuurd naar 
                              de playlist pagina. Hier kunt u views (media) toevoegen aan de 
                              playlist in een bepaalde positie op het scherm. Als uw playlist 
                              views en seconden heeft, kunt u de playlist activeren. Een playlist 
                              die niet is geactiveerd, wordt niet getoond in de campagne.  
                        </p>
                        <p>8. Nu u een campagne heeft gemaakt kunt u terug gaan naar “Devices” en uw aangemaakte device aanklikken. Dan kunt u een schedule kiezen, bijvoorbeeld die van vandaag en hier een campagne 
                              aan toevoegen.  
                        </p>
                        <p>9. Als u bij de device pagina op de show page link klikt, kunt u nu de campagne zien die u zojuist heeft gemaakt. </p>
                        <p>10. U heeft nu succesvol een campagne laten zien! Op deze manier 
                              kunt u meer devices en campagnes toevoegen en maken. Ook kunt 
                              u meer playlists en views toevoegen aan uw huidige campagne. De 
                              portal biedt veel creatieve mogelijkheden, probeer ze vooral uit! 
                        </p>
                      </div>
                    </template>
            </FaqCard>
    </div>
      <div class="modal fade" id="backDrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Log Out</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                            <div class="modal-body">
                            <strong>You are about to leave, Make sure you save your changes!</strong>
                            </div>
                        <div class="modal-footer">
                            <button type="button" class="btn" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn" @click="KClogout()" data-bs-dismiss="modal">Log Out</button>
                        </div>
                    </div>
                </div>
            </div>
  </div>
</template>

<script>


export default {
name: 'faqportal',

data(){
return{
  adminName:'',
}
},

computed:{

},

mounted(){
this.getInfoAPI()
},

methods:{

    KClogout(){
                this.$store.dispatch('key/logout')
                console.log("in out of here")
                this.$router.push('/')
            },
 async getInfoAPI(){
                this.adminName = localStorage.adminName
                await this.$store.dispatch("getAllLocations")
                await this.$store.dispatch("getAll", {type:"device"})
                await this.$store.dispatch("getAll", {type:"schedule"})
            },
},
}


</script>

<style>
.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}
.active, .sbItem:hover {
  background-color: #f7960b;
}
#ncboom{
  height: 1000px;
  width: auto;
}
</style>