<template>


  <div class="">
    
    
     <SideBar>
       <template v-slot:sbHeader>
          
       </template>
       <template v-slot:sbItems>
          <div :class="{active: isActive}">
              <a class="text-light" href="#"><i class="bi bi-clipboard"></i> &nbsp;Dashboard</a>
              <a class="text-light" href="#"><i class="bi bi-display"></i>&nbsp; Devices</a>
              <a class="text-light" href="#"><i class="bi bi-card-list"></i>&nbsp; Campaigns</a>
              <a class="text-light" href="#"><i class="bi bi-collection-play"></i>&nbsp; Preview</a>
          </div>
       </template>
     </SideBar>
     
     
     
     <div class="row mt-5">

       <card cls="-md-4" icon="tablet-landscape">
         <template #header>Campaigns</template>
          <template #body>
            Typed this text overhere
          </template>
      </card>
       <card cls="-md-4" icon="tablet-landscape">
         <template #header>First table</template>
          <template #body>
            <table class="table table-striped table-responsive" >
              <thead class="thead bg-abblue"><tr><th>Test this table</th></tr></thead>
              <tbody>
                <tr><td>value1</td></tr>
                <tr><td>value1</td></tr>
                <tr><td>value1</td></tr>
                <tr><td>value1</td></tr>
                <tr><td>value1</td></tr>
                </tbody>
            </table>
          </template>
      </card>
       <card cls="-md-4" icon="tablet-landscape">
         <template #header>Second table</template>
          <template #body>
            Typed this text overhere
          </template>
      </card>

        <NavBar>
        <template v-slot:navHeader></template>
        <template v-slot:navToggler></template>
        <template v-slot:navLink>
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        <a class="nav-link" href="#">Features</a>
        <a class="nav-link" href="#">Pricing</a>
        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </template>
      </NavBar>

        </div>
  
     

  

    
    
    
    
    <h1 class="mt-5 my-5">This is an about page</h1>


<SelectForm cls="" >
<template v-slot:selected>Make a selection</template>
<template v-slot:option>
  <option value="1">one</option>
  <option value="2">two</option>
  <option value="3">three</option>
</template>
</SelectForm>
  
     

     



    <div class='drop-zone' @drop="onDrop($event, 1)" @dragover.prevent @dragenter.prevent>
      <div v-for='item in listOne' :key='item.title' class='drag-el' draggable="true" @dragstart="startDrag($event, item)">
        {{ item.title }}
      </div>
    </div>
    <div class='drop-zone' @drop="onDrop($event, 2)" @dragover.prevent @dragenter.prevent>
      <div v-for='item in listTwo' :key='item.title' class='drag-el' draggable="true" @dragstart="startDrag($event, item)">
        {{ item.title }}
      </div>
    </div>


    <button type="button" class="btn btn-primary m-4" @click="() => TogglePopup('buttonTrigger')">test</button>

    <!-- <Popup v-if="popupTriggers.buttonTrigger" :TogglePopup="() => TogglePopup('buttonTrigger')">
      <h2>My Button popup</h2>
    </Popup> -->
    <button type="button" class="btn btn-primary m-4" @click="changeImg()">Img change</button>
    
    <div class="container mb-5">
      <!-- <form action="/about.vue" method="post" enctype="multipart/form-data"> -->
        <div v-if="!image">
          <h2>Select an image</h2>
          <input type="file" @change="onFileChange">
        </div>
        <div v-else>
          <img :src="image" />
          <button @click="removeImage">Remove image</button>
        </div>
        <div>
          <button>Submit</button>
        </div>
      <!-- </form> -->
    </div>
    <div class="container">
      <p>{{testImage}}</p>
      <img :src="testImage" alt="test image">
      <img :src="testImage2" alt="test image">
    </div>

    <table class="col-1">
      <thead>
        <tr>
          <th>Devices Name</th>
          <th>PK</th>
          <th></th>
        </tr>
      </thead>

      <tbody>{{getData}}
        <tr
          v-for="device in devices"
          v-bind:key="device.pk"
        >
          <td>{{device.name}}</td>
          <td>{{device.pk}}</td>
                
        </tr>
      </tbody>
    </table>

    <div class="container">
      <div class="image">
        <img src="../assets/main-icon-color.png" class="spinner-border" style="width: 50%; height: 50%;">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
import Popup from '../components/Popup.vue'
// import NavbarComponents from '../components/NavbarComponents.vue';
import NavBar from '../components/NavBar.vue';
export default {

  setup () {
    const popupTriggers = ref({
      buttonTrigger: false,
    });

    const TogglePopup = (trigger) => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger]
    }

    return {
      popupTriggers,
      TogglePopup
    }
  },
  name:'testAbout',
  components: {
    Popup,
    // NavbarComponents,
    NavBar,
},

  data(){
    return{
      image: '',
      isActive: true,
      testImage2: null,
      imageSwitch: false,
      testImage: null,
      devices:[],
      items: [
        {
          id: 0,
          title: 'Item A',
          list: 1
        },
        {
          id: 1,
          title: 'Item B',
          list: 1
        },
        {
          id: 2,
          title: 'Item C',
          list: 2
        }]
    }
  },
  mounted() {
    this.onPageLoad()
  },
  computed: {
    getData(){
      this.devices = this.$store.state.ncapiStore.devices
      console.log(this.devices)
      console.log(this.$store.state.ncapiStore.campagnes)
    },
    listOne () {
      return this.items.filter(item => item.list === 1)
    },
    listTwo () {
      return this.items.filter(item => item.list === 2)
    }
  },
  methods: {
    async onPageLoad(){
      await this.$store.dispatch("getAll", {type:"device"})
      .then(() => {
          console.log('success')
      })
      .catch((error) => {
          console.log('AAAH: ', error)
      })
      this.$store.dispatch("getAll", {type: "campagne"})
      //console.log(this.$store.state.ncapiStore.devices)
      
    },
    startDrag (evt, item) {
      // evt.dataTransfer.dropEffect = 'move'
      // evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', item.id)
    },
    onDrop (evt, list) {
      const itemID = evt.dataTransfer.getData('itemID')
      const item = this.items.find(item => item.id == itemID)
      item.list = list
    },
    async testStore(){
      await axios
      .get('/api/v1/campagnes/playlist/', {headers: {Authorization: 'Bearer ' + this.$store.state.user.accessToken, Administration: localStorage.administrationID}})
      .then(response => {
        console.log("Data playlist: ", response.data)

          
      })
      .catch(error => {
        console.log(error)
        
      })
    }, 
    testStore2() {
      const databox= {
        name:"pancake",
      }
      this.$store.dispatch("patch", {type:"device", pk: 1, data: databox})
    },

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();

      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);

      if(!this.imageSwitch){
        this.testImage = URL.createObjectURL(file)
      }
      else{
        this.testImage2 = URL.createObjectURL(file)
      }

      console.log("SEEING WHAT ALL THIS IS: ", this.testImage)
      
    },
    removeImage: function (e) {
      this.image = '';
    },
    changeImg(){
      if(!this.imageSwitch){
        this.imageSwitch = true
      }
      else{
        this.imageSwitch = false
      }
    }
  },

  
}
</script>

<style scoped>
  .drop-zone {
    background-color: #eee;
    width: 50%;
    margin: 50px auto;
    margin-bottom: 10px;
    padding: 10px;
  }

  .drag-el {
    background-color: rgb(150, 143, 219);
    margin-bottom: 10px;
    padding: 5px;
    min-height: 10px;
  }

  .image {
    position: absolute;
    display: block;
    padding: 10px;
      width: 500px;
  height: 500px;
  }

.image img {
  position: absolute;
  top: 25%;
  left: 26%;
}

.spin {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  animation: spin 1.5s infinite linear;
}

.spin::before,
.spin::after {
  left: -2px;
  top: -2px;
  display: none;
  position: absolute;
  content: '';
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}
  #card-header{
    color: #017ab5;
  }
  #bg-color{
    color: #337ab7;;
  }
  #navbg-color{
    color: #f7960b;
  }
  
 #backdrop-color{
   color: #343434;
 }

 #sidebar-color{
   color: #017ab5;
 }
.navbar-brand{
    background-color: rgba(63, 63, 63, 1) ;
}



.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}
 
.sidebar a.active {
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #f7960b;
  color: white;
}

</style>