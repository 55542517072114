<template >
{{getData}}
        <div class="mb-4">
            <h2 class="text-bg-abblue mt-4" >Dashboard</h2>
            <p>Welcome {{adminName}}!</p>
        </div>
        
            <Card cls="-md-12" icon="window-fullscreen" >
                <template v-slot:header>
                    Devices
                </template>
                <template v-slot:body>
                <div class="row row-cols-md-4 row-cols-xs-1 g-4" id="deviceBox">
                    <!-- <div v-for="(device, index) in devices" -->

                    <!-- Single Device Card-->
                    <div 
                        v-for="(device, index) in getFullDevices()"
                        :key="device.pk"
                        class="col"
                    >
                        <div class="card">
                            <div class="card-body">
                        <h5 class="txt-abblue">{{device.name}}</h5>
                        <div class="mx-2" v-if="device.screen_rotation ==='landscape'" >
                            <img class="card-img-top my-2" src='../assets/Landscape.png' alt="defaultImg" style="width:10rem">
                            <hr>
                        </div>
                        <div class="mx-5" v-else-if="device.screen_rotation ==='portrait_left'">
                            <img class="card-img-top my-2" src='../assets/Left_rotation.png' alt="defaultImg" style="width:10rem">
                            <hr>
                        </div>
                            <div class="mx-2" v-else-if="device.screen_rotation ==='portrait_right'">
                            <img class="card-img-top my-2" src='../assets/Right_rotation.png' alt="defaultImg" style="width:10rem">
                            <hr>
                        </div>
                                <p class="text-muted"><b>Location: </b><span class="text-capitalize">{{ locationNames[index] }}</span></p>
                                <p class="text-muted"><b>Test Schema: </b><span class="text-capitalize">{{ device.schedule }}</span></p>
                                <p class="text-muted"><b>Campagnes: </b><span class="text-capitalize">{{ device.campagnes }}</span></p>
                                <div class="hstack">
                                            <button class="btn" @click="goDetails(index)">Details</button>
                                            <button @click="goShow(selectedDevice.pk)" class="btn ms-2" type="button" ><i class="bi bi-card-image me-2"></i>Preview</button>
                            </div>
                            
                            </div>
                        </div>
                    </div> <!-- End Device Col-->

                </div>
                </template>
                <template v-slot:title>
                        
                </template>
            </Card>
                <!-- <div class="row justify-content-center border-actaDarkblue rounded" id="scrollable">
                      <h5 class=" text-white card-header " id="deviceOverview"><i class="bi bi-display"></i> Devices</h5> 
                    <div class="d-flex" id="deviceBox">
                        <div class="card-header  mx-2 my-2 col-md-3 col-lg-8 col-xl-12" style="width: 25rem;"  
                                v-for="(device, index) in devices"
                                :key="device.pk"
                                @click="selectDevice(index);">
                                <div v-if="device.screen_rotation ==='landscape'" >
                                    <img class="card-img-top" src='../assets/Landscape.png' alt="defaultImg">
                                </div>
                                <div v-else-if="device.screen_rotation ==='portrait_left'">
                                     <img class="card-img-top" src='../assets/Left_rotation.png' alt="defaultImg">
                                </div>
                                <div v-else-if="device.screen_rotation ==='portrait_right'">
                                     <img class="card-img-top" src='../assets/Right_rotation.png' alt="defaultImg">
                                </div>
                            <div class="card-body" style="width: 20rem;" >
                                <h4 class="card-title">Device name: <h5>{{device.name}}</h5></h4>
                                
                                <h6 class="card-title">Location:  {{locationNames[index]}}</h6>
                                    <p class="card-text">Preview of your device below</p>
                                       
                            </div>
                        </div>                         
                    </div>
                </div> -->
       
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="emptyDashboard" class="toast hide text-black bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-black bg-danger border-0">
                    <strong class="me-auto"><i class="bi bi-info-circle-fill"></i> There are no devices in the dashboard</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body">
                        <p>To get started head to the <router-link style="text-decoration: none; color: black;" to="/devices-overview">'Devices'</router-link> tab to add devices</p>
                    </div>
            </div>
        </div> 
</template>

<script> 

const {Toast} = bootstrap
    export default {
        name: 'dashboard',
        data(){
            return{
                devices:[],
                schedules: [],
                locations: [],
                locationNames:[],
                selectedDevice: {},
                adminName: '',
                dashboardCount:[],
            }
        },
        computed: {
            getData(){
                //save devices, schedules, and locations data in local variables
                this.devices = this.$store.state.ncapiStore.devices
                console.log(this.devices)
                this.schedules = this.$store.state.ncapiStore.schedules                
                this.locations = this.$store.state.octoblueStore.locations

                //get the location names belonging to the devices
                for (var index = 0; index < this.locations.length; index ++){
                    for(var i=0; i<this.devices.length; i++){
                        if (this.devices[i].location_id === this.locations[index].id){
                            this.locationNames.push(this.locations[index].name)
                        }
                    }
                }
            }  
        },
        mounted (){
            this.getInfoAPI()
            this.dashBoardInfo()
            // this.checkRotation()
        },
        methods: {

                getFullDevices(){
                    var devs = []

                    for( var i=0; i < this.devices.length; i++){
                        var dev = this.devices[i]
                        console.log("Device: ", i, {...dev})

                        var deviceSchedules = []
                        for (var itwo=0; itwo<this.schedules.length; itwo++){
                            if(this.schedules[itwo].device === dev.pk){
                                deviceSchedules.push(this.schedules[itwo])
                            }
                        }
                        // dev.schedules = deviceSchedules

                //         // getting today's day and getting the data from the right schedule
                        const weekday = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];

                        const d = new Date();
                        let day = weekday[d.getDay()];

                        for(var ithree = 0; ithree<deviceSchedules.length; ithree++){
                            if(deviceSchedules[ithree].day === day){
                                dev.schedule = deviceSchedules[ithree].day
                                dev.campagnes = deviceSchedules[ithree].campagnes.length
                            }
                        }
                        // console.log("Device and index", i,dev)
                        devs.push(dev)
                    }
                    return devs
                },

                checkRotation(){
               for(var i=0; i<this.devices.length; i++){
                //    if(this.devices[i].screen_rotation){
                //        console.log('here', this.devices[i].screen_rotation)
                //    }
                if(this.devices[i].screen_rotation ==='landscape'){
                    console.log('1')
                    // this.defaultImg = this.landScape
                }
                else if(this.devices[i].screen_rotation === 'portrait_left'){
                          console.log('2')
                        //   this.defaultImg = this.Links
                }
                else if(this.devices[i].screen_rotation == "portrait_right"){
                    console.log("3")
                    // this.defaultImg = this.Rechts
                }
               }
                },
             
            async dashBoardInfo(){
                if(this.devices.length === 0){
                    var toast = new Toast(this.$refs.emptyDashboard)
                    toast.show()
                }
            },
             

            async highLight(){
                  this.devices.name = this.$store.state.ncapiStore.devices
                //   console.log(this.devices + 'so this is devices length')
                //   console.log(this.locationNames + 'so this is location names')

                this.devices.forEach( device => {
                            if(this.devices.name){
                        device = document.getElementById('styles').style.opacity = 0.8;
                        device = document.getElementById('styles').style.backgroundColor = '#6dcffc';
                  }
                })
                
            },
               

            async getInfoAPI(){
                this.adminName = localStorage.adminName
                await this.$store.dispatch("getAllLocations")
                await this.$store.dispatch("getAll", {type:"device"})
                await this.$store.dispatch("getAll", {type:"schedule"})
            },
            async selectDevice(index){
                // getting the data from the selected device
                const selectedDevice = this.devices[index]
                this.selectedDevice.name = selectedDevice.name
                this.selectedDevice.pk = selectedDevice.pk

                //store the schedule data of schedules in a selected device in deviceSchedules local variable
                var deviceSchedules = []
                for (var i=0; i<this.schedules.length; i++){
                    if(this.schedules[i].device === selectedDevice.pk){
                        deviceSchedules.push(this.schedules[i])
                    }
                }

                // getting today's day and getting the data from the right schedule
                const weekday = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];

                const d = new Date();
                let day = weekday[d.getDay()];

                for(var i = 0; i<deviceSchedules.length; i++){
                    if(deviceSchedules[i].day === day){
                        this.selectedDevice.schedule = deviceSchedules[i].day
                        this.selectedDevice.campagnes = deviceSchedules[i].campagnes.length
                    }
                }
            },
            async previewSchedule(){
                //store device name and schedule day data in store
                //and go to preview page
                await this.$store.commit("setData", {device: this.selectedDevice.name , schedule: this.selectedDevice.schedule})
                this.$router.push("/select-show")
            },
            goShow(pk){
                let route = this.$router.resolve({name: "ShowPage", params: {pk: pk}})
                window.open(route.href, "show")
            },
             goDetails(index){
                //go to schedule details page of selected schedule
                this.$router.push(`/devices-overview/${this.devices[index].pk}`)
            },
        }     
    }
</script>
<style scoped>
td{
    cursor: pointer;
}
#styles{
    opacity: 7;
    background-color: #fff;
}
#deviceOverview{
    background-color: #067db6;
}
#scrollable{
    /* overflow: visible; */
}
#deviceBox{
    /* border:1px solid #067db6; */
    overflow: auto;
    scroll-behavior: smooth;
    /* border-radius: 5px; */
}
.btn{
  background-color:#017ab5;
  color: white;
  margin: auto;
  text-align: center;
}
.btn:hover{
  background-color:#f7960b
}
</style>