<template>
    <div class="container"> {{getData}}
        <div v-if="playlist.campagne" class="my-4">
            <router-link :to="{ name: 'Campagne', params: { pk: playlist.campagne}}" class="btn"><i class="bi bi-arrow-left me-2"></i>Back</router-link>
        </div>

        <div v-if="noViews.length" class="alert alert-warning mb-3">
            <div class="alert-heading">
                <b>Views are missing!</b>
            </div>
            <hr>
            <div class="">There are currently no views in: 
                <div v-for="position in noViews" v-bind:key="position.name">
                    - #{{position}}
                </div>
                The playlist will be put on inactive if no views are added.
            </div>
        </div>
        <div v-if="playlist.seconds === 0 & playlist.video_seconds === 0" class="alert alert-warning mb-3" role="alert">
            <div class="alert-heading">
                <b class="">Seconds are missing!</b>
            </div>
            <hr>
            <div class="">There are currently 0 seconds in {{playlist.name}}. 
                The playlist will be put on inactive if no seconds are added.
            </div>
        </div>

        <Card cls="-xl-12">
            <template v-slot:header>
                {{playlist.name}}
            </template>
            <template v-slot:body>
                <div class="col-6 position-relative mb-5">
                        <p>Name: {{playlist.name}}</p>
                        <p>Total seconds: {{playlistTotalSeconds}}</p>
                        <p v-if="playlist.layout ==='fullscreen'">Video seconds: {{playlist.video_seconds}}</p>
                        <p v-if="playlist.layout === 'fullscreen'">Non video view seconds: {{playlist.seconds}} </p>
                        <p v-if="playlist.views">Amount of views: {{playlist.views.length}}</p>
                        <p>Layout: <span>{{playlist.layout}}</span></p>
                        <span v-if="playlist.layout ==='fullscreen'"><div class="position-absolute start-100 top-0 ms-5 mt-5 me-5" id="square"></div></span>
                        <span v-if="playlist.layout ==='verticalsplit'"><div class="position-absolute start-100 top-0 ms-5 mt-5 me-5" id="squareB"></div></span>
                        <span v-if="playlist.layout ==='horizontalsplit'"><div class="position-absolute start-100 top-0 ms-5 mt-5 me-5" id="squareC"></div></span>
                        <span v-if="playlist.layout ==='crosssplit'"><div class="position-absolute start-100 top-0 ms-5 mt-5 me-5" id="squareD"></div></span>
                        <span v-if="playlist.layout ==='tsplitright'"><img src='../assets/TSplit-right.png' alt="TSplit-right" id="squareE" class="position-absolute start-100 top-0 ms-5 mt-5 me-5"></span>
                        <span v-if="playlist.layout ==='tsplitleft'"><img src='../assets/TSplit-left.png' alt="TSplit-left" id="squareE" class="position-absolute start-100 top-0 ms-5 mt-5 me-5"></span>
                        <span v-if="playlist.layout ==='tsplittop'"><img src='../assets/TSplit-boven.png' alt="TSplit-boven" id="squareE" class="position-absolute start-100 top-0 ms-5 mt-5 me-5"></span>
                        <span v-if="playlist.layout ==='tsplitbottom'"><img src='../assets/TSplit-beneden.png' alt="TSplit-beneden" id="squareE" class="position-absolute start-100 top-0 ms-5 mt-5 me-5"></span>
                        
                        <button type="button" class="btn ms-2 mb-2" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-pencil-square me-2"></i>Edit Playlist</button>                                               
                        <button class="btn ms-2 mb-2" type="button" data-bs-toggle="modal" data-bs-target="#PlaylistModal"><i class="bi bi-trash-fill me-2"></i>Delete Playlist</button>                            
                        <button v-if="playlist.status === 1" type="button" class="btn ms-2 mb-2" data-bs-toggle="modal" data-bs-target="#StatusModal">Active</button>
                        <button v-if="playlist.status === 0" type="button" class="btn ms-2 mb-2" data-bs-toggle="modal" data-bs-target="#StatusModal">Inactive</button>
                        <div class="collapse" id="collapseExample">
                            <div class="card card-body">
                                <EditPlaylist v-bind:objPlaylist="playlist" v-bind:PKPlaylist="playlist.pk" v-bind:objSortedViews="views" v-bind:userSeconds="userSeconds" />
                            </div>
                        </div>
                <div class="modal fade" id="StatusModal" tabindex="-1" aria-labelledby="statusModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="statusModalLabel">Are you sure?</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p v-if="playlist.status === 1">Are you sure you want to change the status of {{playlist.name}} to inactive?</p>
                                <p v-if="playlist.status === 0">Are you sure you want to change the status of {{playlist.name}} to active?</p>
                    
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-primary" @click="activityButton()" data-bs-dismiss="modal">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                   <div class="modal fade" id="PlaylistModal" tabindex="-1" aria-labelledby="playlistModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="playlistModalLabel">Are you sure?</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>Are you sure you want to delete {{playlist.name}}?</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-danger" @click="deletePlaylist()" data-bs-dismiss="modal"><i class="bi bi-trash-fill me-2"></i>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>  
                    </div>
            </template>
        </Card>

         <div class="container mb-4" v-if="playlist.views">
            <h4 class=" mb-2">Views</h4>
            <button class="btn" type="button" data-bs-toggle="collapse" data-bs-target="#AddViewCollapse" aria-expanded="false" aria-controls="AddViewCollapse"><i class="bi bi-plus-circle me-2"></i>Add view</button>
            <div class="collapse" id="AddViewCollapse">
                <div class="card card-body ">
                    <AddView v-bind:objSortedViews="views" v-bind:PlaylistSeconds="playlist.seconds"/>
                </div>
            </div>

        <Card cls="-xl-12 my-4" >
            <template  v-slot:header><span v-for="viewPosition in usedViews" v-bind:key="viewPosition.pk">Views - {{viewPosition.name}}</span></template>
            <template v-slot:body>
                    <div class="g-2 mt-3" :v-bind="addEventListeners()">  
                <div class="col-12" v-for="viewPosition in usedViews" v-bind:key="viewPosition.pk">                    
                    <div class="">                        
                        <div class="text-white">{{viewPosition.name}}</div>
                        <div class="">   
                            <div class="draggable-list" id="draggable-list">
                                <section 
                                    v-for="(view, index) in viewPosition.views"
                                    v-bind:key="view.pk"
                                    class='draggable card border-actaorangeBg mb-2' draggable="true"
                                    @dragstart="dragStart(index, view.pk)"
                                    :data-index="index"
                                >
                                    <div class="card-header-actaorangeBg">
                                        <i class="bi bi-list ms-1 me-1"></i>  
                                        #{{index +1}} {{view.name}}</div>
                                    <div class="card-body table-responsive">
                                        <table class="table table-borderless mb-0"> 
                                            <thead>
                                                <tr>
                                                    <th>Name:</th>
                                                    <th>Content type:</th>
                                                    <th>Seconds:</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>   
                                                <td>{{view.name}}</td>
                                                <td>{{view.content_type}}</td>
                                                <td>{{view.seconds}}</td>
                                                <td class="p-0 text-end">
                                                    <div>
                                                        <button class="btn mb-1" type="button" data-bs-toggle="modal" :data-bs-target="'#BottomLeftModal' + view.pk"><i class="bi bi-trash-fill me-2"></i>Delete View</button>
                                                        <div class="modal fade" :id="'BottomLeftModal' + view.pk" tabindex="-1" aria-labelledby="bottomLeftModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        <h5 class="modal-title" id="bottomLeftModalLabel">Are you sure?</h5>
                                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <p>Are you sure you want to delete {{view.name}}?</p>
                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button type="button" class="btn" data-bs-dismiss="modal">Cancel</button>
                                                                        <button type="button" class="btn" @click="deleteView(view.pk)" data-bs-dismiss="modal"><i class="bi bi-trash-fill me-2"></i>Delete</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>  
                                                        <button type="button" class="btn mx-4 mb-1" data-bs-toggle="collapse" :data-bs-target="'#ViewCollapse'+view.pk" aria-expanded="false" :aria-controls="'ViewCollapse'+view.pk">Edit View</button>
                                                        <div class="collapse" :id="'ViewCollapse'+view.pk">
                                                            <div class="card card-body">
                                                                <EditView v-bind:objView="view" v-bind:PKView="view.pk"/>
                                                            </div>
                                                        </div>                                       
                                                    </div>
                                                </td>  
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </template>
        </Card>
        
            <div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11">
                <div ref="deletePlaylistToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header text-white bg-danger border-0">
                        <strong class="me-auto">Playlist deleted!</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        <p>The playlist has been deleted!</p>
                        <p>You'll now return to the campagne page</p>
                    </div>
                </div>                
                
                <div ref="deleteViewToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header text-white bg-danger border-0">
                        <strong class="me-auto">View deleted!</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        <p>The view has been deleted!</p>
                    </div>
                </div>

                <div ref="noViewsToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header text-white bg-danger border-0">
                        <strong class="me-auto">Missing views!</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        <p>There are views missing, please add views.</p>
                        <p>Because there are views missing, the playlist has been put on inactive!</p>
                    </div>
                </div>

                <div ref="noSecondsToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header text-white bg-danger border-0">
                        <strong class="me-auto">0 seconds!</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        <p>There are 0 seconds, please add seconds.</p>
                        <p>Because there are 0 seconds, the playlist has been put on inactive!</p>
                    </div>
                </div>

                <div ref="activatedToast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header text-white bg-success border-0">
                        <strong class="me-auto">Activated!</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        <p>The playlist was activated!</p>
                    </div>
                </div>

                <div ref="inactivatedToast" class="toast hide text-black bg-warning border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header text-black bg-warning border-0">
                        <strong class="me-auto">Inactivated!</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        <p>The playlist was inactivated!</p>
                    </div>
                </div>
                <div ref="inactivePlaylist" class="toast hide text-black bg-warning border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header text-black bg-warning border-0">
                        <strong class="me-auto"><i class="bi bi-info-circle-fill"></i> Warning!</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        <p>The playlist is currently inactive, click the "inactive" button to activate it</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script> 
import EditPlaylist from './EditPlaylist.vue'
import AddView from './AddView.vue'
import EditView from './EditView.vue'
import Card from '../components/Card.vue'

const {Toast} = bootstrap

    export default {
        name: 'Playlist',
        components: {
    EditPlaylist,
    AddView,
    EditView,
    Card
},
        data() {
            return{
                disabled: 0,
                playlist: [],
                campagne: [],
                playlistTotalSeconds: 0,
                objPlaylist: [],
                PKPlaylist: null,
                PKView: null,
                objSortedViews: [], 
                // PlaylistSeconds: null,
                userSeconds: null,
                dragStartIndex: null, 
                dragEndIndex: null,
                currentViewPK: null,
                noViews: [],
                usedViews: [],
                views: {
                    fullscreen: [],
                    left: [],
                    right: [],
                    top: [],
                    bottom: [],
                    topRight: [],
                    topLeft: [],
                    bottomRight: [],
                    bottomLeft: [],
                },
                Message:  '  In this section is where you add your views for the playlist, To get started click the Add View button.',
            }
        },
        computed: {
            getData(){
                //store playlist and campagne in local variables
                this.playlist = this.$store.state.ncapiStore.playlist
                this.campagne = this.$store.state.ncapiStore.campagne

                //when playlist is loaded in:
                if (this.playlist.views){  

                    //sort views by position order
                    this.playlist.views.sort((a, b)=>{
                        if(a.position_order < b.position_order) return -1
                        else if(a.position_order > b.position_order) return 1
                        else return 0
                    })
                    // clear to make sure no double views:
                    this.views.fullscreen = []
                    this.views.left = []
                    this.views.right = []
                    this.views.top = []
                    this.views.bottom = []
                    this.views.topRight = []
                    this.views.topLeft = []
                    this.views.bottomRight = []
                    this.views.bottomLeft = []
                    // sort views in positions:
                    for (var index = 0; index < this.playlist.views.length; index ++) {
                        if (this.playlist.views[index].position === 'fullscreen') {
                            this.views.fullscreen.push(this.playlist.views[index])
                        }
                        else if (this.playlist.views[index].position === 'left') {
                            this.views.left.push(this.playlist.views[index])
                        }
                        else if (this.playlist.views[index].position === 'right') {
                            this.views.right.push(this.playlist.views[index])
                        }
                        else if (this.playlist.views[index].position === 'top') {
                            this.views.top.push(this.playlist.views[index])
                        }
                        else if (this.playlist.views[index].position === 'bottom') {
                            this.views.bottom.push(this.playlist.views[index])
                        }
                        else if (this.playlist.views[index].position === 'topright') {
                            this.views.topRight.push(this.playlist.views[index])
                        }
                        else if (this.playlist.views[index].position === 'topleft') {
                            this.views.topLeft.push(this.playlist.views[index])
                        }
                        else if (this.playlist.views[index].position === 'bottomright') {
                            this.views.bottomRight.push(this.playlist.views[index])
                        }
                        else if (this.playlist.views[index].position === 'bottomleft') {
                            this.views.bottomLeft.push(this.playlist.views[index])
                        }
                    }
                } 

                //check if there are views in the right positions
                //save list of views and name of position into usedViews 
                //variable to have easy access to views in a specific position
                this.noViews = []
                this.usedViews = []
                if(this.playlist.layout === 'fullscreen'){
                    if (!this.views.fullscreen.length){
                        this.noViews.push("Fullscreen")
                    }
                    else{
                        //calculate how many nonVideoViews there are
                        var nonVideoViews =0
                        this.playlistTotalSeconds = 0
                        for (var index = 0; index < this.views.fullscreen.length; index ++){
                            if(this.views.fullscreen[index].content_type !== 'video'){
                                nonVideoViews = nonVideoViews+1
                            }
                        }
                        if(nonVideoViews !== 0){
                            this.playlistTotalSeconds = this.playlistTotalSeconds + this.playlist.seconds
                        }
                        this.playlistTotalSeconds = this.playlistTotalSeconds + this.playlist.video_seconds
                    }
                    const fullscreen = {
                        name: "Fullscreen",
                        views: this.views.fullscreen
                    }
                    this.usedViews.push(fullscreen)

                    
                }
                else{
                    this.playlistTotalSeconds = this.playlist.seconds
                }
                if (this.playlist.layout === 'verticalsplit' || this.playlist.layout === 'tsplitleft'){
                    if (!this.views.left.length){
                        this.noViews.push("Left")
                    }
                    const left = {
                        name: "Left",
                        views: this.views.left
                    }
                    this.usedViews.push(left)
                }
                if (this.playlist.layout === 'verticalsplit' || this.playlist.layout === 'tsplitright'){
                    if (!this.views.right.length){
                        this.noViews.push("Right")
                    }
                    const right = {
                        name: "Right",
                        views: this.views.right
                    }
                    this.usedViews.push(right)
                }
                if (this.playlist.layout === 'horizontalsplit' || this.playlist.layout === 'tsplittop'){
                    if (!this.views.top.length){
                        this.noViews.push("Top")
                    }
                    const top = {
                        name: "Top",
                        views: this.views.top
                    }
                    this.usedViews.push(top)
                }
                if (this.playlist.layout === 'horizontalsplit' || this.playlist.layout === 'tsplitbottom'){
                    if (!this.views.bottom.length){
                        this.noViews.push("bottom")
                    }
                    const bottom = {
                        name: "Bottom",
                        views: this.views.bottom
                    }
                    this.usedViews.push(bottom)
                }
                if (this.playlist.layout === 'crosssplit' || this.playlist.layout === 'tsplitright' || this.playlist.layout === 'tsplitbottom'){
                    if (!this.views.topLeft.length){
                        this.noViews.push("Top Left")
                    }
                    const topLeft = {
                        name: "Top Left",
                        views: this.views.topLeft
                    }
                    this.usedViews.push(topLeft)
                }
                if (this.playlist.layout === 'crosssplit' || this.playlist.layout === 'tsplitleft' || this.playlist.layout === 'tsplitbottom'){
                    if (!this.views.topRight.length){
                        this.noViews.push("Top Right")
                    }
                    const topRight = {
                        name: "Top Right",
                        views: this.views.topRight
                    }
                    this.usedViews.push(topRight)
                }
                if (this.playlist.layout === 'crosssplit' || this.playlist.layout === 'tsplitright' || this.playlist.layout === 'tsplittop'){
                    if (!this.views.bottomLeft.length){
                        this.noViews.push("Bottom Left")
                    }
                    const bottomLeft = {
                        name: "Bottom Left",
                        views: this.views.bottomLeft
                    }
                    this.usedViews.push(bottomLeft)
                }
                if (this.playlist.layout === 'crosssplit' || this.playlist.layout === 'tsplitleft' || this.playlist.layout === 'tsplittop'){
                    if (!this.views.bottomRight.length){
                        this.noViews.push("Bottom Right")
                    }
                    const bottomRight = {
                        name: "Bottom Right",
                        views: this.views.bottomRight
                    }
                    this.usedViews.push(bottomRight)
                }                
            }
        },
        mounted() {
            this.getPlaylistDetails()
            this.inactiveView()
        },
        methods: {


            async inactiveView(){
                //check playlist with inactive status in views

                if(this.playlist.status === 0){
                    var toast = new Toast(this.$refs.inactivePlaylist)
                    toast.show()
                }
            },

            async getPlaylistDetails(){
                //load playlist and campagne from API
                await this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})
                await this.$store.dispatch("get", {type:"campagne", pk:this.playlist.campagne})
                console.log(this.playlist)
                console.log(this.campagne)

                this.getUserSeconds()
            },
            getUserSeconds(){
                //calculate userSeconds by subtracting the videoSeconds from total seconds
                this.userSeconds=this.playlist.seconds
                console.log(this.userSeconds)
            },
            async deletePlaylist(){
                //call toast
                var toast = new Toast(this.$refs.deletePlaylistToast)
                await toast.show()

                //if playlist is active: recalculate campagne.total_time
                if (this.playlist.status === 1){
                    await this.$store.dispatch("get", {type:"campagne", pk: this.playlist.campagne})
                    const newTime = {
                        total_time: this.$store.state.ncapiStore.campagne.total_time - this.playlistTotalSeconds
                    }
                    await this.$store.dispatch("patch", {type:"campagne", pk: this.playlist.campagne, data: newTime})
                }

                //after 5 seconds, delete the playlist and go to the campagne page
                setTimeout(() => {
                    const campagnePK = this.playlist.campagne
                    this.$store.dispatch("delete", {type:"playlist", pk:this.$route.params.pk})
                    
                    this.$router.push(`/campagnes-overview/${campagnePK}`) }, 
                    5000
                );
                
            },

            async deleteView(pk){
                //load the tobe deleted view and save it's data in local variable
                //initialize viewsPosition
                await this.$store.dispatch("get", {type:"view", pk: pk})
                let currentView = this.$store.state.ncapiStore.view
                let viewsPosition = []

                //loop through all views to find list of views in the same position
                //as the tobe deleted view. save list in viewsPosition 
                for(var index = 0; index < this.usedViews.length; index++){
                    for(var i = 0; i< this.usedViews[index].views.length; i++){
                        if (this.usedViews[index].views[i].pk === pk){
                            viewsPosition = this.usedViews[index].views
                        }
                    }
                }

               

                //if the tobe deleted view is a video: recalculate playlist.seconds and if the playlist is active
                //recalculate the campagne.total_time
                if(currentView.content_type === 'video'){
                    const newSeconds = {
                        video_seconds: this.playlist.video_seconds - currentView.seconds
                    }
                    await this.$store.dispatch("patch", {type:"playlist", pk: this.$route.params.pk, data: newSeconds})

                    if(this.playlist.status === 1){
                        await this.$store.dispatch("get", {type:"campagne", pk: this.playlist.campagne})
                        const newCampagneSeconds = {
                            total_time: this.$store.state.ncapiStore.campagne.total_time - currentView.seconds
                        }
                        await this.$store.dispatch("patch", {type:"campagne", pk: this.playlist.campagne, data: newCampagneSeconds})
                    }
                }

                //calculate how many nonVideoViews there are
                var nonVideoViews =0
                for (var index = 0; index < viewsPosition.length; index ++){
                    if(viewsPosition[index].content_type !== 'video'){

                        nonVideoViews = nonVideoViews+1
                    }
                }

                //delete view and call toast
                await this.$store.dispatch("delete", {type:"view", pk: pk})
                var toast = new Toast(this.$refs.deleteViewToast)
                await toast.show()

                //loop through list of views: if a views position_order is higher than that of the deleted view, 
                //change it's position_order to make up for the deleted view and recalculate the seconds for all leftover
                //views
                for (var index = 0; index < viewsPosition.length; index ++){
                    if (viewsPosition[index].position_order > currentView.position_order){
                        const newPositionOrder = {
                            position_order: index
                        }
                        await this.$store.dispatch("patch", {type:"view", pk: viewsPosition[index].pk, data: newPositionOrder})
                    }
                }
                if(currentView.content_type !== 'video'){
                    if(nonVideoViews === 1){
                        if(this.playlist.status === 1){
                            await this.$store.dispatch("get", {type:"campagne", pk: this.playlist.campagne})
                            const updateTotalTime = {
                                total_time: this.campagne.total_time - this.playlist.seconds
                            }
                            console.log("THE PLAYLIST SECONDS", this.playlist.seconds)
                            console.log("THE CAMPAGNE SECONDS", this.campagne.total_time)
                            await this.$store.dispatch("patch", {type:"campagne", pk: this.playlist.campagne, data: updateTotalTime})
                        }
                    }
                    else{
                        const updateSecondsOrder = {
                            seconds:Math.round(this.playlist.seconds/(nonVideoViews-1)),
                        }
                        for (var index = 0; index < viewsPosition.length; index ++){
                            if(viewsPosition[index].pk !== pk && viewsPosition[index].content_type !== 'video'){
                                await this.$store.dispatch("patch", {type:"view", pk: viewsPosition[index].pk, data: updateSecondsOrder})
                            }
                        }
                    }
                }
                
                //load playlist to refresh page
                await this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})

                //if there's only 1 view in viewsPosition (which is the deleted view): change the status of playlist 
                //to inactive (because there are views missing in at least 1 position) and call toast and load playlist 
                //to refresh page
                if (viewsPosition.length === 1) {
                    var toast = new Toast(this.$refs.noViewsToast)
                    await toast.show()
                    const newStatus = {
                        status: 0
                    }
                    await this.$store.dispatch("patch",{type:"playlist", pk: this.$route.params.pk, data: newStatus})
                    this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})
                }
            },   
            async activityButton(){
                //check if the playlist has seconds, if not call toast
                if(this.playlist.seconds === 0){
                    var toast = new Toast(this.$refs.noSecondsToast)
                    toast.show()
                }
                //check if there are missing views, if there are call toast
                if (this.noViews.length){
                    var toast = new Toast(this.$refs.noViewsToast)
                    toast.show()
                }
                //check if playlist has sseconds and no missing views
                else if (this.playlist.seconds !== 0 && !this.noViews.length) {
                    //if playlist is inactive: call toast, change status to active, and update campagne.total_time
                    if (this.playlist.status === 0) {
                        var toast = new Toast(this.$refs.activatedToast)
                        toast.show()
                        const newStatus = {
                            status: 1
                        }
                        await this.$store.dispatch("patch",{type:"playlist", pk: this.$route.params.pk, data: newStatus})
                        await this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})
                        await this.$store.dispatch("get", {type:"campagne", pk: this.playlist.campagne})

                        const newTime = {
                            total_time: this.$store.state.ncapiStore.campagne.total_time + this.playlistTotalSeconds
                        }
                        await this.$store.dispatch("patch", {type:"campagne", pk: this.playlist.campagne, data: newTime})
                    }
                    //if playlist is active: call toast, change status to inactive, and updat campagne.total_time
                    else {
                        var toast = new Toast(this.$refs.inactivatedToast)
                        toast.show()
                        const newStatus = {
                            status: 0
                        }
                        await this.$store.dispatch("patch", {type:"playlist", pk: this.$route.params.pk, data: newStatus})
                        await this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})
                        await this.$store.dispatch("get", {type:"campagne", pk: this.playlist.campagne})

                        const newTime = {
                            total_time: this.$store.state.ncapiStore.campagne.total_time - this.playlistTotalSeconds
                        }
                        await this.$store.dispatch("patch", {type:"campagne", pk: this.playlist.campagne, data: newTime})
                    }
                }
            },

            addEventListeners() {
                //get all items with class 'draggable' and all <tr> elements with class 'draggable-list'
                const draggables = document.querySelectorAll('.draggable');
                const dragListItems = document.querySelectorAll('.draggable-list section');

                // draggables.forEach(draggable => {
                //     // draggable.addEventListener('dragstart', this.dragStart());
                // });

                //for each view in dragListItems add eventlisteners for dragging
                dragListItems.forEach(view => {
                    view.addEventListener('dragover', this.dragOver);
                    view.addEventListener('drop', this.dragDrop);
                    view.addEventListener('dragenter', this.dragEnter);
                    view.addEventListener('dragleave', this.dragLeave);
                });
            },

            dragStart(index, viewPK) {
                //save the index and pk of currently dragged view
                this.dragStartIndex = +index
                this.currentViewPK = viewPK
            },

            dragEnter() {
            // this.classList.add('over');
            },

            dragLeave() {
            // this.classList.remove('over');
            },

            dragOver(e) {
            e.preventDefault();
            },

            dragDrop(event) {
                //save the index of the view that the dragged item was dropped on
                //and call reorderItems 
                this.dragEndIndex = +event.currentTarget.getAttribute('data-index');
                this.reorderItems(this.dragStartIndex, this.dragEndIndex, this.currentViewPK);
                // this.classList.remove('over');
            },

            async reorderItems(oldIndex, newIndex, currentViewPK){
                //initialize positionDecider
                let positionDecider = []

                //find the views that have the same position as the dragged view and save it in positionDecider
                for(var index = 0; index < this.usedViews.length; index++){
                    for(var i = 0; i< this.usedViews[index].views.length; i++){
                        if (this.usedViews[index].views[i].pk === currentViewPK){
                            positionDecider = this.usedViews[index].views
                        }
                    }
                }

                //for every view in positionDecider check if their position_order should be changed because of the dragged view
                for (var index = 0; index < positionDecider.length; index ++) {
                    if (index <= newIndex && index > oldIndex){
                        const newPositionOrder = {
                            position_order: positionDecider[index].position_order-1
                        }
                        await this.$store.dispatch("patch", {type:"view", pk: positionDecider[index].pk, data: newPositionOrder})
                    }
                    else if (index >= newIndex && index < oldIndex){
                        const newPositionOrder = {
                            position_order: positionDecider[index].position_order+1
                        }
                        await this.$store.dispatch("patch", {type:"view", pk: positionDecider[index].pk, data: newPositionOrder})
                    }
                }
                
                //save the position_order where the dragged view needs to go and patch this
                let newSpot = positionDecider[newIndex]

                const newPositionOrder = {
                    position_order: newSpot.position_order
                }
                
                await this.$store.dispatch("patch", {type:"view", pk: positionDecider[oldIndex].pk, data: newPositionOrder})
                this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})
            },    
        }
    }
</script>

<style scoped>
.draggable{
    cursor: default;
}

#square{
    background-color: black;
    border: 1px solid red;
    height: 210px;
    width: 400px;
    
}

#squareB{
    background-color: black;
    height: 200px;
    width: 400px;
    border: 1px solid red;
    background-image: linear-gradient(to left,
             rgba(255,255,255, 0) 0%,
             rgba(255,255,255,0) calc(50% - 0.8px),
             rgba(255,255,255,1) 25%,
             rgba(255,255,255,0) calc(50% + 0.8px),
             rgba(255,255,255,0) 100%);
}

#squareC{
    /* horizontal */
    background-color: black;
    height: 180px;
    width: 400px;
    border: 1px solid red;
    background-image: 
         linear-gradient(to bottom,
             rgba(255,255,255,0) 0%,
             rgba(255,255,255,0) calc(50% - 0.8px),
             rgba(255,255,255,1) 25%,
             rgba(255,255,255,0) calc(50% + 0.8px),
             rgba(255,255,255,0) 100%);  
}

#squareD{
    background-color: black;
    height: 180px;
    width: 400px;
    border: 1px solid red;
    background-image:  linear-gradient(to left,
             rgba(255,255,255, 0) 0%,
             rgba(255,255,255,0) calc(50% - 0.8px),
             rgba(255,255,255,1) 25%,
             rgba(255,255,255,0) calc(50% + 0.8px),
             rgba(255,255,255,0) 100%),
         linear-gradient(to bottom,
             rgba(255,255,255,0) 0%,
             rgba(255,255,255,0) calc(50% - 0.8px),
             rgba(255,255,255,1) 25%,
             rgba(255,255,255,0) calc(50% + 0.8px),
             rgba(255,255,255,0) 100%);  
}
#squareE{
    border: 1px solid red;
    height: 200px;
    width: 400px; 
}

.card-body{
    padding: .5rem;
}

.card-header-actaorangeBg{
background-color: #f7960b !important;
}

.border-actaorangeBg{
    border-color:#f7960b;
}
</style>
