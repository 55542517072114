<template>
    <div class="container" >
        <div class="col-6">
            <form @submit.prevent="submitForm">
                <div class="field">
                    <label>Name</label>
                    <div class="control">
                        <input type="text" class="input form-control" v-model="name" required="required">
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="btn btn-primary mt-3">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="flippingtoast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-success border-0">
                    <strong class="me-auto">Success!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The campagne has been added!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const {Toast} = bootstrap
export default {
    name: 'AddCampagne',
    data() {
        return {
            name:'',
        }
    },
    methods: {
        submitForm() {
            // console.log('submit form')
            //call toast
            var toast = new Toast(this.$refs.flippingtoast)
            toast.show()

            //add campagne to API and empty values
            const campagne = {
                administration_id: localStorage.administrationID,
                name: this.name,
            }
            this.$store.dispatch('post', {type:"campagne", data:campagne})
            this.name= ''

        },
    },
}
</script>
