<template>
    <div class="container" >
        <div class="col-6">
            <form @submit.prevent="submitForm(index)">
                <div class="field">
                    <label>Name</label>
                    <div class="control mb-2">
                        <input type="text" class="input form-control" v-model="name" required="required">
                    </div>
                </div>

                <div class="field">
                    <label>Layout</label>
                    <div class="control mb-1">
                        <div class="select">
                            <select v-model="layout" class="form-select">
                                <option disabled value="">Please select one</option>
                                <option value="fullscreen">Fullscreen</option>
                                <option value="verticalsplit">Verticaal Split Screen</option>
                                <option value="horizontalsplit">Horizontaal Split Screen</option>
                                <option value="crosssplit">Kruis Split Screen</option>
                                <option value="tsplitright">T Split Screen Rechts</option>
                                <option value="tsplitleft">T Split Screen Links</option>
                                <option value="tsplitbottom">T Split Screen Beneden</option>
                                <option value="tsplittop">T Split Screen Boven</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label>Seconds</label>
                    <div class="control">
                        <input type="number" class="input form-control" v-model.number="seconds" required="required">
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="btn mt-3">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="flippingtoast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-success border-0">
                    <strong class="me-auto">Success!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The playlist was added! </p>
                    <p>You will be redirected to the playlist page</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const {Toast} = bootstrap
export default {

    name: 'AddPlaylist',
    data() {
        return {
            playlist:[],
            campagne:[],
            name: '',
            layout: 'fullscreen',
            seconds: 0,
            //incoming_transition: '',
            //outgoing_transition: '',
        }
    },
    methods: {
        async submitForm() {
            //store campagne data in local variable and call toast
            this.campagne = this.$store.state.ncapiStore.campagne
            var toast = new Toast(this.$refs.flippingtoast)
            toast.show()

            //post new playlist data to API and get campagne to reload page
            const playlist = {
                administration_id: localStorage.administrationID,
                campagne: this.campagne.pk,
                name: this.name,
                layout: this.layout,
                position_order: this.campagne.playlists.length +1,
                seconds: this.seconds,
                //incoming_transition: this.incoming_transition,
                //outgoing_transition: this.outgoing_transition,
            }
            await this.$store.dispatch("post", {type:"playlist", data: playlist})
            await this.$store.dispatch("get", {type:"campagne", pk:this.$route.params.pk})

            //empty values
            this.name= ''
            this.layout= 'fullscreen'
            this.seconds= 0
            //this.incoming_transition= ''
            //this.outgoing_transition= ''

            //get PK of newest playlist to go to its page
            this.campagne = this.$store.state.ncapiStore.campagne
            const currentPlaylist = this.campagne.playlists[this.campagne.playlists.length - 1]
            await this.$store.dispatch("get", {type:"playlist", pk: currentPlaylist.pk})

            setTimeout(() => { 
                this.$router.push(`/campagnes-overview/playlist/${currentPlaylist.pk}`) 
                }, 5000
            );
        },
    },
}
</script>
