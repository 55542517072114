<template>
{{getData}}
        <div class="col-12 mb-3">
            <h2 class=" my-4" >Devices</h2>
            <button class="btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-plus-circle me-2"></i>Add device</button>

            <div class="collapse" id="collapseExample">
                <div class="card card-body">
                    <AddDevice />
                </div>
            </div>
        </div>
        
        <Card cls="" icon="window-fullscreen">
            <template v-slot:header>
                Devices
            </template>
            <template v-slot:body>
                 <table class="table table-borderless table-hover"> 
                <thead>
                    <tr>
                        <th>Device Name:</th>
                        <th>Location:</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="(device, index) in devices"
                        v-bind:key="device.pk"
                        @click="goDetails(index)"
                    >   
                        <td>{{device.name}}</td>
                        <td>{{locationNames[index]}}</td>
                        <td>
                            <router-link :to="{ name: 'Device', params: { pk: device.pk}}" class="btn">Details</router-link>
                        </td>  
                    </tr>
                </tbody>
            </table>
            </template>
        </Card>

        <!-- <div class="card border-actaDarkblue mb-3 ">
            <h5 class="card-header-actaDarkblue text-white bg-actaDarkblue">Devices</h5>
            <table class="table table-borderless table-hover"> 
                <thead>
                    <tr>
                        <th>Device Name:</th>
                        <th>Location:</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="(device, index) in devices"
                        v-bind:key="device.pk"
                        @click="goDetails(index)"
                    >   
                        <td>{{device.name}}</td>
                        <td>{{locationNames[index]}}</td>
                        <td>
                            <router-link :to="{ name: 'Device', params: { pk: device.pk}}" class="btn btn-outline-primary">Details</router-link>
                        </td>  
                    </tr>
                </tbody>
            </table>
        </div> -->

        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="noDevices" class="toast hide text-black bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-black bg-danger border-0">
                    <strong class="me-auto"><i class="bi bi-info-circle-fill"></i> There are no devices</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body">
                        <p>To get started click 'add device' to add a new device</p>
                    </div>
            </div>
        </div> 

    
</template>

<script> 
import AddDevice from '../views/AddDevice.vue'

const {Toast} = bootstrap

    export default {
        name: 'devicesOverview',
        components: {
            AddDevice,
        },
        data(){
            return{
                devices:[],
                locations:[],
                locationNames:[],
                deviceCount: [],
            }
        },

        computed: {
            getData(){
                //store devices and locations data into local variables
                this.devices = this.$store.state.ncapiStore.devices
                this.locations = this.$store.state.octoblueStore.locations

                for (var index = 0; index < this.locations.length; index ++){
                    for(var i=0; i<this.devices.length; i++){
                        if (this.devices[i].location_id === this.locations[index].id){
                            this.locationNames.push(this.locations[index].name)
                        }
                    }
                }
            }
        },
        mounted (){
            this.onPageLoad()
            this.currentDevices()
            //console.log(process.env.VUE_APP_NCAPI)
        },
        
        methods: {

            async currentDevices(){
                if(this.devices.length === 0){
                    console.log('this is a problem')
                    var toast = new Toast(this.$refs.noDevices)
                    toast.show()
                }

                //   if(this.devices.length){
                //     this.deviceCount = this.devices.length
                //     console.log('this is devices amount', this.deviceCount)
                //     var toast = new Toast(this.$refs.devicesCount)
                //     toast.show()
                // }
            },

            async onPageLoad(){
                //load all devices and locations into store
                await this.$store.dispatch("getAllLocations")
                await this.$store.dispatch("getAll", {type:"device"})
            },
            goDetails(index){
                //go to device details page of selected device
                this.$router.push(`/devices-overview/${this.devices[index].pk}`)
            }
        }
    }
</script>
<style scoped>
td{
    cursor: pointer;
}
</style>