<template>
  <div class="">{{getData}}
      
      <NavBar cls="">
          <template v-slot:navHeader><b>NarrowCasting FAQs</b> </template>
          <template v-slot:navLinks></template>
          <template v-slot:navForm>
               <div >
                    <ul class="m-0 nav-item">
                        <li class=" " style="list-style-type: none;">
                            <div class="nav-item dropdown ">
                                <a class="nav-link dropdown-toggle fs-5 text-white" href="#" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                     <i class="bi bi-person-fill me-1" aria-label="Account options"></i>{{adminName}}
                                </a>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" class="dropdown-item dropColor fs-5"><i class="bi bi-person-circle me-2 mx-3" aria-label="Account"></i>Account</a></li>
                                        <li><a href="/registration" class="dropdown-item dropColor fs-5"><i class="bi bi-files  me-2 mx-3" aria-label="Administration"></i>Administration</a></li>
                                        <li><a href="/faq" class="dropdown-item dropColor fs-5"><i class="bi bi-question-circle me-2 mx-3" aria-label="FAQ"></i>FAQ</a></li>
                                        <li><hr class="dropdown-divider dropColor fs-5"></li>
                                        <li><a href="#backDrop" class="dropdown-item dropColor fs-5" data-bs-toggle="modal"><i class="bi bi-box-arrow-right  me-2 mx-3" aria-label="Log out"></i>Log out</a></li>
                                    </ul>
                            </div>
                        </li>
                    </ul>
                </div>
          </template>
          <template v-slot:navModals></template>
      </NavBar>
      
        <div class="container">
            
            <div class="row justify-content-lg me-2 my-3">
                <FaqCard link="/faqportal" cls="-md-6" icon="person-fill">
                    <template v-slot:body></template>
                    <template v-slot:title>NarrowCasting Info</template>
                    <template v-slot:text>Alles over de basis van narrow casting in 10 eenvoudige stappen</template>
                </FaqCard>

                <FaqCard link="" cls="-md-6" icon="headphones">
                    <template v-slot:body></template>
                    <template v-slot:title>Stuen</template>
                    <template v-slot:text>Voor alle vragen</template>
                </FaqCard>
            </div>
        </div>

         <div class="modal fade" id="backDrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Log Out</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                            <div class="modal-body">
                            <strong>You are about to leave, Make sure you save your changes!</strong>
                            </div>
                        <div class="modal-footer">
                            <button type="button" class="btn" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn" @click="KClogout()" data-bs-dismiss="modal">Log Out</button>
                        </div>
                    </div>
                </div>
            </div>
  </div>
</template>

<script>
import Navbar from '../components/layout/Navbar.vue'

const {Toast} = bootstrap

export default {
name: 'Faq',
components:{
    Navbar
},

data(){
    return{
        adminName: '',
    }
},

mounted(){
this.getAdminInfo()
},

computed:{
getData(){

}
},

methods:{
                KClogout(){
                this.$store.dispatch('key/logout')
                console.log("in out of here")
                this.$router.push('/')
            },
          async getAdminInfo(){
            this.adminName = localStorage.adminName
                await this.$store.dispatch("getAllLocations")
                await this.$store.dispatch("getAll", {type:"device"})
                await this.$store.dispatch("getAll", {type:"schedule"})
            },
}

}
</script>

<style>
#accountFAQ{
    font-size: 50px;
     color: #51BADD!important;
}
#emailFAQ{
    font-size: 50px;
    color: #51BADD!important;
}
#castingFAQ{
    font-size: 50px;
    color: #51BADD!important;
}
hr{
    border-color: rgb(34, 34, 34);
    /* opacity: 7; */
    height: 5px;
    width: 100px;
}

#communityFAQ{
    font-size: 50px;
    color: #51BADD;
}

#portalFAQ{
    font-size: 50px;
    color: #51BADD;
}

#supportFAQ{
    font-size: 50px;
    color: #51BADD;
}
p{
    color: black
}
#headerText{
    color: #51BADD;
}
h2{
    color: black;
}
#myDIV{

}
.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}
.active, .sbItem:hover {
  background-color: #f7960b;
}

</style>