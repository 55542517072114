import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import Vue from 'vue'
import VueLogger from 'vuejs-logger';
import * as Keycloak from 'keycloak-js';

//Sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

//components
import Card from './components/Card.vue'
import SideBar from './components/SideBar.vue'
import NavBar from './components/NavBar.vue'
import ToolTip from './components/ToolTip.vue'
import Admin from './components/Admin.vue'
import FaqCard from './components/FAQCard.vue'
import Input from './components/Input.vue'
import RegistrationForm from './components/RegistrationForm.vue'
import SelectForm from './components/SelectForm.vue'


const app = createApp(App)

app.use(store)
app.use(router, axios)

Sentry.init({
  app,
  dsn: "https://79109081b8044ecf9b1e0cd6dc2aa6f6@sentry.staging.octoblue.nl/4",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "narrowcastingwebapp.staging.octoblue.nl" ,"narrowcasting.actablue.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});


app.component('Card', Card)
app.component('SideBar', SideBar)
app.component('NavBar', NavBar)
app.component('ToolTip', ToolTip)
app.component('Admin',Admin)
app.component('FaqCard', FaqCard)
app.component('Input',Input)
app.component('RegistrationForm',RegistrationForm)
app.component('SelectForm', SelectForm)


app.mount('#app')

// createApp(App).use(Card).use(router,axios ).mount('#app')






/*
createApp(App).use(store).use(router, axios).mount('#app')

store.dispatch('key/check')
.then( res => {
  console.log('logged in')
})
.catch( err => {
  router.push('/help')
})
//router.push('/Dev')
*/

/*
axios.defaults.baseURL = process.env.VUE_APP_NCAPI
let currentHostname = window.location.hostname; 
let urls = {
  api: process.env.VUE_APP_NCAPI,
  login: process.env.VUE_APP_KC_URL
};

console.log(urls)
store.commit("setAPIAndLogin", urls);
*/
/*
if (currentHostname.indexOf('localhost') > -1) {
  urls = {
    api: 'http://localhost:8100/',
    login: 'https://keycloak.octoblue.nl/auth/' // insert your http or https://<KeycloakURL>/auth
  }
  store.commit("setAPIAndLogin", urls);
}
else {
  let webapiUrl = 'https://' + currentHostname + '/'; // OpenShift part 1 using http
  let keycloakUrl = 'https://' + currentHostname + '/auth'; // OpenShift part 1 using http
  urls = {
    api: webapiUrl,
    login: keycloakUrl
  }
  store.commit("setAPIAndLogin", urls);
}
*/

// let initOptions = store.getters['key/kcOptions']
// //console.log("Options", initOptions)

// let keycloak = Keycloak(initOptions);

// keycloak.init({ onLoad: initOptions.onLoad })
// .then((auth) => {
//   console.log("initialized")
//   console.log( "Auth: ", typeof( auth ), auth, {...auth} )
//   // if (!auth) {
//   //   window.location.reload();
//   // } else {
//   //   console.log("Authenticated")
//   //   // createApp(App).use(store).use(router, axios).mount('#app')
    
//   //   console.log("Authenticated");
//   //   let payload = {
//   //     idToken: keycloak.idToken,
//   //     accessToken: keycloak.token
//   //   }
//   //   if (keycloak.token && keycloak.idToken && keycloak.token != '' && keycloak.idToken != '') {
//   //     store.commit("login", payload);
//   //     console.log("User has logged in: " + keycloak.subject)
//   //     payload = {
//   //       name: keycloak.tokenParsed.preferred_username,
//   //     };
//   //     store.commit("setName", payload);
//   //   }
//     // router.push("/")
//   // }
  
  
//   //Token Refresh
//   // setInterval(() => {
//   //   keycloak.updateToken(70)
//   //   .then((refreshed) => {
//   //     if (refreshed) {
//   //       console.log('Token refreshed' + refreshed);
//   //       let payloadRefreshedTokens = {
//   //         idToken: keycloak.idToken,
//   //           accessToken: keycloak.token
//   //       }
//   //       if (keycloak.token && keycloak.idToken && keycloak.token != '' && keycloak.idToken != '') {
//   //           store.commit("login", payloadRefreshedTokens);
//   //       }
//   //       else {
//   //           store.dispatch("key/logout");
//   //         }
//   //         //   } else {
//   //           //     console.log('Token not refreshed, valid for '
//   //         //     + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
//   //     }
//   //   })
//   //   .catch(() => {
//   //     console.log('Failed to refresh token');
//   //   });
//   // }, 6000)
  
// })
// .catch(error => {
//   console.log(error)
//   console.log("Authenticated Failed");
// });
