import axios from "axios";
import _ActablueAPI from './api/_ActablueAPI' 
import _NarrowCastingAPI from './api/_NarrowCastingAPI' 

export const baseState = {
    base_url: '',

}

export const mutations = {

}

export const getters = {
    ax(){ 
        axios.create({
            baseURL: '',
            headers: [
                ''
            ]
        })
    }
}
 
export const actions = {
    async post( store, { url, data } ) {
        return await axios.post( url, data )
    },
    async get( store, url ) {
        return await axios.get( url )
    },
    async patch( store, { url, data } ){
        return await axios.patch( url, data )
    },
    async put( store, { url, data } ){
        return await axios.put( url, data )
    },
    async delete( store, url ){
        return await axios.delete( url )
    },
}

export default {
    namespaced: true,
    state: baseState, 
    mutations: mutations, 
    getters: getters, 
    actions: actions,
    modules: {
        ab: _ActablueAPI,
        nc: _NarrowCastingAPI
    }
}