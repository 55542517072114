<template>
    <div class="container" >
        <div class="col-6">
            <form @submit.prevent="submitForm(index)">
                <div class="field mb-2">
                    <label>Name</label>
                    <div class="control">
                        <input type="text" class="input form-control" v-model="name" required>
                    </div>
                </div>

                <div class="field mb-2">
                    <label>Position</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="position" class="form-select">
                                <option disabled value="">Please select one</option>
                                <option v-if="layout.topLeft" value="topleft">Boven Links</option>
                                <option v-if="layout.topRight" value="topright">Boven Rechts</option>
                                <option v-if="layout.bottomLeft" value="bottomleft">Beneden Links</option>
                                <option v-if="layout.bottomRight" value="bottomright">Beneden Rechts</option>
                                <option v-if="layout.left" value="left">Links</option>
                                <option v-if="layout.right" value="right">Rechts</option>
                                <option v-if="layout.bottom" value="bottom">Beneden</option>
                                <option v-if="layout.top" value="top">Boven</option>
                                <option v-if="layout.fullscreen" value="fullscreen">Fullscreen</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field mb-2">
                    <label>Content type</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="content_type" class="form-select">
                                <option disabled value="">Please select one</option>
                                <option value="url">URL</option>
                                <option value="image">Image</option>
                                <option value="html">HTML</option>
                                <option value="youtube">Youtube</option>
                                <option value="video" v-if="position === 'fullscreen'">Video</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field" v-if="content_type === 'url'">
                    <label>URL</label>
                    <div class="control">
                        <input type="url" class="input form-control" v-model="url" required="required">
                    </div>
                </div>

                <div class="field" v-if="content_type === 'youtube'">
                    <label>Youtube</label>
                    <div class="control">
                        <input type="url" class="form-control" v-model="youtube" required="required">
                    </div>
                    <div id="youtubeHelp" class="form-text"><b>Youtube Link Format:</b> https://www.youtube.com/embed/VideoID</div>
                </div>

                <div class="field" v-if="content_type === 'html'">
                    <label>HTML</label>
                    <div class="control">
                        <textarea type="url" class="form-control" v-model="html"></textarea>
                    </div>
                </div>

                <div class="field" v-if="content_type === 'image'">
                    <label>Image</label>
                    <div class="control">
                        <input type="file" class="form-control" @change="onFileChange" required="required">
                    </div>
                </div>

                <div class="field" v-if="content_type === 'video'">
                    <label>Video</label>
                    <div class="control">
                        <input type="file" class="form-control" @change="onFileChange" required="required">
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="btn btn-primary mt-3">Submit</button>
                        <span  v-if="content_type ===youtube"></span>
                    </div>
                </div>
            </form>
        </div>

    	<div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="flippingtoast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-success border-0">
                    <strong class="me-auto">Success!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The view has been added!</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
const {Toast} = bootstrap
export default {

    name: 'AddView',
    props: {
        objSortedViews: {
            type: Object
        },
        PlaylistSeconds: {
            type: Number 
        },
    },
    data() {
        return {
            playlist:[],
            name: '',
            content_type: 'url',
            position: 'fullscreen',
            url: '',
            youtube: '',
            html: '',
            image: null,
            mediaData: '',
            vidSeconds: 0,
            layout: {
                fullscreen: false,
                left: false,
                right: false,
                top: false,
                bottom: false,
                topRight: false,
                topLeft: false,
                bottomRight: false,
                bottomLeft: false,
            }
        }
    },
    mounted(){
        this.onPageLoad()
    },
    methods: {

        async onPageLoad(){
            //load playlist data in store and store it in local variable
            // await this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})
            this.playlist = this.$store.state.ncapiStore.playlist

            //sort by layout which positions are posible for the view
            if (this.playlist.layout === 'fullscreen'){
                this.layout.fullscreen = true
                this.position = 'fullscreen'
            }
            else if (this.playlist.layout === 'verticalsplit'){
                this.layout.left = true
                this.layout.right = true
                this.position = 'left'
            }
            else if (this.playlist.layout === 'horizontalsplit'){
                this.layout.top = true
                this.layout.bottom = true
                this.position = 'top'
            }
            else if (this.playlist.layout === 'crosssplit'){
                this.layout.topLeft = true
                this.layout.topRight = true
                this.layout.bottomRight = true
                this.layout.bottomLeft = true
                this.position = 'topleft'
            }
            else if (this.playlist.layout === 'tsplitleft'){
                this.layout.left = true
                this.layout.topRight = true
                this.layout.bottomRight = true
                this.position = 'left'
            }
            else if (this.playlist.layout === 'tsplitright'){
                this.layout.right = true
                this.layout.topLeft = true
                this.layout.bottomLeft = true
                this.position = 'topleft'
            }
            else if (this.playlist.layout === 'tsplittop'){
                this.layout.top = true
                this.layout.bottomRight = true
                this.layout.bottomLeft = true
                this.position = 'top'
            }
            else if (this.playlist.layout === 'tsplitbottom'){
                this.layout.bottom = true
                this.layout.topRight = true
                this.layout.topLeft = true
                this.position = 'topleft'
            }
        },

        async submitForm() {
            //call toast and initialize formData
            var toast = new Toast(this.$refs.flippingtoast)
            toast.show()
            const formData = new FormData();

            //store playlist data in local variable and initialize viewsPosition
            this.playlist = this.$store.state.ncapiStore.playlist
            let viewsPosition = []

            //store sortedViews in the right position
            if (this.position === 'fullscreen') {
                viewsPosition = this.objSortedViews.fullscreen
            }
            else if (this.position === 'left'){
                viewsPosition = this.objSortedViews.left
            }
            else if (this.position === 'right'){
                viewsPosition = this.objSortedViews.right
            }
            else if (this.position === 'top'){
                viewsPosition = this.objSortedViews.top
            }
            else if (this.position === 'bottom'){
                viewsPosition = this.objSortedViews.bottom
            }
            else if (this.position === 'topleft'){
                viewsPosition = this.objSortedViews.topLeft
            }
            else if (this.position === 'topright'){
                viewsPosition = this.objSortedViews.topRight
            }
            else if (this.position === 'bottomleft'){
                viewsPosition = this.objSortedViews.bottomLeft
            }
            else if (this.position === 'bottomright'){
                viewsPosition = this.objSortedViews.bottomRight
            }

            //calculate amount of non-video views 
            var nonVideoViews =0
            for (var index = 0; index < this.objSortedViews.fullscreen.length; index ++){
                if(this.objSortedViews.fullscreen[index].content_type !== 'video'){
                    nonVideoViews = nonVideoViews+1
                }
            }
            
            //post view data to API
            if(this.content_type === 'image'){
                formData.append("administration_id", localStorage.administrationID)
                formData.append("playlist", this.$store.state.ncapiStore.playlist.pk)
                formData.append("name", this.name)
                formData.append("content_type", this.content_type)
                formData.append("position", this.position)
                formData.append("position_order", viewsPosition.length+1)

                if (this.position === "fullscreen"){
                    formData.append("seconds", Math.round(this.PlaylistSeconds/(nonVideoViews+1)))
                }
                else{
                    formData.append("seconds", Math.round(this.PlaylistSeconds/(viewsPosition.length+1)))
                }

               // formData.append("incoming_transition", this.incoming_transition)
               // formData.append("outgoing_transition", this.outgoing_transition)
                if(this.mediaData){
                    formData.append("image", this.mediaData, this.mediaData.name)
                }

                await this.$store.dispatch("post", {type:"view", data: formData})
            }
            else if(this.content_type === 'html'){
                let viewSeconds = 0
                if (this.position === "fullscreen"){
                    viewSeconds = Math.round(this.PlaylistSeconds/(nonVideoViews+1))
                }
                else{
                    viewSeconds = Math.round(this.PlaylistSeconds/(viewsPosition.length+1))
                }
                const view = {
                    administration_id: localStorage.administrationID,
                    playlist: this.$store.state.ncapiStore.playlist.pk,
                    name: this.name,
                    content_type: this.content_type,
                    position: this.position,
                    position_order: viewsPosition.length+1,
                    seconds: viewSeconds,
                   // incoming_transition: this.incoming_transition,
                   // outgoing_transition: this.outgoing_transition,
                    html: this.html,
                }
                await this.$store.dispatch("post",{type:"view", data:view})
            }
            else if(this.content_type === 'url'){
                let viewSeconds = 0
                if (this.position === "fullscreen"){
                    viewSeconds = Math.round(this.PlaylistSeconds/(nonVideoViews+1))
                }
                else{
                    viewSeconds = Math.round(this.PlaylistSeconds/(viewsPosition.length+1))
                }
                const view = {
                    administration_id: localStorage.administrationID,
                    playlist: this.$store.state.ncapiStore.playlist.pk,
                    name: this.name,
                    content_type: this.content_type,
                    position: this.position,
                    position_order: viewsPosition.length+1,
                    seconds: viewSeconds,
                   // incoming_transition: this.incoming_transition,
                    //outgoing_transition: this.outgoing_transition,
                    url: this.url,
                }
                await this.$store.dispatch("post",{type:"view", data:view})
            }
            else if(this.content_type === 'video'){
                formData.append("administration_id", localStorage.administrationID)
                formData.append("playlist", this.$store.state.ncapiStore.playlist.pk)
                formData.append("name", this.name)
                formData.append("content_type", this.content_type)
                formData.append("position", this.position)
                formData.append("position_order", viewsPosition.length+1)
                formData.append("seconds", this.vidSeconds)
               // formData.append("incoming_transition", this.incoming_transition)
               // formData.append("outgoing_transition", this.outgoing_transition)
                if(this.mediaData){
                    formData.append("video", this.mediaData, this.mediaData.name)
                }

                await this.$store.dispatch("post", {type:"view", data: formData})
            }
            
            //#New
               else if(this.content_type === 'youtube'){
                let ytSeconds = 0
                if (this.position === "fullscreen"){
                    ytSeconds = Math.round(this.PlaylistSeconds/(nonVideoViews+1))
                }
                else{
                    ytSeconds = Math.round(this.PlaylistSeconds/(viewsPosition.length+1))
                }
                const view = {
                    administration_id: localStorage.administrationID,
                    playlist: this.$store.state.ncapiStore.playlist.pk,
                    name: this.name,
                    content_type: this.content_type,
                    position: this.position,
                    position_order: viewsPosition.length+1,
                    seconds: ytSeconds,
                   // incoming_transition: this.incoming_transition,
                    //outgoing_transition: this.outgoing_transition,
                    youtube: this.youtube,
                }
                await this.$store.dispatch("post",{type:"view", data:view})
            }

            
            //calculate seconds of non-video views and patch them with updated seconds
            if (this.content_type !== 'video') {
                for (var index = 0; index < viewsPosition.length; index ++){
                    if(viewsPosition[index].content_type !== 'video'){
                        let newSeconds = 0
                        if(this.position === 'fullscreen'){
                            newSeconds = {
                                seconds:Math.round(this.PlaylistSeconds/(nonVideoViews +1)),
                            } 
                        }
                        else {
                            newSeconds = {
                                seconds:Math.round(this.PlaylistSeconds/(viewsPosition.length +1)),
                            } 
                        }
                        await this.$store.dispatch("patch", {type:"view", pk: viewsPosition[index].pk, data: newSeconds})
                    }
                }
                if(nonVideoViews === 0 & this.playlist.status === 1){
                    await this.$store.dispatch("get", {type:"campagne", pk: this.playlist.campagne})
                    const newTotalTime = {
                        total_time: this.$store.state.ncapiStore.campagne.total_time + this.playlist.seconds
                    }
                    await this.$store.dispatch("patch", {type:"campagne", pk: this.playlist.campagne, data: newTotalTime})
                }
            }
            //update playlist.seconds and campagne.total_time if the playlist is active with video seconds
            else {
                const newPlaylistSeconds = {
                    video_seconds: this.playlist.video_seconds + this.vidSeconds
                }
                await this.$store.dispatch("patch", {type:"playlist", pk: this.$route.params.pk, data: newPlaylistSeconds})
                if(this.playlist.status === 1){
                    await this.$store.dispatch("get", {type:"campagne", pk: this.playlist.campagne})
                    const newCampagneSeconds = {
                        total_time: this.$store.state.ncapiStore.campagne.total_time + this.vidSeconds
                    }
                    await this.$store.dispatch("patch", {type:"campagne", pk: this.playlist.campagne, data: newCampagneSeconds})
                }
            }

            //load playlist again to update page
            //and empty values
            this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})

            this.name= ''
            this.content_type= 'url'
            // this.position= 'fullscreen'
            //this.incoming_transition= ''
            //this.outgoing_transition= ''
            this.mediaData= ''
            this.url= ''
            this.html= ''
            this.youtube=''

        },
        onFileChange(e) {
            //on fileChange store file data in mediaData
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;

            this.mediaData = files[0]
            
            //if the file is a video calculate video seconds 
            if(this.mediaData.type === "video/mp4"){
                var vid = document.createElement('video');
                var fileURL = URL.createObjectURL(files[0]);
                vid.src = fileURL;
                
                // wait for duration to change from NaN to the actual duration
                // vid.ondurationchange = function() {
                //     // alert(vid.duration);
                //     this.vidSeconds= Math.round(vid.duration)
                // }

                setTimeout(() => { 
                    this.vidSeconds= Math.round(vid.duration) 
                    // console.log("DONE WITH WAITING", this.vidSeconds)
                    }, 100
                );
            }
        },
    },
}
</script>