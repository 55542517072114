import axios from 'axios'

export default {
    state:{
        actablue_api_base_url: process.env.VUE_APP_AB_API,
        actablue_api_nc_app_name: process.env.VUE_APP_AB_APP_NAME,
        locations: [],
        administrations: [],
        merchID: '',
        subscriptionID: '',
    },
    getters: {
        abAPIBaseURL(state) {
            return (resource) => state.actablue_api_base_url + resource
        },
        abAPIAppName(state) {
            return () => state.actablue_api_nc_app_name
        }

    },
    mutations:{
        setLocations(state, context) {
            state.locations = context
        },
        setAdministrations(state, context) {
            state.administrations = context
        },

        setMerchantID(state, context){
            state.merchID = context
        },
        setSubscriptionID(state, context){
            state.subscriptionID = context
        },

    },
    actions: {
        async getAllLocations(store){
            console.log("check this", store.rootGetters.token)
            console.log("URL", store.getters.abAPIBaseURL)
            await axios
              .get( store.getters.abAPIBaseURL( 'locations/location/list/all/' ), {headers: {Authorization: 'Bearer ' + store.rootGetters.token, administration: localStorage.administrationID}})
              .then(response => {
                store.commit('setLocations', response.data)
              })
              .catch(err => {
                  console.log({...err})
                  throw err
              })
        },
        async getAdministrations({commit, getters, rootGetters}) {
            console.log("check this", rootGetters.token)
            console.log("URL", getters.abAPIBaseURL( 'serials/serial/list' ))
            await axios 
            .get( getters.abAPIBaseURL( 'serials/serial/list' ), {headers: {Authorization: 'Bearer ' + rootGetters.token}})
            .then(response => {
                commit('setAdministrations',response.data)
            })
            .catch(err => {
                console.log({...err})
                throw err
            })
            
        },
        async postMerchant(store, postdata){
            console.log("reached post merchant")
            console.log(postdata)
            console.log(JSON.stringify(postdata))
            await axios
            .post( store.getters.abAPIBaseURL( 'registrations/registration/merchant/NARROWCASTING' ), postdata, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                console.log(response)
                console.log(response.data)
                store.commit('setMerchantID', response.data.id)
            })
            .catch(err => {
                console.log({...err})
                console.log(err.response)
                throw err
            })
        },
        async getSubscription(store){
            console.log("reached get subscription")
            await axios
            .get( store.getters.abAPIBaseURL( 'registrations/subscription/list' ), {params: {type: "NARROWCASTING"}})
            .then(response => {
                console.log(response)
                console.log(response.data)
                store.commit('setSubscriptionID', response.data[0].id)
            })
            .catch(err => {
                console.log({...err})
                throw err
            })
        },
        async postRegistration(store, postdata, rootGetters){
            console.log("reached post registration")
            console.log(postdata)
            await axios
            .post( store.getters.abAPIBaseURL( 'registrations/registration' ), postdata, {headers: {Authorization: 'Bearer ' + store.rootGetters.token}})
            .then(response => {
                console.log(response)
                console.log(response.data)
            })
            .catch(err => {
                console.log({...err})
                throw err
            })
        }
        
    },
}