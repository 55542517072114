<template>
  <div :class="'col' + props.cls">
    <select :class="attr" aria-label="Default select example">
    <option selected><slot name="selected"></slot></option>
    <option :value="value"><slot name="option"></slot></option>
    </select>
  </div>
</template>

<script setup>
    const props = defineProps({
        attr:{
            type: Boolean,
            default: "form-select"
        },

        value:{
            type: Number,
            default: ""
        },
        cls:{
            type: String,
            default: "-xl-4"
        }
    })
</script>

<script>
export default {
name: 'SelectForm'
}
</script>

<style>

</style>