import {apiGetters, apiActions} from '@/store/apiIntegrations'

const state = {
    api_resource: 'api/v1/devices/device/',
    data_attr_name: 'device',
    devices: [],
    device: {},
    tmp_device: {}
}

export default {
    namespaced: true,
    state: state,
    mutations: {},
    getters: apiGetters,
    actions: apiActions
}