<template>
    <div id="showPageContainer">{{getData}} 
        <div id="screenTurner">
            <div v-for="viewSort in showingView" v-bind:key="viewSort.sorter">
                <div v-if="viewSort.name ==='Fullscreen'" class="position-absolute full-view top-0 start-0">
                    <video v-if="viewSort.view.content_type === 'video'" :src="viewSort.view.video" controls autoplay muted style="width:100%; height:100%" class="mx-auto d-block"></video>
                    <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                    <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                    <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                    <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>
                </div>
                <div v-if="viewSort.name ==='Top Left'" class="position-absolute border-end border-bottom border-light border-2 quarter-view top-0 start-0">
                    <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                    <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                    <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                    <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>
                </div>
                <div v-if="viewSort.name ==='Left'" class="position-absolute border-end border-light border-2 vertical-view top-0 start-0">
                    <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                    <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                    <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                    <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                </div>
                <div v-if="viewSort.name ==='Bottom Left'" class="position-absolute border-end border-top border-light border-2 quarter-view bottom-0 start-0">
                    <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                    <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                    <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                    <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                </div>
                <div v-if="viewSort.name ==='Top Right'" class="position-absolute border-start border-bottom border-light border-2 quarter-view top-0 end-0">
                    <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                    <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                    <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                    <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                </div>
                <div v-if="viewSort.name ==='Right'" class="position-absolute border-start border-light border-2 vertical-view top-0 end-0">
                    <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                    <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                    <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                    <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                </div>
                <div v-if="viewSort.name ==='Bottom Right'" class="position-absolute border-start border-top border-light border-2 quarter-view bottom-0 end-0">
                    <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                    <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                    <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                    <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                </div>
                <div v-if="viewSort.name ==='Top'" class="position-absolute border-bottom border-light border-2 horizontal-view top-0 start-0">
                    <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                    <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                    <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                    <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                </div>
                <div v-if="viewSort.name ==='Bottom'" class="position-absolute border-top border-light border-2 horizontal-view bottom-0 start-0">
                    <img v-if="viewSort.view.content_type === 'image'" :src="viewSort.view.image" alt="test image" class="d-block m-auto mw-100 mh-100 w-auto h-auto">
                    <iframe v-if="viewSort.view.content_type === 'url'" :src="viewSort.view.url" class="m-auto" style="width:100%; height:100%"></iframe>
                    <iframe v-if="viewSort.view.content_type ==='youtube'" :src="viewSort.view.youtube +'?autoplay=1'" allow='autoplay' frameborder="0" style="width:100%; height:100%"></iframe>
                    <span v-if="viewSort.view.content_type === 'html'" v-html="viewSort.view.html" class="m-auto"></span>                    
                </div>
            </div>  
            <div class="position-fixed bottom-0 w-100" id="poweredBy" style="z-index: 9">
                <p class="text-light text-center my-1" > Powered By: <b>ActaBlue</b></p> 
            </div>
            <div v-if="noCampagnes" class="text-center mt-5"> 
                <h2 class="text-danger">Nothing to play!</h2>
                <p class="text-danger">
                    This schedule has no campagnes or the campagnes in this schedule don't have active playlists. 
                    <br>Please add campagnes and/or active playlists in the portal.
                </p>
                <button class="btn btn-outline-primary" type="button" data-bs-toggle="modal" data-bs-target="#tabCloseModal">Close tab</button>
            </div>
            <div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11">
                <div ref="Toasty" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header text-white bg-danger border-0">
                        <strong class="me-auto">Oh No!</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        <p>The schedule has no campagnes</p>
                    </div>
                </div>  
            </div>
            <div class="modal fade" id="tabCloseModal" tabindex="-1" aria-labelledby="tabCloseModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="tabCloseModalLabel">Are you sure?</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>Are you sure you want to close this tab?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-danger" @click="closeTab()" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>     
        </div>   
    </div>
</template>

<script>
const {Toast} = bootstrap

export default {
    name:'ShowPage',
    components: {
    },
    data() {
        return{
            deviceSchedules: [],
            devices: [],
            campagnes: [],
            schedules:[],
            deviceCampagnes: [],
            deviceCampagnesDay:[],

            campagne: [],
            device: [],
            schedule: [],
            activePlaylists: [],
            showingPlaylist: [],
            scheduleCampagnes: [],
            stopTimer: false,
            playlistTimer: 0,
            timerVar: "",
            showingView: {
                1: [],
                2: [],
                3: [],
                4: [],
            },
            usedViews: [],
            views: {
                fullscreen: [],
                left: [],
                right: [],
                top: [],
                bottom: [],
                topRight: [],
                topLeft: [],
                bottomRight: [],
                bottomLeft: [],
            },

            noCampagnes: false,
        }
    },
    computed: {
        getData(){
                  
            //store data of devices, schedules and campagnes in local variable
            this.devices = this.$store.state.ncapiStore.devices
            this.schedules = this.$store.state.ncapiStore.schedules
            this.campagnes = this.$store.state.ncapiStore.campagnes  
        },

        
    },
    mounted(){
        this.onPageLoad()
    },

    methods: {
        closeTab(){
            // closes current tab
            window.close()
        },
        async onPageLoad() {
            //load data of devices, schedules and campagnes from API into store
            await this.$store.dispatch("getAll", {type:"device"})
            await this.$store.dispatch("getAll", {type:"schedule"})
            await this.$store.dispatch("getAll", {type:"campagne"})

            this.sortPlayData()
        },
        sortPlayData(){
            //store data of the device in local variable
            for (var index=0; index<this.devices.length; index++){
                if(this.devices[index].pk === parseInt(this.$route.params.pk)){
                    this.device = this.devices[index]
                }
            }

            //check if the screen has rotation and turn the content based on that
            var element = document.getElementById("screenTurner");
            if(this.device.screen_rotation === "portrait_left"){
                element.classList.add("turnLeftWrapper");
            }
            else if(this.device.screen_rotation === "portrait_right"){
                element.classList.add("turnRightWrapper");
            }
            


            //store the schedule data of schedules in deviceSchedules local variable
            for (var index=0; index<this.schedules.length; index++){
                if(this.schedules[index].device === parseInt(this.$route.params.pk)){
                    this.deviceSchedules.push(this.schedules[index])
                }                    
            }            

            // getting today's day and getting the data from the right schedule
            const weekday = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];

            const d = new Date();
            let day = weekday[d.getDay()];

            for(var index = 0; index<this.deviceSchedules.length; index++){
                if(this.deviceSchedules[index].day === day){
                    this.schedule = this.deviceSchedules[index]
                }
            }

            //check if the schedule has a campagne, if not show message 
            if (this.schedule.campagnes.length){
                //sort campagnes by position
                this.schedule.campagnes.sort((a, b)=>{
                    if(a.position < b.position) return -1
                    else if(a.position > b.position) return 1
                    else return 0
                })

                // getting the campagnes in the schedule
                for(var i=0; i<this.schedule.campagnes.length; i++){
                    for(var index = 0; index <this.campagnes.length; index++){
                        if(this.schedule.campagnes[i].campagne === this.campagnes[index].pk){
                            this.scheduleCampagnes.push(this.campagnes[index])
                        }
                    }
                }
                
                this.scheduleSorter()
            }
            else {
                //show message about no campagnes
                this.noCampagnes = true
                var toast = new Toast(this.$refs.Toasty)
                toast.show()
            }            
        },
        async scheduleSorter(){
            // initialize var to count the inactive playlists
            var inactiveCounter = 0

            // loop through campagnes, get activeplaylists for campagne 
            // and play it before going to next campagne
            for (var index = 0; index<this.scheduleCampagnes.length; index++){
                this.campagne = await this.scheduleCampagnes[index]

                //sort playlists by position order
                this.campagne.playlists.sort((a, b)=>{
                    if(a.position_order < b.position_order) return -1
                    else if(a.position_order > b.position_order) return 1
                    else return 0
                })

                this.activePlaylists = []
                for(var i = 0; i<this.campagne.playlists.length; i++){
                    if (this.campagne.playlists[i].status === 1){
                        this.activePlaylists.push(this.campagne.playlists[i])
                    } 
                }

                if(this.activePlaylists.length){
                    await this.play()
                }
                else {
                    // if a campagne has no active playlists add 1 to the counter
                    inactiveCounter ++
                }
            }

            //check if the amount of campagnes with no active playlists (inactiveCounter) 
            //is the same as the amount of campagnes in the schedule
            //if not, there is media that should be played
            //if yes, a message should be shown to inform the user nothing can be shown
            // console.log(inactiveCounter, ' and ', this.scheduleCampagnes.length)
            
            if(inactiveCounter !== this.scheduleCampagnes.length){
                window.location.reload()
                // console.log("launching reload")
            }
            else{
                this.noCampagnes = true
                var toast = new Toast(this.$refs.Toasty)
                toast.show()
            }
        },
        async play(){
            // loop playlists and save current playlist as showingPlaylist
            for(var index=0; index<this.activePlaylists.length; index++){
                this.showingPlaylist = this.activePlaylists[index]
                // this.playlistTimer = this.activePlaylists[index].seconds

                var playlistTotalSeconds = 0

                //check if the playlist is fullscreen. if yes, check if it has video views and nonvideoviews
                //calculate the playlistTotalSeconds 
                if(this.activePlaylists[index].layout === 'fullscreen'){
                    // console.log(this.activePlaylists[index])
                    //calculate how many nonVideoViews there are
                    var nonVideoViews =0
                    playlistTotalSeconds = 0
                    for (var i = 0; i < this.activePlaylists[index].views.length; i ++){
                        if(this.activePlaylists[index].views[i].content_type !== 'video'){
                            nonVideoViews = nonVideoViews+1
                        }
                    }
                    if(nonVideoViews !== 0){
                        playlistTotalSeconds = playlistTotalSeconds + this.activePlaylists[index].seconds
                    }
                    playlistTotalSeconds = playlistTotalSeconds + this.activePlaylists[index].video_seconds
                    
                }
                else{
                    playlistTotalSeconds = 0
                    playlistTotalSeconds = this.activePlaylists[index].seconds
                }
                this.playlistTimer = playlistTotalSeconds
                // console.log(playlistTotalSeconds)


                if (this.playlistTimer > 0 && this.stopTimer !== true) {
                    this.timerVar = setInterval(() => {
                        
                        this.playlistTimer= this.playlistTimer-1
                        if(this.playlistTimer <=0){
                            clearInterval(this.timerVar)
                        }
                        }, 
                        1000
                    );    
                }

                // sort views of playlist by position order
                this.activePlaylists[index].views.sort((a, b)=>{
                    if(a.position_order < b.position_order) return -1
                    else if(a.position_order > b.position_order) return 1
                    else return 0
                })

                // clear to make sure no double views:
                this.views.fullscreen = []
                this.views.left = []
                this.views.right = []
                this.views.top = []
                this.views.bottom = []
                this.views.topRight = []
                this.views.topLeft = []
                this.views.bottomRight = []
                this.views.bottomLeft = []
                // sort views in positions:
                for (var i = 0; i < this.activePlaylists[index].views.length; i ++) {
                    if (this.activePlaylists[index].views[i].position === 'fullscreen') {
                        this.views.fullscreen.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'left') {
                        this.views.left.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'right') {
                        this.views.right.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'top') {
                        this.views.top.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'bottom') {
                        this.views.bottom.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'topright') {
                        this.views.topRight.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'topleft') {
                        this.views.topLeft.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'bottomright') {
                        this.views.bottomRight.push(this.activePlaylists[index].views[i])
                    }
                    else if (this.activePlaylists[index].views[i].position === 'bottomleft') {
                        this.views.bottomLeft.push(this.activePlaylists[index].views[i])
                    }
                }

                // save sorted views + position name in usedViews array
                this.usedViews = []
                if(this.activePlaylists[index].layout === 'fullscreen'){
                    const fullscreen = {
                        name: "Fullscreen",
                        views: this.views.fullscreen
                    }
                    this.usedViews.push(fullscreen)
                }
                if (this.activePlaylists[index].layout === 'verticalsplit' || this.activePlaylists[index].layout === 'tsplitleft'){
                    const left = {
                        name: "Left",
                        views: this.views.left
                    }
                    this.usedViews.push(left)
                }
                if (this.activePlaylists[index].layout === 'verticalsplit' || this.activePlaylists[index].layout === 'tsplitright'){
                    const right = {
                        name: "Right",
                        views: this.views.right
                    }
                    this.usedViews.push(right)
                }
                if (this.activePlaylists[index].layout === 'horizontalsplit' || this.activePlaylists[index].layout === 'tsplittop'){
                    const top = {
                        name: "Top",
                        views: this.views.top
                    }
                    this.usedViews.push(top)
                }
                if (this.activePlaylists[index].layout === 'horizontalsplit' || this.activePlaylists[index].layout === 'tsplitbottom'){
                    const bottom = {
                        name: "Bottom",
                        views: this.views.bottom
                    }
                    this.usedViews.push(bottom)
                }
                if (this.activePlaylists[index].layout === 'crosssplit' || this.activePlaylists[index].layout === 'tsplitright' || this.activePlaylists[index].layout === 'tsplitbottom'){
                    const topLeft = {
                        name: "Top Left",
                        views: this.views.topLeft
                    }
                    this.usedViews.push(topLeft)
                }
                if (this.activePlaylists[index].layout === 'crosssplit' || this.activePlaylists[index].layout === 'tsplitleft' || this.activePlaylists[index].layout === 'tsplitbottom'){
                    const topRight = {
                        name: "Top Right",
                        views: this.views.topRight
                    }
                    this.usedViews.push(topRight)
                }
                if (this.activePlaylists[index].layout === 'crosssplit' || this.activePlaylists[index].layout === 'tsplitright' || this.activePlaylists[index].layout === 'tsplittop'){
                    const bottomLeft = {
                        name: "Bottom Left",
                        views: this.views.bottomLeft
                    }
                    this.usedViews.push(bottomLeft)
                }
                if (this.activePlaylists[index].layout === 'crosssplit' || this.activePlaylists[index].layout === 'tsplitleft' || this.activePlaylists[index].layout === 'tsplittop'){
                    const bottomRight = {
                        name: "Bottom Right",
                        views: this.views.bottomRight
                    }
                    this.usedViews.push(bottomRight)
                }
                
                // initial index counter for showingView
                var showIndex = 1
                // make sure no double showViews
                this.showingView[1]=[]
                this.showingView[2]=[]
                this.showingView[3]=[]
                this.showingView[4]=[]

                // loop through usedViews (which has position and sorted views)
                // play them in showViewSorter, add 1 to showIndex for next position
                for(var i=0; i<this.usedViews.length; i++){
                    this.showViewSorter(this.usedViews[i], showIndex)
                    showIndex++
                }

                // Wait till this playlist is finished playing, then go to next loop
                // const waitTime = this.activePlaylists[index].seconds*1000
                const waitTime = this.playlistTimer*1000
                // console.log("playlist seconds", waitTime)
                await this.sleep(waitTime)
                if(this.stopTimer === true){
                    break
                }
                // console.log("next playlist")
                clearInterval(this.timerVar)
                this.playlistTimer = 0
            }
        },
        async showViewSorter(positionViewList, showIndex){
            // loop through positionViewList(specific usedViews(has position and sorted views))
            // save position and specific view in showingView and wait till it finished playing
            // then go to next view loop
            for(var index=0; index<positionViewList.views.length; index++){
                // console.log("THIS IS THE CURRENT VIEW", positionViewList.views[index])

                const show = {
                    name: positionViewList.name,
                    view: positionViewList.views[index]
                }
                this.showingView[showIndex] = show
                const waitTime = this.showingView[showIndex].view.seconds*1000
                // console.log("View seconds", waitTime)
                await this.sleep(waitTime)
            
                if(this.stopTimer === true){
                    break
                }
                console.log("next view")
            }
        },

         
        sleep(ms) {     
            if(this.stopTimer === false){
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        },
    }
}
</script>

<style scoped>
.quarter-view{
    width: 50%;
    height: 50%;
    display: flex;
}
.horizontal-view{
    width: 100%;
    height: 50%;
    display: flex;
}
.vertical-view{
    width: 50%;
    height: 100%;
    display: flex;
}
.full-view{
    width: 100%;
    height: 100%;
    display: flex;
}
.turnRightWrapper{
    transform: rotate(90deg);
    transform-origin:bottom left;
    
    position:absolute;
    top: -100vw;
    left: 0;
    
    height:100vw;
    width:100vh;
    
    background-color:#000;
    color:#fff;

    overflow:auto;
}
.turnLeftWrapper{
    transform: rotate(-90deg);
    transform-origin:bottom right;
    
    position:absolute;
    top: -100vw;
    right: 0;
    
    height:100vw;
    width:100vh;
    
    background-color:#000;
    color:#fff;

    overflow:auto;
}

#poweredBy{
    background-color: #017ab5;
    /* opacity: 0.7; */
}
</style>