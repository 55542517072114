<template>
  <div :class="'col'+ props.cls" style="min-height: 100%;">
        <div class="navbar navbar-expand-lg bg-navTopSideOrange p-0">
          <div class="col-md-3 bg-ab-grey navBrandlogo h-100 p-0 m-0"> <img src="../assets/Actablue_logo.png" alt="Actablue logo" class="brand"></div>
            <div class="container-fluid">
              <a class="navbar-brand text-white" href="#"><slot name="navHeader"></slot></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                    <slot name="navLinks"></slot>
                </ul>
                </div>
                
            <div class="d-flex">
                <slot name="navForm">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-success" type="submit">Search</button>
                </slot>
            </div>
            <div id="myModals">
                <slot name="navModals"></slot>
            </div>
        </div>
    </div>
  </div>
</template>


<script setup>
const props = defineProps({
    cls:{
        type: String,
        defaults: ''
    }
})

</script>


<script scoped>
export default {
name: 'NavBar'
}
</script>

<style scoped>
  .navbg{
    color: #f7960b;;
  }
  .navhighlight{
    color: #f7960b;
  }
  
 #backdrop-color{
   color: #343434;
 }

 /* #sidebar-color{
   color: #017ab5;
 } */

 .navbar{
    height: 60px;
 }

.navBrandlogo{
height: 100px;
width: 250px;
display: flex;
}

.brand{
  margin-left:50px;
}

</style>