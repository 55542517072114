<template>
 
</template>

<script>
export default {



    mounted (){
    },

methods: {
       addActive(){
            var element = document.getElementById("myDIV");
            var btns = element.getElementsByClassName("sbItem");
            
            for(var i=0; i< btns.length; i++){
                btns[i].addEventListener("click", function(){
                    var current = document.getElementsByClassName("active");
                    current[0].className = current[0].className.replace(" active", "");
                    this.className += "active";
                })
            }
            
       },
}
}


</script>

<style scoped>


#newLogo{
    background-color: rgba(63, 63, 63, 1) ;
}
/* .a-active{
    background-color: #1AA5D3 !important;
} */

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}
 
.active, .sbItem:hover {
  background-color: #f7960b;
}

/* .sidebar a:hover {
  background-color: #f7960b;
  color: white;
} */

.activeStyle{
    background-color: #f7960b;
}
</style>
