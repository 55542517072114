<template>
  <div>{{getData}}
  <NavBar>
    <template v-slot:navHeader>Welcome!</template>
    <template v-slot:navForm>
      <button href="#backDrop" class="btn" type="submit" data-bs-toggle="modal"><i class="bi bi-box-arrow-right me-1" aria-label="Log out"></i>Log out</button>
    </template>
      <template v-slot:navModals>
          <div class="modal fade" id="backDrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title" id="staticBackdropLabel">Log Out</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <strong>Are you sure you want to log out?</strong>
                      </div>
                      <div class="modal-footer">
                          <button type="button" class="btn" data-bs-dismiss="modal">Close</button>
                          <button type="button" class="btn" @click="KClogout()" data-bs-dismiss="modal">Log Out</button>
                      </div>
                    </div>
                  </div>
              </div>
          </template>
  </NavBar>

    
    <div class="container">
      <h2 class="txt-abblue mt-4" >Kies een administratie:</h2>
      <div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mx-auto my-2">
          <!-- <Admin cls="" size="">
            <template v-slot:image>
            </template>
          	  <template v-slot:body >
                <div  v-for="(administration, index) in administrations"
            v-bind:key="administration.id">
             {{administration.name}}</div>
           
            </template>
          	  <template v-slot:title>Name: </template>
          	  <template v-slot:text>&nbsp;</template>
        	    <template v-slot:update>Last modified: </template>
          </Admin> -->
          <Admin v-for="(administration, index) in administrations"
          :key="administration.id"
          :name="administration.name"
          :index="administration.index"
          >
              <template v-slot:title><h5 class="txt-abblue">{{administration.name}}</h5></template>
          	  <template v-slot:text><button class="btn text-light my-2" @click="chooseAdmin(index)">Ga naar administratie</button></template>
        	    <template v-slot:update></template>
          </Admin> 
          <!-- <div class="card-group"
            v-for="(administration, index) in administrations"
            v-bind:key="administration.id" >
            <div class="card">
              <div class="row g-0">
                <div class="col-md-6">
                  <img src="../assets/hunter-logo.png" class="img-fluid" alt="Administration logo">
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <div class="card-body px-2  text-center">
                    <p class="card-text px-3">Name: {{administration.name}}</p>
                    <button class="btn text-light" @click="chooseAdmin(index)">Choose admin</button>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import Navbar from '@/components/layout/Navbar'
import Admin from '../components/Admin.vue'
  export default {
    name: 'administrations',
    components: {
    Navbar,
    Admin
},
    data() {
      return {
        administrations: [],
        adminName:'',
      }
    },
    mounted(){
      this.onPageLoad()
      this.getAdminInfo()
    },
    computed: {
        getData(){
          this.administrations = this.$store.state.octoblueStore.administrations
          console.log(this.administrations)
        }
    },
    methods: {
      async onPageLoad(){
        if(this.$route.query.registration){
          let slp = await new Promise(r => setTimeout(r, 5000));
        }
        await this.$store.dispatch("getAdministrations")
      },
async getAdminInfo(){
       this.adminName = localStorage.adminName
             await this.$store.dispatch("getAllLocations")
             await this.$store.dispatch("getAll", {type:"device"})
             await this.$store.dispatch("getAll", {type:"schedule"})
             console.log('admin here -->',this.adminName)
          },
        
      chooseAdmin(index){
        const administration = this.administrations
            let dataSaver = {
              id: administration[index].id,
              name: administration[index].name,
            }
            this.$store.commit("setAdminID", dataSaver)
            localStorage.setItem('administrationID', dataSaver.id)
            localStorage.setItem('adminName', dataSaver.name)
            this.$router.push('/dashboard')
      },
      KClogout(){
        this.$store.dispatch('key/logout')
        console.log("in out of here")
        this.$router.push("/")
      },
    },
  }

</script>
<style scoped>

.btn:hover{
   background-color:#0990d3;
 }

 .border-actablueBg{
   border: .1px solid #017ab5;
 }
 .border-end-actablueBg{
   border: .1rem solid #017ab5;
 }
</style>
