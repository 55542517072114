import {ref, reactive, unref, isRef} from 'vue'

const nav_links = ref([]);
// const navNav = ref()

export default {
    getNavLinks(){
        return nav_links
    },
    addNavLink(new_link){
        
        nav_links.value.push(new_link)
        nav_links.value.push('')//needs to be empty when its called again
    },

    // getRouterLink(){
    //     return navNav
    // },
    // addrouterToLink(newroute){
    //     navNav.value.push(newroute)
    // }
}
