<template>
  <div class="popup">
      <div class="popup-inner">
          <slot />
          <button type="button" class="btn btn-primary m-4 popup-close" @click="TogglePopup()">Cancel</button>
          <button type="button" class="btn btn-danger m-4 popup-close" @click="Delete()">Delete</button>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        TogglePopup: Function,
        Delete: Function,
    }
}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;

    .popup-inner {
        background: #fff ;
        padding: 32px;
    }
}
</style>