<template>
    <div class="container" >
        <div class="col-6">
            <form @submit.prevent="submitForm(index)">{{getData}}
                <div class="field">
                    <label>Name</label>
                    <div class="control mb-2">
                        <input type="text" class="input form-control" v-model="name" required="required" >
                    </div>
                </div>

                <label>Location</label>
                <div class="mb-2">
                    <select v-model="selected" class="form-select">
                        <option disabled value="">Please select one</option>
                        <option v-for="location in locations" v-bind:key="location.select">{{location.name}}</option>
                    </select>
                </div>

                <label>Screen rotation</label>
                <div class="mb-1">
                    <div class="control">
                        <div class="select">
                            <select v-model="rotation" class="form-select" required>
                                <option disabled value="">Please select one</option>
                                <option value="landscape">Landscape</option>
                                <option value="portrait_left">Portrait linksom</option>
                                <option value="portrait_right">Portrait rechtsom</option>

                                 <!-- <div class="card text-center mx-2 btn" for="btn-check" style="width: 15rem;" value="landscape">
                            <img class="card-img-top" src="../assets/Right_rotation.png" alt="landscape">
                                <div class="card-body">
                                    <p class="card-text">Right</p>
                                </div>
                            </div>

                                   <div class="card text-center mx-2" style="width: 15rem;">
                            <img class="card-img-top" src="../assets/Landscape.png" alt="landscape">
                                <div class="card-body">
                                    <p class="card-text">Landscape</p>
                                </div>
                            </div>

                            <div class="card text-center mx-2" style="width: 15rem;">
                            <img class="card-img-top" src="../assets/Left_rotation.png" alt="landscape">
                                <div class="card-body">
                                    <p class="card-text">Left</p>
                                </div>
                            </div> -->
                            
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="btn btn-primary mt-2">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="flippingtoast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-success border-0">
                    <strong class="me-auto">Success!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The device has been added!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const {Toast} = bootstrap
export default {

    name: 'AddDevice',
    data() {
        return {
            name: '',
            locationID: '',
            locations: [],
            selected: '',
            rotation: '',
            scheduleDays:
                ["monday","tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
        }
    },
    computed: {
        getData(){
            //store locations in local variable
            this.locations = this.$store.state.octoblueStore.locations
        }
    },
    mounted (){
        this.chooseLocation()
    },
    methods: {
        async chooseLocation(){
            //load all locations in store from API
            this.$store.dispatch("getAllLocations")
        },

        async submitForm() {
            //store locations and call toast
            const locationsVar = this.locations
            var toast = new Toast(this.$refs.flippingtoast)
            toast.show()

            //get the locationID
            if (this.selected.length){
                for (var index = 0; index < locationsVar.length; index ++) {
                    if (locationsVar[index].name === this.selected) {
                        this.locationID = locationsVar[index].id
                    } 
                }
            }
            else {
                this.locationID = null
            }

            //post new device data to API and get all devices to reload page
            const device = {
                administration_id: localStorage.administrationID,
                name: this.name,
                location_id: this.locationID,
                screen_rotation: this.rotation
            }
            await this.$store.dispatch("post", {type:"device", data:device})
            await this.$store.dispatch("getAll", {type: "device"})

            //get the PK of the newest device and add all schedule days to it
            const newestDevice = this.$store.state.ncapiStore.devices[this.$store.state.ncapiStore.devices.length -1]
            for (var index=0; index<this.scheduleDays.length; index++){
                let scheduleInfo = {
                    administration_id: localStorage.administrationID,
                    day: this.scheduleDays[index],
                    device: newestDevice.pk,
                }
                await this.$store.dispatch("post", {type:"schedule", data: scheduleInfo})
            }
        
        
            //empty values
            this.name= ''
            this.selected ='' 
            this.locationID= ''
        },
    },
}
</script>
