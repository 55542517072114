<template>
    <div class="container">{{getData}}
        <div class="col-6">
            <form @submit.prevent="submitForm(index)">
                <div class="field mb-2">
                    <label>Name</label>
                    <div class="control">
                        <input type="text" class="input form-control" v-model="device.name">
                    </div>
                </div>


                <label>Location</label>
                <div class="mb-2">
                    <select v-model="selected" class="form-select">
                        <option disabled value="">Please select one</option>
                        <option v-for="location in locations" v-bind:key="location.select">{{location.name}}</option>
                    </select>
                </div>

                <label>Screen rotation</label>
                <div class="mb-2">
                    <div class="control">
                        <div class="select">
                            <select v-model="device.screen_rotation" class="form-select">
                                <option value="landscape">Landscape</option>
                                <option value="portrait_left">Portrait linksom</option>
                                <option value="portrait_right">Portrait rechtsom</option>
                            </select>
                        </div>
                    </div>
                </div>


                <div class="field">
                    <div class="control">
                        <button data-bs-toggle="collapse" role="button" href="#collapseExample"  class="btn btn-primary mt-2" @click="buttonClicked()">Submit</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="flippingtoast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-success border-0">
                    <strong class="me-auto">Success!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The device has been updated!</p>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
import Device from '../views/Device.vue'
const {Toast} = bootstrap
export default {

    name: 'EditDevice',
    components:{
        Device
    },

    data() {
        return {
            device: [],
            locationID: '',
            locations: [],
            selected: '',
        }
    },
    computed: {
        getData(){
            //store device and locations data in local variable
            this.device = this.$store.state.ncapiStore.device
            this.locations = this.$store.state.octoblueStore.locations

            //store selected location data in local variable
            for (var index = 0; index < this.locations.length; index ++) {
                if (this.locations[index].id === this.device.location_id) {
                    this.selected = this.locations[index].name
                }
            }
        }
    },
    methods: {
        async submitForm() {
            //store locations in local variable and call toast
            const locationsVar = this.locations
            var toast = new Toast(this.$refs.flippingtoast)
            toast.show()
            

            //check if a location is selected: if it is store its ID in locationID, if not locationID=null
            if (this.selected.length){
                for (var index = 0; index < locationsVar.length; index ++) {
                    if (locationsVar[index].name === this.selected) {
                        this.locationID = locationsVar[index].id
                    } 
                }
            }
            else {
                this.locationID = null
            }

            //patch updated device data to API, load device to update the page
            const device = {
                name: this.device.name,
                location_id: this.locationID,
                screen_rotation: this.device.screen_rotation
            }
            await this.$store.dispatch("patch", {type:"device", pk:this.$route.params.pk, data: device})
            this.$store.dispatch("get", {type:"device", pk: this.$route.params.pk})
        },
        buttonCLicked(){
            this.$root.$refs.Device_component.showToasty();
        },
    },
}
</script>
