<template>
  <form :class="'col' +props.cls">
        <div class="">
            <div class="card-header abstyle" id="borderTop">
                <h2 class="text-center text-white"><slot name="header">Placement text</slot></h2>
            </div>
        <div class="card-body" id="borderBottom">
            <slot name="body"></slot>
             <div class="field">
                <div class="control">
                    <button class="btn mt-2">Volgende</button>
                </div>
            </div>
        </div>
        </div>
  </form>
</template>


<script setup>
const props = defineProps({
    cls:{
        type:String,
        default:'-lg-12'
    }
})
</script>

<script>
export default {
name: 'RegistrationForm'
}
</script>

<style>
#borderTop{
border: 2px solid var(--ab-blue);
border-top-right-radius: 15px;
border-top-left-radius: 15px;
}
#borderBottom{
border: 2px solid var(--ab-blue);
border-bottom-right-radius: 15px;
border-bottom-left-radius: 15px;
}
.abstyle{
    background-color: var(--ab-blue);
}
</style>