import * as Keycloak from 'keycloak-js';


const kcState = {
    api_base_url: process.env.VUE_APP_KC_URL,
    realm: process.env.VUE_APP_KC_REALM,
    clientId: process.env.VUE_APP_KC_CLIENT_ID,
    clientSecret: process.env.VUE_APP_KC_CLIENT_SECRET,  
}

const kcMutations = {
 
}

const kcGetters = {
    
    kcOptions( state ){
        return {
            url: state.api_base_url, 
            realm: state.realm, 
            clientId: state.clientId, 
            onLoad: 'check-sso',
            // onLoad: 'login-required',
            // checkLoginIframe: false,
            // promiseType: "native",
        }
    },
}

const kcActions = {

    async login(store, rootState) {
        /**
         * Log the user in, by requesting a keycloak token.
         */
        console.log("Try new Login")
        console.log(store.getters.kcOptions)

        let kc = Keycloak( store.getters.kcOptions )
        console.log('before: ', kc.authenticated);
        await kc.init({ onLoad: store.getters.kcOptions.onLoad })
        .then((auth) => {
            if ( kc.authenticated ) {
                console.log("return page: ", kc.authenticated);
                console.log( "Auth: ", typeof( auth ), auth, {...auth} )

                console.log("IT WORKS")
                console.log( "Auth: ", typeof( auth ), auth, {...auth} )

                let payload = {
                    idToken: kc.idToken,
                    accessToken: kc.token,
                    loginTime: new Date().getTime()
                }

                console.log("Payload: ", payload )

                if (kc.token && kc.idToken && kc.token != '' && kc.idToken != '') {
                    store.commit("login", payload, { root: true });
                    console.log("User has logged in: " + kc.subject)
                    payload = {
                        name: kc.tokenParsed.preferred_username,
                    };
                    store.commit("setName", payload, { root: true });
                }
            }
            else{
                console.log("auth is false")
                console.log( "Auth: ", typeof( auth ), auth, {...auth} )
                kc.login()
            }

        })
        .catch((err)=>{
            console.log("DOESNT WORK")
            console.log({...err})
            console.log("Keycloak auth fail: ", err)
            throw err
        })
    },

    async logout(store) {
        /**
         * Log the user out, remove the token
         */
        var options = store.getters.kcOptions;
        let kc = Keycloak(store.getters.kcOptions);
        
          
        await kc.init({ onLoad: store.getters.kcOptions.onLoad })
        .then((auth) => {
            // console.log( "Auth: ", typeof( auth ), auth, {...auth} )
            if ( kc.authenticated ) {
                var logoutOptions = { redirectUri: "http://localhost:8080/"}
                store.commit("kcLogout", null, { root: true });
                kc.logout(logoutOptions)
            }
            else {
                console.log("Can't logout when you're not logged in lol")
            }
        
        // await store.dispatch('check')
        })     
        .catch((err)=>{
            console.log("DOESNT WORK")
            console.log({...err})
            console.log("Keycloak auth fail: ", err)
            throw err
        }) 
    },

    async register(store) {
        /**
         * Log the user in, by requesting a keycloak token.
         */
        console.log("Try new register")
        console.log(store.getters.kcOptions)

        let kc = Keycloak( store.getters.kcOptions )
        console.log('before: ', kc.authenticated);
        await kc.init({ onLoad: store.getters.kcOptions.onLoad })
        .then((auth) => {
            if ( kc.authenticated ) {
                console.log("Can't register if you're logged in");
            }
            else{
                kc.register()
            }

        })
        .catch((err)=>{
            console.log("DOESNT WORK")
            console.log({...err})
            console.log("Keycloak auth fail: ", err)
            throw err
        })
    },

    async check(store) {
        /**
         *  Check if a new token should be requested.
         */
        return new Promise((resolve, reject)=> {
            // Check Token, Check validity, redirect user
            if( store.rootState.user.isAuthenticated && 
                store.rootState.user.idToken != '' &&
                store.rootState.user.idToken && 
                store.rootState.user.accessToken != '' &&
                store.rootState.user.accessToken ) {
                    //  Check validity of the token
                    console.log("Login Check passed: ", store.rootState.user)
                    resolve( true )
            } else {
                console.log("Login Check Failed: ", store.rootState.user)
                // return reject( store.dispatch('login') )
            }

        })
    },

    async refresh(store) {
        // Initialize Keycloak
        var options = store.getters.kcOptions;
        let kc = Keycloak(store.getters.kcOptions);
        await kc.init({ onLoad: store.getters.kcOptions.onLoad })

        // Token Refresh
        kc.updateToken(299)
        .then((refreshed) => {
            console.log(refreshed)
            if (refreshed) {
                console.log('Token refreshed' + refreshed);
                let payloadRefreshedTokens = {
                    idToken: kc.idToken,
                    accessToken: kc.token,
                    loginTime: new Date().getTime()
                }
                if (kc.token && kc.idToken && kc.token != '' && kc.idToken != '') {
                    store.commit("login", payloadRefreshedTokens, { root: true });
                }
                else {
                    store.dispatch("key/logout");
                }
            } 
            else {
                // console.log('Token not refreshed, valid for '
                // + Math.round(kc.tokenParsed.exp + kc.timeSkew - new Date().getTime() / 1000) + ' seconds');
                // console.log(kc.tokenParsed.exp, " + ", kc.timeSkew, " - ", new Date().getTime() /1000)
            }
        })
        .catch((err) => {
        console.log('Failed to refresh token');
        console.log({...err})
        console.log("Keycloak refresh fail: ", err)
        });
    }

}

export default {
    namespaced: true,
    state: kcState,
    mutations: kcMutations,
    getters: kcGetters,
    actions: kcActions,
}
