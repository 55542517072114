<template>
    <div class="container my-4">{{getData}}
        <Card cls="-xl-8 my-5">
            <template v-slot:header>
                Campagne: {{campagne.name}}
            </template>
            <template v-slot:body>
                      <div class="card-body">
                    <p>Name: {{campagne.name}}</p>
                    <p v-if="campagne.playlists">Amount of playlists: {{campagne.playlists.length}}</p>
                    <p>Total time: {{campagne.total_time}}</p>
                    <div class="my-3">
                        <button type="button" class="btn ms-2 mb-1" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-pencil-square me-2"></i>Edit campagne</button>
                        <button class="btn ms-2 mb-1" type="button" data-bs-toggle="modal" data-bs-target="#CampagneModal"><i class="bi bi-trash-fill me-2"></i>Delete Campagne</button>
                        <button class="btn ms-2 mb-1" type="button" @click="previewCampagne()"><i class="bi bi-card-image me-2"></i>Preview Campagne</button>                            
                         
                        
                        <div class="modal fade" id="CampagneModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Are you sure you want to delete {{campagne.name}}?</p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                        <button type="button" class="btn btn-danger" @click="deleteCampagne()" data-bs-dismiss="modal"><i class="bi bi-trash-fill me-2"></i>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div class="collapse" id="collapseExample">
                            <div class="card card-body ">
                                <EditCampagne />
                            </div>
                        </div> 
                    </div>
                </div>
            </template>
        </Card>

        <Card cls="">
            <template v-slot:header>
                Playlist
            </template>
            <template v-slot:body>
                 <ToolTip cls="-lg-4" styl="primary" icon="question-circle-fill">
                    <template v-slot:body><i class="bi bi-question-circle-fill"></i> {{Message}}</template>
                 </ToolTip>
                
                <button class="btn mt-3 ms-3 mb-1" type="button" data-bs-toggle="collapse" data-bs-target="#AddPlaylistCollapse" aria-expanded="false" aria-controls="AddPlaylistCollapse"><i class="bi bi-plus-circle me-2"></i>Add playlist</button>
                <div class="collapse" id="AddPlaylistCollapse">
                    <div class="card card-body ">
                        <AddPlaylist />
                    </div>
                </div>   
                    <div :v-bind="addEventListeners()">
                    <div class="draggable-list" id="draggable-list" >  
                        <section 
                            v-for="(playlist, index) in campagne.playlists" 
                            v-bind:key="playlist.pk"
                            class='card-body draggable' draggable="true"
                            @dragstart="dragStart(index)"
                            :data-index="index">

                            <div class="card border-actaorangeBg" v-if="playlist.status === 1">
                                <div class="card-header-actaorangeBg">
                                        <i class="bi bi-list ms-1 me-1 text-lg"></i>
                                    #{{index +1}} {{playlist.name}}</div>
                                
                                <div class="card-body table-responsive" id="Dragg">
                                    
                                    <table class="table table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th>Layout:</th>
                                                <th>Name:</th>
                                                <th>Seconds:</th>
                                                <th>Amount of views:</th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            <tr>
                                                <td>{{playlist.layout}}</td>
                                                <td>{{playlist.name}}</td>
                                                <td>{{playlistSecondsList[index]}}</td>
                                                <td>{{playlist.views.length}}</td>
                                                <td class="p-0 text-end">
                                                    <button class="btn" @click="goPlaylist(playlist.pk)">
                                                        Details
                                                    </button>
                                                    <button class="btn ms-2" type="button" data-bs-toggle="modal" :data-bs-target="'#playlistModal' + playlist.pk">
                                                        <i class="bi bi-trash-fill me-2"></i>Delete Playlist
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="modal fade" :id="'playlistModal' + playlist.pk" tabindex="-1" aria-labelledby="playlistModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="playlistModalLabel">Are you sure?</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Are you sure you want to delete {{playlist.name}}?</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <button type="button" class="btn btn-danger" @click="deletePlaylist(index)" data-bs-dismiss="modal">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 

                            <div class="card border-light" v-else>
                                <div class="card-header text-muted">
                                        <i class="bi bi-list ms-1 me-1"></i>
                                    #{{index +1}} {{playlist.name}} <strong>inactive</strong></div>
                                <div class="card-body table-responsive">
                                    <table class="table table-borderless mb-0" >
                                        <thead>
                                            <tr>
                                                <th>Layout:</th>
                                                <th>Name:</th>
                                                <th>Seconds:</th>
                                                <th>Amount of views:</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            <tr>
                                                <td>{{playlist.layout}}</td>
                                                <td>{{playlist.name}}</td>
                                                <td>{{playlistSecondsList[index]}}</td>
                                                <td>{{playlist.views.length}}</td>
                                                <td class="p-0 text-end">
                                                    <button class="btn" @click="goPlaylist(playlist.pk)">
                                                        Details
                                                    </button>
                                                    <button class="btn ms-2" type="button" data-bs-toggle="modal" :data-bs-target="'#playlistModal' + playlist.pk">
                                                        <i class="bi bi-trash-fill me-2"></i>Delete Playlist
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="modal fade" :id="'playlistModal' + playlist.pk" tabindex="-1" aria-labelledby="playlistModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="playlistModalLabel">Are you sure?</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Are you sure you want to delete {{playlist.name}}?</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <button type="button" class="btn btn-danger" @click="deletePlaylist(index)" data-bs-dismiss="modal">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                      
                        </section>
                    </div>
                </div>
            </template>
        </Card>

        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="deleteCampagneToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-danger border-0">
                    <strong class="me-auto">Campagne deleted!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The campagne has been deleted!</p>
                    <p>You'll now return to the campagnes overview page</p>
                </div>
            </div>
        </div>

        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="deletePlaylistToast" class="toast hide text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-danger border-0">
                    <strong class="me-auto">Playlist deleted!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The playlist has been deleted!</p>
                </div>
            </div>
        </div> 

         <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="emptyPlaylist" class="toast hide text-black bg-warning border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-black bg-warning border-0">
                    <strong class="me-auto"> <i class="bi bi-info-circle-fill"></i> There are currently no playlists!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>Get started by clicking green 'add playlist' to add a new playlist</p>
                </div>
            </div>
        </div> 

         <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="inactivePlaylist" class="toast hide text-black bg-warning border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-black bg-warning border-0">
                    <strong class="me-auto"><i class="bi bi-info-circle-fill"></i> A playlist is inactive!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>Get started by adding a view in the playlist</p>
                </div>
            </div>
        </div> 

    </div>
</template>

<script> 
import EditCampagne from './EditCampagne.vue'
import AddPlaylist from './AddPlaylist.vue'
import EditPlaylist from './EditPlaylist.vue'

const {Toast} = bootstrap
    export default {
        name: 'Campagne',
        components: {
            EditCampagne,
            AddPlaylist,
            EditPlaylist,
            hover: false,
            
        },
        data() {
            return{
                campagne: [],
                objPlaylist: [],
                playlistSecondsList: [],
                PKPlaylist: null,
                dragStartIndex: null, 
                dragEndIndex: null,
                hover: false,
                Message:  '  In this section is where you add your playlist, To get started click the Add Playlist button.',
                
            }
        },
        computed: {
            getData(){
                // console.log("CAMPAGNE DATA", this.campagne)
                
                //store campagne data in local variable and sort playlists by position order
                this.campagne = this.$store.state.ncapiStore.campagne
                if (this.campagne.playlists){
                    this.campagne.playlists.sort((a, b)=>{
                        if(a.position_order < b.position_order) return -1
                        else if(a.position_order > b.position_order) return 1
                        else return 0
                    })
                    console.log('this should be campaign', this.campagne.playlists)
                }

                //check if the playlist is fullscreen. if yes, check if it has video views and nonvideoviews
                //calculate the playlistTotalSeconds 
                this.playlistSecondsList = []
                if(this.campagne.playlists){
                    for(var index=0; index<this.campagne.playlists.length; index++){
                        if(this.campagne.playlists[index].layout === 'fullscreen'){
                            console.log("SHOW ME THE FULLSCREEN PLAYLISTS", this.campagne.playlists[index].seconds)
                            if (!this.campagne.playlists[index].views.length){
                                var playlistTotalSeconds =0
                            }
                            else{
                                //calculate how many nonVideoViews there are
                                var nonVideoViews =0
                                var playlistTotalSeconds = 0
                                for (var i = 0; i < this.campagne.playlists[index].views.length; i ++){
                                    if(this.campagne.playlists[index].views[i].content_type !== 'video'){
                                        nonVideoViews = nonVideoViews+1
                                    }
                                }
                                if(nonVideoViews !== 0){
                                    playlistTotalSeconds = playlistTotalSeconds + this.campagne.playlists[index].seconds
                                }
                                playlistTotalSeconds = playlistTotalSeconds + this.campagne.playlists[index].video_seconds
                            }
                            
                        }
                        else{
                            var playlistTotalSeconds = 0
                            playlistTotalSeconds = this.campagne.playlists[index].seconds
                        }
                        this.playlistSecondsList.push(playlistTotalSeconds)
                    }
                }
            }
        },
        mounted() {
            this.getCampagneDetails()
            this.checkPlaylistStatus()
            this.checkPlaylist()
        },
        methods: {

            async checkPlaylistStatus(){
               if(this.campagne.playlists){
                   for(var index=0; index<this.campagne.playlists.length; index++){
                       if(this.campagne.playlists[index].status === 0){
                        //    console.log('one is inactive')
                        var toast = new Toast(this.$refs.inactivePlaylist)
                        toast.show()
                       }
                   }
               }
           },

             checkPlaylist(){
                if(this.campagne.playlists.length === 0){
                    console.log('this boi is empty', this.campagne.playlists.length)
                    var toast = new Toast(this.$refs.emptyPlaylist)
                    toast.show()
                }
                else{
                    console.log('this boi is full', this.campagne.playlists.length)
                }
             },

            async getCampagneDetails(){
                //load campagne into store from API
                await this.$store.dispatch("get", {type:"campagne", pk: this.$route.params.pk})
            },
            async deleteCampagne(){
                //call toast, delete this campagne from API and load all Campagnes to update store
                //then go to campagnes overview after 5 secs
                var toast = new Toast(this.$refs.deleteCampagneToast)
                toast.show()
                await this.$store.dispatch("delete", {type:"campagne", pk: this.$route.params.pk})
                await this.$store.dispatch("getAll", {type:"campagne"})

                setTimeout(() => {
                    this.$router.push('/campagnes-overview') }, 
                    5000
                );
            },
            async previewCampagne(){
                //store campagne.name in store and go to the preview page
                await this.$store.commit("setData", {campagne: this.campagne.name})
                this.$router.push("/select-show")
            },
            async deletePlaylist(index){
                //save tobe deleted playlist data in currentPlaylist
                //if the playlist is active update campagne.total_time
                //delete the playlist
                let currentPlaylist = this.campagne.playlists[index]
                if (this.campagne.playlists[index].status === 1){
                    await this.$store.dispatch("get", {type:"campagne", pk: this.campagne.pk})
                    const newTime = {
                        total_time: this.$store.state.ncapiStore.campagne.total_time - this.campagne.playlists[index].seconds
                    }
                    await this.$store.dispatch("patch", {type:"campagne", pk: this.campagne.pk, data: newTime})
                }
                await this.$store.dispatch("delete", {type:"playlist", pk: this.campagne.playlists[index].pk})

                //update the position order of other playlists
                for (var i = 0; i < this.campagne.playlists.length; i ++){
                    if (this.campagne.playlists[i].position_order >= currentPlaylist.position_order){
                        const newPositionOrder = {
                            position_order: i
                        }
                        if(this.campagne.playlists[i].pk != currentPlaylist.pk){
                            await this.$store.dispatch("patch",{type:"playlist", pk: this.campagne.playlists[i].pk, data: newPositionOrder})
                        }
                    }
                }

                //call toast and reload page
                var toast = new Toast(this.$refs.deletePlaylistToast)
                toast.show()
                this.$store.dispatch("get", {type:"campagne", pk: this.$route.params.pk})    
            },
            async goPlaylist(pk){
                //load playlist into store and go to its playlist details page
                await this.$store.dispatch("get",{type:"playlist", pk: pk})
                this.$router.push(`/campagnes-overview/playlist/${pk}`) 
            },

            addEventListeners() {
                //get all items with class 'draggable' and all <section> elements with class 'draggable-list'
                const draggables = document.querySelectorAll('.draggable');
                const dragListItems = document.querySelectorAll('.draggable-list section');

                //for each playlist in dragListItems add eventlisteners for dragging
                dragListItems.forEach(view => {
                    view.addEventListener('dragover', this.dragOver);
                    view.addEventListener('drop', this.dragDrop);
                    view.addEventListener('dragenter', this.dragEnter);
                    view.addEventListener('dragleave', this.dragLeave);
                });
            },

            dragStart(index, viewPosition) {
                //save the index of currently dragged playlist
                this.dragStartIndex = +index
            },

            dragEnter() {
            // this.classList.add('over');
            
            },

            dragLeave() {
            // this.classList.remove('over');
            document.getElementById("Dragg").setAttribute('style', 'opacity: 0.7;')
            // document.getElementById("Dragg").setAttribute('style', 'border: 1px solid red;')
            },

            dragOver(e) {
            e.preventDefault();
            },

            dragDrop(event) {
            //save the index of the playlist that the dragged item was dropped on
            //and call reorderItems 
            this.dragEndIndex = +event.currentTarget.getAttribute('data-index');
            this.reorderItems(this.dragStartIndex, this.dragEndIndex);
            // this.classList.remove('over');
            },

            async reorderItems(oldIndex, newIndex){
                //for every playlist in campagne.playlists check if their position_order 
                //should be changed because of the dragged playlist
                for (var index = 0; index < this.campagne.playlists.length; index ++) {
                    if (index <= newIndex && index > oldIndex){
                        const newPositionOrder = {
                            position_order: this.campagne.playlists[index].position_order-1
                        }
                        await this.$store.dispatch("patch", {type:"playlist", pk: this.campagne.playlists[index].pk, data: newPositionOrder})
                    }
                    else if (index >= newIndex && index < oldIndex){
                        const newPositionOrder = {
                            position_order: this.campagne.playlists[index].position_order+1
                        }
                        await this.$store.dispatch("patch", {type:"playlist", pk: this.campagne.playlists[index].pk, data: newPositionOrder})
                    }
                }
                
                //save the position_order where the dragged playlist needs to go and patch this
                let newSpot = this.campagne.playlists[newIndex]
                const newPositionOrder = {
                    position_order: newSpot.position_order
                }             
                await this.$store.dispatch("patch", {type:"playlist", pk: this.campagne.playlists[oldIndex].pk, data: newPositionOrder})
                this.$store.dispatch("get", {type:"campagne", pk: this.$route.params.pk})
            },    
        }
    }
</script>

<style scoped>
    
.draggable{
    cursor: move;
}

#Dragg{
    opacity: 10;
    border:none;
}

.card-body{
    padding: .5rem;
}
.link
{
   color:rgb(0, 0, 0);
   text-decoration: none; 
   background-color: none;
}
.actablueBg{
    color: #067db6;;
}
.card-header-actaorangeBg{
background-color: #f7960b !important;
}

.border-actaorangeBg{
    border-color:#f7960b;
}
</style>