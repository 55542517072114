<template>
    <div class="sidebar" id="barLeft" >
        <slot name="sbHeader">
          <!-- <div class="backColor">
              <img src="../assets/Actablue_logo.png" alt="Actablue logo" class="navbar-brand">
          </div> -->
        </slot>
          <slot name="sbItems"></slot>
    </div>
</template>


<script setup>
const props = defineProps({

    item: {
      type: String,
      default: ''
    }
})

</script>

<script>
export default {
name: 'SideBar'
}
</script>

<style>

#barLeft{
    background-color: #017ab5;
    /* height: calc(100vh - 48px); */
    min-height: 100%;
    
}
.sidebar {
position: relative;
  width: 250px;
  min-height: 100%;
  overflow: hidden;
  float: left;
}

ul.items a:hover{
  /* background-color: orange !important; */
}

.navbar-brand{
 width: 100px;
  height: auto; 
  margin-left: auto;
  margin-right: auto;
  display: block;

}
.backColor{
 background-color: rgba(63, 63, 63, 1) ;
  width:320px; 
}
.change{
  height: 100%;
}
.scaleHeight{
    min-height: 100%;
}
</style>