<template>
    <div class="container" >
        <div class="col-12">
            <form @submit.prevent="submitForm(index)">{{getData}}
                <div class="field mb-2">
                    <label>Name</label>
                    <div class="control">
                        <input type="text" class="input form-control" v-model="playlist.name">
                    </div>
                </div>

                <div class="field">
                    <label>Seconds</label>
                    <div class="control">
                        <input type="number" class="input form-control" v-model.number="playlist.seconds">
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" class="btn mt-3">Submit</button>
                    </div>
                </div>
                
            </form>
        </div>
        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div ref="flippingtoast" class="toast hide text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header text-white bg-success border-0">
                    <strong class="me-auto">Success!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>The playlist has been updated!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const {Toast} = bootstrap
export default {

    name: 'EditPlaylist',
    props: {
        objPlaylist: {
            type: Object
        },
        PKPlaylist: {
            type: Number 
        },
        objSortedViews: {
            type: Object
        },
    },
    data() {
        return {
            playlist:[],
            oldSeconds: null,
        }
    },
    mounted() {
        this.onLoad()
    },
    computed: {
        getData(){
            //store playlist data in local variable
            this.playlist = this.$store.state.ncapiStore.playlist
        }
    },
    methods: {
        async onLoad(){
            //load playlist data into store
            //store original playlist seconds in local variable
            await this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})
            this.oldSeconds = this.playlist.seconds 
            console.log('SOMETHING')
        },
        async submitForm() {
            //call toast
            var toast = new Toast(this.$refs.flippingtoast)
            toast.show()

            //patch updated playlist data to API
            const playlist = {
                campagne: this.$store.state.ncapiStore.campagne.pk,
                name: this.playlist.name,
                seconds: this.playlist.seconds,
                //incoming_transition: this.playlist.incoming_transition,
               // outgoing_transition: this.playlist.outgoing_transition,
            }
            await this.$store.dispatch("patch", {type:"playlist", pk: this.$route.params.pk, data: playlist})

            //if playlist has 0 seconds inactivate it
            if(this.playlist.layout === 'fullscreen'){
                if(this.playlist.seconds === 0 & this.playlist.video_seconds === 0){
                    const newStatus = {
                        status: 0
                    }
                    await this.$store.dispatch("patch", {type:"playlist", pk: this.$route.params.pk, data:  newStatus})
                }
            }
            else{
                if (this.playlist.seconds === 0){
                    const newStatus = {
                        status: 0
                    }
                    await this.$store.dispatch("patch", {type:"playlist", pk: this.$route.params.pk, data:  newStatus})
                }
            }

            //if playlist is active: update campagne.total_time
            if (this.playlist.status === 1){
                await this.$store.dispatch("get", {type:"campagne", pk: this.playlist.campagne})
                const fixSeconds = {
                    total_time: this.$store.state.ncapiStore.campagne.total_time - this.oldSeconds + this.playlist.seconds
                }
                await this.$store.dispatch("patch", {type:"campagne", pk: this.playlist.campagne, data: fixSeconds})
            }

            //sort views into the right positions
            if (this.playlist.views){
                let viewsPosition = []

                for (var index = 0; index < this.playlist.views.length; index ++){
                    if(this.playlist.views[index].position === 'fullscreen') {
                        viewsPosition = this.objSortedViews.fullscreen  
                    }
                    else if (this.playlist.views[index].position === 'left'){
                        viewsPosition = this.objSortedViews.left
                    }
                    else if (this.playlist.views[index].position === 'right'){
                        viewsPosition = this.objSortedViews.right
                    }
                    else if (this.playlist.views[index].position === 'top'){
                        viewsPosition = this.objSortedViews.top
                    }
                    else if (this.playlist.views[index].position === 'bottom'){
                        viewsPosition = this.objSortedViews.bottom
                    }
                    else if (this.playlist.views[index].position === 'topleft'){
                        viewsPosition = this.objSortedViews.topLeft
                    }
                    else if (this.playlist.views[index].position === 'topright'){
                        viewsPosition = this.objSortedViews.topRight
                    }
                    else if (this.playlist.views[index].position === 'bottomleft'){
                        viewsPosition = this.objSortedViews.bottomLeft
                    }
                    else if (this.playlist.views[index].position === 'bottomright'){
                        viewsPosition = this.objSortedViews.bottomRight
                    }

                    //calculate amount of non-video views
                    var nonVideoViews =0
                    for (var i = 0; i < viewsPosition.length; i ++){
                        if(viewsPosition[i].content_type !== 'video'){
                            nonVideoViews = nonVideoViews+1
                        }
                    }

                    //calculate the seconds of non-video views
                    let newViewSeconds = null
                    if(this.playlist.layout !== 'fullscreen'){
                        newViewSeconds = {
                            seconds:Math.round(this.playlist.seconds/viewsPosition.length)
                        }
                    }
                    else{
                        newViewSeconds = {
                            seconds:Math.round(this.playlist.seconds/nonVideoViews)
                        }
                    }

                    //if playlist is fullscreen check if the view is not a video before updating with newViewSeconds
                    //else update all views with newViewSeconds
                    if(this.playlist.layout === 'fullscreen'){
                        if(this.playlist.views[index].content_type !== 'video'){
                            await this.$store.dispatch("patch", {type:"view", pk: this.playlist.views[index].pk, data: newViewSeconds})
                        }
                    }
                    else{
                        await this.$store.dispatch("patch", {type:"view", pk: this.playlist.views[index].pk, data: newViewSeconds})
                    }
                }
            }
            //load playlist to update page
            this.$store.dispatch("get", {type:"playlist", pk: this.$route.params.pk})
        },
    },
}
</script>
